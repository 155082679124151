import { H_WorkProcessType } from 'helyosjs-sdk';
import { Mission, Nobstacle, Obstacle, Presence, Target, Yard, Guideline } from '../models/app.models'
import { AllEntities, emptyCollection } from './updatetableCollection'

export type InvalidStateType = 'changing' | 'outdated'
export interface OutdatedMissions {
    [presenceId: string]: InvalidStateType
}

export interface ApplicationEntityBase {
    missions: Mission
    presences: Presence,
    obstacles: Obstacle,
    nobstacles: Nobstacle,
    targetAreas: Obstacle,
    targets: Target,
    guidelines: Guideline,
    yards: Yard,
    wProcessTypes: H_WorkProcessType,
}


export class Playback {
    status?: string = 'stop';
    mission?: Partial<Mission>;
    currentPlayTime?: any;
    totTime?: number;
    enlapse?: number;
    presence?: Presence;
    history?: any[];
    visible?: boolean = false;
    labels?: any;
}

export class Schedule{
    reloadEvents: boolean = true;
    labels?: any;
}


export class Command{
    reloadEvents: boolean = true;
    labels?: any;
    collapseSchedule?: boolean = true;
    missionUI: any;

}

export class Layout{
    reloadEvents: boolean = true;
    labels?: any;
}


export enum LoginStatusType {
    user_logged = 'USER_LOGGED',
    admin_logged = 'ADMIN_LOGGED',
    not_logged = 'NOT_LOGGED'
} 
 

export class UiControl {
    playback: Playback = new Playback();
    schedule: Schedule = new Schedule();
    commandView: Command = new Command();
    layoutView: Layout = new Layout();
    showDialog: boolean = false;
    selectedPresence: any;
    filterStatus:string;
    lastMapUpdateRequest: Date = new Date();
}

export interface ApplicationState {
    entities: AllEntities<ApplicationEntityBase>,
    outdatedMissions: OutdatedMissions,
    activeYard: Yard,
    uiControl: UiControl,
    authentication: any,

}

export const initialState: ApplicationState = {
    // activeYard: {id:1, name:'', origin: {lat: 51.0531973, lon: 13.7031056, zoomLevel: 19}},
    activeYard: new Yard(),
    entities: {
        missions: emptyCollection(),
        nobstacles: emptyCollection(),
        obstacles: emptyCollection(),
        presences: emptyCollection(),
        targets: emptyCollection(),
        targetAreas: emptyCollection(),
        guidelines: emptyCollection(),
        yards: emptyCollection(),
        wProcessTypes: emptyCollection(),
    },
    outdatedMissions: {},
    uiControl: new UiControl(),
    authentication: 'not_logged',

}
