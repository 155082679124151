import * as React from 'react'

/**
 * A Foundation 6 button-group element
 *
 * Use classes to control its appearance:
 *   tiny, small, large
 *   stacked
 */

interface Props {
    className?: string
    style?: React.CSSProperties
}

export class ButtonGroup extends React.PureComponent<Props, {}> {
    render () {
        return (
            <div className={`button-group ${this.props.className || ''}`} style={this.props.style}>
                {this.props.children}
            </div>
        )
    }
}
