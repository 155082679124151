import * as React from 'react'
import { ReactNode } from 'react-redux'
import './ModalDialog.scss'

export interface Props {
    title?: string,
    children: ReactNode,
    confirmAction: () => void
    cancelAction: () => void
}

export class ModalDialog extends React.PureComponent<Props> {
    render () {
        return (
            <div className="ModalDialog background" onClick={this.props.cancelAction}>
                <div className="Dialog" onClick={event => {event.stopPropagation()}}>
                    {
                        (() => {
                            if (this.props.title) {
                                return <div className="title">{this.props.title}</div>
                            }
                        })()
                    }
                    <div className="content">
                        {this.props.children}
                    </div>
                    <div id="DialogButtons">
                            <button className="button confirm" onClick={this.props.confirmAction}>
                                Confirm
                            </button>
                            <button className="button cancel" onClick={this.props.cancelAction}>
                                Cancel
                            </button>
                        </div>
                </div>
            </div>
    )}
}
