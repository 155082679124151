import Timeline from 'react-calendar-timeline';
import 'react-calendar-timeline/lib/Timeline.css';
import '../../../assets/stylesheets/schedule_table.scss';

import * as moment from 'moment';
import * as lodash from 'lodash';
import React = require('react');
import { getWorkProcessAssignments } from '../../../services/app-service';
import { helyosToAppModel } from '../../../services/helyos-app-model-convertors';
import { Store } from '../../base';
import { setPlayback } from '../../../state/actions';



function onClickMission(item) {
    console.log(item)
    getWorkProcessAssignments(item.id).then(r=>{
        const pathSegments = r.map(a=>helyosToAppModel.actionToPathConvertor(a)); 
        const pathFlattened = [].concat.apply([], pathSegments);
        const totTime = 10;     
        Store.dispatch(setPlayback({history:pathFlattened, totTime,  mission:{id:r.id, status:r.status}}));
      
    });
}


class Basic extends React.Component<any,any>{

    constructor(...params){
        super(params);
        const defaultTimeStart = moment()
        .startOf("day")
        .toDate();
        const defaultTimeEnd = moment()
            .startOf("day")
            .add(1, "day")
            .toDate();
        const resources = [{}];
        const missions = [];
        this.state = { resources, missions, defaultTimeStart, defaultTimeEnd };
    }


    componentWillReceiveProps(props){
        if (props.presences && props.presences.length){
            const debouncedResources = lodash.debounce((props)=>
                                        {this.reloadResources(props);
                                         this.reloadMissions(props);   
                                        },1000);
            debouncedResources(props);
        }

    }

    reloadResources(props) {
        let resources = [];
        if (props.presences && props.presences.length){
            resources = this.props.presences.map(p => { return { id:p.id, title:p.main_licence_plate,  yardId: p.yardId,
                color: 'white',
            } });
        }
        this.setState({resources});
    }

    reloadMissions(props) {
        let missions = [];
        if (props.presences && props.presences.length) {
            props.presences.forEach( p => {
               const presenceMissions =  p.missions.map( m => (
                                            {
                                                id: m.id,
                                                group: p.id,
                                                title: `m-${m.id}`,
                                                start_time:  moment(m.schedStartAt),
                                                end_time: moment(m.schedEndAt).add(10, 'minutes'),
                                                _yardId: p.yardId
                                            }));

                missions = missions.concat(presenceMissions)                    
            });


        this.setState({missions});
        }    

    }




    itemRenderer = ({ item, timelineContext, itemContext, getItemProps, getResizeProps }) => {
        const { left: leftResizeProps, right: rightResizeProps } = getResizeProps();
        const backgroundColor = itemContext.selected ? (itemContext.dragging ? "red" : item.selectedBgColor) : '#1779ba';
        const borderColor = itemContext.resizing ? "red" : item.color;
        return (
          <div
            {...getItemProps({
              style: {
                backgroundColor,
                color: 'white',
                borderColor,
                borderStyle: "solid",
                borderWidth: 1,
                borderRadius: 4,
                minWidth:'40px',
                borderLeftWidth: itemContext.selected ? 3 : 1,
                borderRightWidth: itemContext.selected ? 3 : 1
              },
              onMouseDown: () => {
                  onClickMission(item);
              }
            })}
          >
            {itemContext.useResizeHandle ? <div {...leftResizeProps} /> : null}
    
            <div
              style={{
                height: itemContext.dimensions.height,
                overflow: "hidden",
                paddingLeft: 3,
                textAlign:'center',
                textOverflow: "ellipsis",
                whiteSpace: "nowrap"
              }}
            >
              {itemContext.title}
            </div>
    
            {itemContext.useResizeHandle ? <div {...rightResizeProps} /> : null}
          </div>
        );
      };




    render(){return <div>

                        <Timeline
                        groups={this.state.resources}
                        items={this.state.missions}
                        stackItems
                        defaultTimeStart={moment().add(-12, 'hour')}
                        defaultTimeEnd={moment().add(12, 'hour')}
                        itemRenderer={this.itemRenderer}

                        />
    </div>}
}


export default Basic;