import { appToHelyosModel } from '../models/app-helyos-model-convertors';
import { changeEntityState, markMissionsOutdated, reloadScheduleEvents, setYard, setPlayback, cleanEntityState, setMissionUI, requestMapUpdate, showDialog } from '../state/actions';
import { Mission, Obstacle, Target, DestinationData, MissionData, Presence, Yard, MissionStatus, Guideline, TargetAnchor } from '../models/app.models';

import { Store, bufferDispatches, DispatchMessage } from '../state/store';
import { helyosToAppModel, simplify, simplifyPathObj } from './helyos-app-model-convertors';
import { HelyosServices, H_WorkProcess, H_Tools, H_WorkProcessType, H_InstantAction  } from 'helyosjs-sdk';
import { ApplicationState } from '../state/models';
import * as Swal from 'sweetalert2';

const toast = Swal.mixin({
    toast: true,
    position: 'bottom',
    showConfirmButton: false,
    timer: 8000,
    animation: true,
    customClass: 'animated slideInRight'
  });


const token =  window.sessionStorage.getItem('token');
let backendUrl =  window.sessionStorage.getItem('backendUrl');
if (!backendUrl){
    backendUrl = `http://${location.hostname}`;
}

let socketPort = '5002';
let gqlPort = '5000';

if (backendUrl.includes('localhost')){
    socketPort = '5002';
    gqlPort = '5000';
} 

if (backendUrl.includes('https')){
    socketPort = '443';
    gqlPort = '443';
} 

export let helyosService = new HelyosServices(backendUrl, {socketPort, gqlPort});


function startApp(){
    listYards();
    // listTargets();
    listGuidelines();
    listWorkProcessTypes();
    helyosSubscriptions();
    schedulePresenceUpdates();
    scheduleMissionUpdates();
    scheduleMapUpdates(false, 0, false); //get all data at start
    scheduleMapUpdates(true, 2000, true); //get updates every 2 seconds
    scheduleMapUpdates(true, 15000, false); //get all data every 5 seconds

}



if (token) {
    helyosService.token =  token;
    helyosService.connect()
    .then(() => {
            console.log("helyos connected");
            startApp();
            return(true);

        }).catch(errorConnect =>console.log(errorConnect));
}

export function helyosLogin(username, password){
    let backendUrl =  window.sessionStorage.getItem('backendUrl');
    if (!backendUrl){
        backendUrl = `http://${location.hostname}`;
    }
    if ( helyosService.url !== backendUrl){ // check if server address changed and reinstantiate helyos service
        helyosService = new HelyosServices(backendUrl, {socketPort:'5002', gqlPort:'5000'});
    }

   return  helyosService.login(username, password)
            .then( response => {
            console.log("response login", response);
            if (response && response.jwtToken){
                    return  helyosService.connect()
                        .then(() => {
                                window.sessionStorage.setItem('token', response.jwtToken);
                                console.log("helyos connected");
                                startApp();
                                return(true);

                            }).catch(errorConnect =>console.log(errorConnect));
                } else {
                    return false;
                } 
            })
            .catch(errorLogin => console.log(errorLogin));
}



    // --------------------------- NOTIFICATIONS PUSHED FROM SERVER ----------------------------------------


function helyosSubscriptions(){
            const socket = helyosService.socket;

            let channel = "new_tool_poses";
            socket.on(channel,(updates)=>{
                presencePoseUpdates(updates);
            });

            channel = "change_tool_status";
            socket.on(channel,(updates)=>{
                presenceUpdates(updates);
            });

            const channelWP = 'change_work_processes';
            socket.on(channelWP,(updates)=>{
                missionUpdates(updates);
            });


            const channelMap = 'yard_updates';
            socket.on(channelMap,(updates)=>{
                yardUpdates(updates);
            });

            const channelExtServ = 'service_requests_update';
            socket.on(channelExtServ,(updates)=>{
                updates.forEach( serv => {
                    if (serv.status === 'ready' && serv.serviceType === 'Map server'){
                        reloadAllShapesInStore();
                    }
                });
            });
};


// --------------------------- YARDS ----------------------------------------


function  listYards() {    
        let defaultYard = {};
        helyosService.yard.list()
        .then(
            data => {
                if(!data || !data.length) return;
                
                let dispatchMessage = data.map(y => {
                    const yard = helyosToAppModel.yardConvertor(y);
                    return {created: yard};
                });
                
                defaultYard = dispatchMessage[0]['created'];

                return Store.dispatch(changeEntityState({ yards: dispatchMessage }));
            },
            (e) => {
                console.log("error yard promise", e);
                Store.dispatch(changeEntityState({ yards: 'error' }));
            }
        ).finally(()=>{
            Store.dispatch(setYard(defaultYard['id']));
        });
}
 
export function editYard(yard: Partial<Yard>) {
    Store.dispatch(changeEntityState({ yards: 'loading' }))
    const yardPatch: Partial<H_Tools> = appToHelyosModel.yardToHelyosFormat(yard);
    return helyosService.yard.patch(yardPatch)
            .then((resp) =>{
                const yard = helyosToAppModel.yardConvertor(resp);
                const dispatchMessage = [new DispatchMessage('updated', yard)];

                return bufferDispatches({ yards: dispatchMessage });
            });
}

// --------------------------- TARGETS ----------------------------------------

// function listTargets(){
//     helyosService.target.list(0)
//     .then(
//         targets => {
//             if(!targets) return;
//             let dispatchMessage = targets.map(t => {
//                 const target = helyosToAppModel.targetConvertor(t);   
//                 return  new DispatchMessage('created',target);    
//             });
            
//             return Store.dispatch(changeEntityState({ targets: dispatchMessage }));
//         },
//         (e) => {
//             console.log("error target promise", e)
//             Store.dispatch(changeEntityState({ nobstacles: 'error', obstacles: 'error', targets: 'error' }))
//         }
//     )
// }


export function createTarget(target: Target) {
    createTargetObject(target);
    // Store.dispatch(changeEntityState({ targets: 'loading'}));
    // const hTarget = appToHelyosModel.targetToHelyosFormat(target);
    // return helyosService.target.create(hTarget)
    // .then(target => {
    //     console.log("createTarget",target);
    //     const _target = helyosToAppModel.targetConvertor(target);   
    //     let dispatchMessages =  [ new  DispatchMessage('created', _target)]    
        // return bufferDispatches({ targets: dispatchMessages });
    // });
}


export function createTargetObject(target: Target) {
    Store.dispatch(changeEntityState({ targets: 'loading'}));
    const hMapObject = appToHelyosModel.targetToMapObject(target);
    return helyosService.mapObjects.create(hMapObject)
    .then(target => {
        console.log("createTarget",target);
        // const _target = helyosToAppModel.targetConvertor(target);   
        let dispatchMessages =  [ new  DispatchMessage('created', target)]    
        return bufferDispatches({ targets: dispatchMessages });
    });
}



export function listGuidelines(){
    helyosService.mapObjects.list({type: 'guideline', deletedAt: null}, 1e6)
    .then(
        mapObjects => {
            if(!mapObjects) return;
            let guidelines = mapObjects.map(mobj => helyosToAppModel.guidelineConvertor(mobj));
            let dispatchMessage = guidelines.map(g => {
                return  new DispatchMessage('created',g);    
            });       
            return Store.dispatch(changeEntityState({ guidelines: dispatchMessage }));
        },
        (e) => {
            console.log("error target promise", e)
            Store.dispatch(changeEntityState({ nobstacles: 'error', obstacles: 'error', targets: 'error' }))
        }
    )
}


export function createGuideline(guideline: Partial<Guideline>) {
    Store.dispatch(changeEntityState({ guidelines: 'loading'}));
    const hGuideline = guideline;
    return helyosService.guidelines.create(hGuideline)
    .then(createdGuideline => {
        console.log("createTarget",createdGuideline);
        let dispatchMessages =  [ new  DispatchMessage('created', createdGuideline)]    
        return bufferDispatches({ guidelines: dispatchMessages });
    });
}


export function listWorkProcessTypes(){
    helyosService.workProcessType.list({})
    .then(
        wpTypes => {
            if(!wpTypes) return;
            let dispatchMessage = wpTypes.map(g => {
                return  new DispatchMessage('created',g);    
            });   
            Store.dispatch(setMissionUI(wpTypes.filter( m => m.settings && m.settings.autotruck_app)));
            return Store.dispatch(changeEntityState({ wProcessTypes: dispatchMessage }));
        },
        (e) => {
            console.log("error wprocessType promise", e)
            Store.dispatch(changeEntityState({ wProcessTypes: 'error' }))
        }
    )
}




// --------------------------- PRESENCES - VEHICLES ----------------------------------------

export function yardUpdates(mapObjectData:any[]) {
    let obstacleDispatchMessage=[];

    obstacleDispatchMessage = mapObjectData.map(t => {
        const mapObject= helyosToAppModel.shapeConvertor(t);
        return  new DispatchMessage('updated',mapObject);
    });    

    bufferDispatches({ obstacles: obstacleDispatchMessage });
}


export function presenceUpdates(toolData: any[]) {
    let presenceDispatchMessage=[];

    presenceDispatchMessage = toolData.map(t => {
        const presence= helyosToAppModel.toolConvertor(t);
        return  new DispatchMessage('updated',presence);
    });    

    bufferDispatches({ presences: presenceDispatchMessage });
}

export function presencePoseUpdates(toolPoses, deltaAnimation = null) {
    let presenceDispatchMessages=[];
    // deltaAnimation was used for playback and to control the animation speed.
    // it is not being used anymore. 

    toolPoses.forEach(toolPose => {
        if (!toolPose.toolId){ 
            console.log("toolPose without toolId");
        return; }
        const id= toolPose.toolId.toString();
        const pose = helyosToAppModel.toolPoseToPresencePose(toolPose);
        const sensors = helyosToAppModel.toolPoseToPresenceSensor(toolPose);
        let taskProgress = null;
        if (toolPose.sensors && toolPose.sensors.helyos_agent_control){
             taskProgress = toolPose.sensors.helyos_agent_control.current_task_progress;
        }
 
        const modifiedAt = toolPose.createdAt;
        
        // calculate the time enlapsed between current and last position to be used in the animation.
        const state: ApplicationState = Store.getState();
        const presence = state.entities.presences.items[id];
        if (!presence) {return; }

        const DateTypeAfter =  new Date() ;
        // const DateTypeAfter =  new Date(toolPose.createdAt + 'Z') ;
        let DateTypeBefore =  new Date(presence.modified + 'Z');

        if (!deltaAnimation){
            deltaAnimation =  DateTypeAfter.getTime() - DateTypeBefore.getTime();
            if (deltaAnimation < 0) { // in case some other action than position change updated the Tools table
                if (presence.posModifiedAt){
                    DateTypeBefore =  new Date(presence.posModifiedAt + 'Z') ;
                    deltaAnimation =  DateTypeAfter.getTime() - DateTypeBefore.getTime();
                } else {
                    deltaAnimation= 1000;
                }
             };
        }
        const posModifiedAt = toolPose.createdAt;
        const presencePatch = {id,  modified: modifiedAt, sensors, deltaAnimation, posModifiedAt};
        if (pose && pose.x !== undefined && pose.x! !== null) {
            presencePatch['pose'] = pose;
        }
        if (taskProgress) {
            presencePatch['taskProgress'] = taskProgress;
        } 

        presenceDispatchMessages.push( new DispatchMessage('updated',presencePatch)); 

    });

    bufferDispatches({ presences: presenceDispatchMessages });
}


export function schedulePresenceUpdates() {
    Store.dispatch(changeEntityState({ presences: 'loading' }));
    const scheduleUpdate = () => window.setTimeout(schedulePresenceUpdates, 600000);
    const state = Store.getState();
    const latest = state.entities.presences.latest;
    let agents = [];
    helyosService.tools.list({agentClass:'vehicle'})
    .then( vehicles => { 
        agents = [...vehicles];
        return helyosService.tools.list({agentClass:'tool'});
    })
    .then(
        tools => {
            let presenceDispatchMessages = [...agents, ...tools].map(t => {
                const presence= helyosToAppModel.toolConvertor(t);
                return  new DispatchMessage('created',presence);
            });
            Store.dispatch(changeEntityState({ presences: presenceDispatchMessages }));
        },
        () => Store.dispatch(changeEntityState({ presences: 'error' }))
    )
    .then(scheduleUpdate, scheduleUpdate);
}

 
export function editPresence(presence: Partial<Presence>) {
    Store.dispatch(changeEntityState({ presences: 'loading' }));
    const toolPatch: Partial<H_Tools> = appToHelyosModel.presenceToToolFormat(presence);
    return helyosService.tools.patch(toolPatch)
            .then((tool) =>{

                const presence = helyosToAppModel.toolConvertor(tool);
                const dispatchMessage = [new DispatchMessage('updated', presence)];

                return bufferDispatches({ presences: dispatchMessage });
            });
}


export function getToolSensorsHistory(startDate, endDate) {
    return helyosService.tools.history(startDate, endDate);
}

// --------------------------- OBSTACLES----------------------------------------


export function listShapesByYard(yardId:number | string) {
    return helyosService.mapObjects.list({yardId, deletedAt: null}, 1e6);
}


export function getObstaclesAndDrivableAreas(latest = 0, onlyUpdates=true) {
    let promise;
    if (onlyUpdates)  promise = helyosService.mapObjects.listRecent(latest);
    else promise = helyosService.mapObjects.list({deletedAt: null}, 1e6);

    return promise
    .then(
        shapes => {
            let obstacles = shapes.filter( s => (s.type==='obstacle')).map(s =>  helyosToAppModel.shapeConvertor(s));
            let targetAreas = shapes.filter( s => (s.type==='target')).map(s =>  helyosToAppModel.shapeConvertor(s));
            let drivables = shapes.filter( s => (s.type==='drivable' || (s.type && s.type.includes('zone')))).map(s => helyosToAppModel.shapeConvertor(s));
            let targets = shapes.filter( s => s.type==='target').map(s => helyosToAppModel.targetObjectConvertor(s));

            return {obstacles, drivables, targets, targetAreas};
        },
        (e) => {
            console.log("error obstacle promise", e);
            bufferDispatches({ nobstacles: 'error', obstacles: 'error', targets:'error', targetAreas: 'error'});
        }
    );
}


export function newObstacleOrDrivalbleAreas(obstacle: Obstacle) {
    const shape = appToHelyosModel.obstacleToShapeFormat(obstacle);
    return helyosService.mapObjects.create(shape)
    .then(
        shapes => {
            let obstacles = shapes.filter( s => s.type==='obstacle').map(s =>  helyosToAppModel.shapeConvertor(s));
            let drivables = shapes.filter( s => s.type==='drivable').map(s => helyosToAppModel.shapeConvertor(s));
            let targets = shapes.filter( s => s.type==='target').map(s => helyosToAppModel.shapeConvertor(s));

            return {obstacles, drivables, targets};
        },
        (e) => {
            console.log("error obstacle creator", e);
            bufferDispatches({ nobstacles: 'error', obstacles: 'error'});
        }
    );
}


export function newShapesOrDrivalbleAreas(obstacles: Obstacle[]) {
    const shapes = obstacles.map(obstacle => appToHelyosModel.obstacleToShapeFormat(obstacle));
    return helyosService.mapObjects.createMany(shapes)
    .then(
        shapes => {
            console.log("newShapesOrDrivalbleAreas,", shapes)
            let obstacles = shapes.filter( s => s.type==='obstacle').map(s =>  helyosToAppModel.shapeConvertor(s));
            let drivables = shapes.filter( s => s.type==='drivable').map(s => helyosToAppModel.shapeConvertor(s));
            return {obstacles, drivables};
        },
        (e) => {
            console.log("error obstacle creator", e)
            bufferDispatches({ nobstacles: 'error', obstacles: 'error'});
        }
    );
}



export function createObstacle(obstacle: Obstacle) {
    Store.dispatch(changeEntityState({ nobstacles: 'loading', obstacles: 'loading', targets: 'loading'}));
    const state = Store.getState();
    obstacle.yardId = state.activeYard.id;
    return newObstacleOrDrivalbleAreas(obstacle)
    .then(() =>  getObstaclesAndDrivableAreas())
    .then(r => {
        const obstacleDispatchMessage = r.obstacles.map(o => new DispatchMessage('created',o));
        const drivableDispatchMessage = r.drivables.map(d => new DispatchMessage('created',d));
        const targetDispatchMessage = r.targets.map(t=> new DispatchMessage('created',t));
        const targetAreaDispatchMessage = r.targetAreas.map(t=> new DispatchMessage('created',t));

        const state = Store.getState();
        const entities = state.entities;
        Store.dispatch(changeEntityState({ nobstacles: drivableDispatchMessage, obstacles: obstacleDispatchMessage,
                                            targetAreas: targetAreaDispatchMessage, targets: targetDispatchMessage }));
        // bufferDispatches({ nobstacles: drivableDispatchMessage, obstacles: obstacleDispatchMessage });
    });
}


export function createManyShapes(obstacles: Obstacle[]) {
    Store.dispatch(changeEntityState({ nobstacles: 'loading', obstacles: 'loading', targets: 'loading'}));
    const state = Store.getState();
    obstacles.forEach(obstacle => {
        obstacle.yardId = state.activeYard.id;
        delete obstacle.id;
    })
    return newShapesOrDrivalbleAreas(obstacles);
}




export function scheduleMapUpdates(repeat=true, period=2000, onlyUpdates=true) {
    Store.dispatch(changeEntityState({ nobstacles: 'loading', obstacles: 'loading', targets: 'loading' }))
    let scheduleUpdate = () => {};
    if (repeat) {
        scheduleUpdate = () => window.setTimeout(()=>scheduleMapUpdates(repeat, period,onlyUpdates), period);
    }
    let latest = onlyUpdates? (new Date()).getTime() - period*2 : 0; // latest is twice period to avoid missing updates.


    getObstaclesAndDrivableAreas(latest/1000, onlyUpdates)
    .then( (r: {obstacles: any[], drivables:any[], targets:any[]}) => {

        const createOrDelete = (d) => {if(d.deletedAt) return new DispatchMessage('deleted',d);
                                       else return new DispatchMessage('created',d);};

        const obstacleDispatchMessage = r.obstacles.map(createOrDelete);
        const drivableDispatchMessage = r.drivables.map(createOrDelete);
        const targetDispatchMessage = r.targets.map(createOrDelete);
        const targetAreaDispatchMessage = r.targetAreas.map(createOrDelete);


        if(!onlyUpdates) Store.dispatch(cleanEntityState("obstacles"));
        Store.dispatch(changeEntityState({ nobstacles: drivableDispatchMessage, obstacles: obstacleDispatchMessage,
            targetAreas: targetAreaDispatchMessage, targets: targetDispatchMessage }));

        // return bufferDispatches({ nobstacles: drivableDispatchMessage, obstacles: obstacleDispatchMessage,
        //                          targets: targetDispatchMessage, targetAreas: targetAreaDispatchMessage });
    })
    .then(scheduleUpdate, scheduleUpdate);
}



export function removeObstacle(id: string) {
    return helyosService.mapObjects.markDeleted(id)
    .then((shape)=>{
        let obstacleDispatchMessage = [{deleted : shape.id} ];
        if (shape.isObstacle) {
            return bufferDispatches({ obstacles: obstacleDispatchMessage });
        } else {
            return bufferDispatches({ nobstacles: obstacleDispatchMessage });
        }
    });
}


export function  reloadAllShapesInStore() {
    Store.dispatch(cleanEntityState("obstacles"));
    getObstaclesAndDrivableAreas(0)
    .then( (r: {obstacles: any[], drivables:any[], targets: any[], targetAreas:[]}) => {
        console.log(r)
        const obstacleDispatchMessage = r.obstacles.map(o => new DispatchMessage('created',o));
        const drivableDispatchMessage = r.drivables.map(d => new DispatchMessage('created',d));
        const targetDispatchMessage = r.targets.map(t => new DispatchMessage('created',t));
        const targetAreaDispatchMessage = r.targetAreas.map(t=> new DispatchMessage('created',t));



        return bufferDispatches({ nobstacles: drivableDispatchMessage, obstacles: obstacleDispatchMessage, targets:targetDispatchMessage, targetAreas: targetAreaDispatchMessage });
    })
}


export function removeAllShapes(yardId: string) {
    return helyosService.mapObjects.markAllDeleted(yardId)
    .then((shapes)=>{

        getObstaclesAndDrivableAreas(0)
        .then( (r: {obstacles: any[], drivables:any[], targetAreas:[]}) => {
            console.log(r)
            const obstacleDispatchMessage = r.obstacles.map(o => new DispatchMessage('created',o));
            const drivableDispatchMessage = r.drivables.map(d => new DispatchMessage('created',d));
            const targetAreaDispatchMessage = r.targetAreas.map(t=> new DispatchMessage('created',t));

            return bufferDispatches({ nobstacles: drivableDispatchMessage, obstacles: obstacleDispatchMessage, targetAreas:targetAreaDispatchMessage });
        })

    });
}



// --------------------------- MISSIONS ----------------------------------------

export function createInstantAction(toolId: any, command: string) {

    const instantAction: Partial<H_InstantAction> = {
        'toolId': toolId as number,
        'toolUuid': null,
        'command': command,
        'sender': 'helyOS dashboard'
    }
    return this.helyosService.instantActions.create(instantAction)
}


export function getWorkProcessDetails(wpTypeName:string) {
    return helyosService.workProcessType.list({name: wpTypeName})
    .then(r => {
        if (r.length > 0) {
            return r[0];
        }

        return null;
    });
}   


export function saveWorkProcessDetails(wpType:Partial<H_WorkProcessType>) {
    return helyosService.workProcessType.patch(wpType)
    .then(r => {
        listWorkProcessTypes();
        if (r.length > 0) {
            return r[0];
        }

        return null;
    });
}  

let missionUpdateCancel: number | undefined

export function getWorkProcessAssignments(id) {
    return helyosService.workProcess.getActions(id);
}


export function missionUpdates(workProcesses: H_WorkProcess[]) {
    Store.dispatch(changeEntityState({ missions: 'loading' }));


    let dispatchMessage = workProcesses.map(async (w: H_WorkProcess) =>  {
        const mission = helyosToAppModel.workProcessConvertor(w);
        if (w.status === 'succeeded' || w.status === 'failed') {
            Store.dispatch(reloadScheduleEvents(true));
        }   

        let agentName: '';
        if (w.toolIds && w.toolIds.length > 0) {
            const agent = await helyosService.tools.get(w.toolIds[0]);
            agentName = agent.name;
        }

        toast.fire({'title':`${agentName} / ${w.workProcessTypeName}: ${w.status}`, 'type':'success'});
        if(w.status === 'assignment_failed') {
            helyosService.assignments.list({workProcessId: w.id }).then(assignms=>{  
                const failedAssignmts = assignms.filter(a => ['failed', 'aborted'].includes(a.status))
                failedAssignmts.forEach(fa=> {
                    helyosService.assignments.get(fa.id).then(fullObj => {
                        if (fullObj.result && fullObj.result.msg_to_user){
                            toast.fire({'title':fullObj.result.msg_to_user, 'type':'warning'});
                        }
                        if (fullObj.result && fullObj.result.error){
                            toast.fire({'title':fullObj.result.error, 'type':'warning'});
                        }
                    })

                })
            })
        }

        if(w.status === 'executing') {
            getWorkProcessAssignments(w.id).then(r=>{  
                if(r) {
                    const startDate= new Date(w.createdAt);
                    const endDate= new Date(w.endedAt);
                    const pathSegments = r.map(a=>helyosToAppModel.actionToPathConvertor(a));
                    let pathFlattened = [].concat.apply([], pathSegments);
                    const originalLength = pathFlattened.length;
                    try {
                        console.log('path original num points', originalLength)
                        const numMaxPoints = 500;
                        if (pathFlattened.length > numMaxPoints) {
                            pathFlattened = simplifyPathObj(pathFlattened, 250);
                            console.log('epsilon 250 mm.  points simplified to:', pathFlattened.length)
                        }
                        if (pathFlattened.length > numMaxPoints) {
                            pathFlattened = simplifyPathObj(pathFlattened, 500);
                            console.log('epsilon 500 mm. points simplified to:', pathFlattened.length)
                        }
                        if (pathFlattened.length > numMaxPoints) {
                            pathFlattened = simplifyPathObj(pathFlattened, 2000);
                            console.log('epsilon 2000 mm. points simplified to:', pathFlattened.length)
                        }
                        if (pathFlattened.length > numMaxPoints) {
                            pathFlattened = [pathFlattened[0], pathFlattened[pathFlattened.length-1]];
                            toast.fire({'title':`too many points in the path: ${originalLength}`, 'type':'warn'});
                            console.error('too many points in the path', pathFlattened.length);
                        }
                    } catch (error) {
                        console.log('path undersampling', error)
                    }
            
                    console.log("after==========================")

                    const totTime = endDate.getTime() - startDate.getTime();
                    Store.dispatch(setPlayback({history:pathFlattened, totTime,  mission:{id:w.id, status:w.status as MissionStatus }}));
                }
            });
        }
        return  new DispatchMessage('created',mission);
    });

    return bufferDispatches({ missions: dispatchMessage });
}



export function scheduleMissionUpdates() {
    Store.dispatch(changeEntityState({ missions: 'loading' }));
    const scheduleUpdate = () => missionUpdateCancel = window.setTimeout(scheduleMissionUpdates, 300000);
    const state = Store.getState();

    helyosService.workProcess.list({status: 'executing'})
        .then(
            wprocesses => {
                let dispatchMessage = wprocesses.map(w => { 
                    const mission = helyosToAppModel.workProcessConvertor(w);
                    return new DispatchMessage('created', mission);
                });

                return Store.dispatch(changeEntityState({ missions: dispatchMessage }));
            },
            (e) => {
                console.log("error mission promise", e);
                Store.dispatch(changeEntityState({ missions: 'error' }));
            }
    )
    .then(scheduleUpdate, scheduleUpdate);
}


export function listMissions(conditions: any): Promise<any> {
    return helyosService.workProcess.list(0);
}


export function createMission(presence_id: string, destination:string, destinationData: DestinationData, 
                schedStartAt=null, workType='driving',  settings={}, anchor=TargetAnchor.Front, status=MissionStatus.Created) {

    const state = Store.getState();
    destinationData.yardId = state.activeYard.id;
    const mission = new MissionData(presence_id, destination, destinationData, schedStartAt, workType, anchor);
    let workprocess = appToHelyosModel.missionToWorkProcessFormat(mission);
    workprocess.status = status;
    Store.dispatch(setPlayback({history:[]}));
    
    helyosService.workProcess.create(workprocess)
    .then( r => {
        const wprocess = r;
        const dispatchMessage = [wprocess].map(w => {
            const mission = helyosToAppModel.workProcessConvertor(w);
            return new DispatchMessage('created', mission);
        }); 
        Store.dispatch(reloadScheduleEvents(true));
        return bufferDispatches({ missions: dispatchMessage });
    });
}

export function createMultiDriveWorkProcess(missions: MissionData[], workTypeName, schedStartAt, schedEndAt) {
    const state = Store.getState();
    const toolIds = missions.map( m => parseInt(m.presence_id));
    const jsonData = missions.map( m => ({ 
                                            tool_id: parseInt(m.presence_id),
                                            target_id:  parseInt(m.target_id),
                                            target_type: m.target_type,
                                            x: m.x,
                                            y: m.y,
                                            orientation: m.orientations[0],
                                            orientations: m.orientations,
                                            anchor: 'front',
                                            schedStartAt: m.schedStartAt,
                                            schedEndAt: m.schedEndAt,
                                         })
                                 );

    const workProcess = {
        toolIds: toolIds,
        yardId: parseInt(state.activeYard.id as any),
        status: MissionStatus.Created,
        description: '',
        data: jsonData as any,
        workProcessTypeName: workTypeName,
        schedStartAt: schedStartAt,
        schedEndAt: schedEndAt,
    }
   
    helyosService.workProcess.create(workProcess)
    .then( r => {
        const wprocess = r;
        const dispatchMessage = [wprocess].map(w => {
            const mission = helyosToAppModel.workProcessConvertor(w);
            return new DispatchMessage('created', mission);
        });
        Store.dispatch(reloadScheduleEvents(true));
        return bufferDispatches({ missions: dispatchMessage });
    });


}



export function createMapWorkProcess(action: string, payload: any, workProcessTypeName: string='mapping') {
    const state = Store.getState();
    const yardId = state.activeYard.id;

    const data = {action, payload}
   
    const workProcess = {
        toolIds: [],
        yardId: yardId,
        status: 'dispatched',
        description: '',
        data: data,
        workProcessTypeName: workProcessTypeName,
        schedStartAt: new Date()
    }
    
    helyosService.workProcess.create(workProcess)
    .then( r => {
        getObstaclesAndDrivableAreas()
        .then(r => {
            const obstacleDispatchMessage = r.obstacles.map(o => new DispatchMessage('created',o));
            const drivableDispatchMessage = r.drivables.map(d => new DispatchMessage('created',d));
            Store.dispatch(changeEntityState({ nobstacles: drivableDispatchMessage, obstacles: obstacleDispatchMessage }));
        });
    });
}



function missionChangeRequestEnded(presenceId: string) {
    Store.dispatch(markMissionsOutdated(presenceId, 'outdated'));
    if (missionUpdateCancel) {
        window.clearTimeout(missionUpdateCancel);
    }
    scheduleMissionUpdates();
}


export function cancelMission(mission: Mission) {
    Store.dispatch(markMissionsOutdated(mission.presence_id, 'changing'));
    console.log("mission to end", mission);
    const wprocess = appToHelyosModel.missionToWorkProcessFormat(mission);
    const patch = {id: wprocess.id, status: 'canceling'};
    if (confirm(`Cancel the mission ID ${wprocess.id}?`)){
            return helyosService.workProcess.patch(patch)
                    .then(
                        () => missionChangeRequestEnded(mission.presence_id),
                    );
    }
}

