import { ApplicationState } from "./models";
import { Yard } from "../models/app.models";

export type Timestamp = number

export interface HasId {
    id: string
}

export type ItemUpdate<T> = Partial<T> & HasId

export interface EntityTypeChange<Data extends HasId> {
    timestamp: Timestamp
    created?: Data
    updated?: ItemUpdate<Data>
    deleted?: string
}

export interface IndexedCollection<Data> {
    [index: string]: Data
}

export interface EntityTypeCollection<Data extends HasId> {
    latest: Timestamp,
    items: IndexedCollection<Data>,
    updating: boolean,
    error: boolean
}

function change<Data extends HasId> (current: EntityTypeCollection<Data>,
                                     changes: Array<EntityTypeChange<Data>>): EntityTypeCollection<Data> {
    if (changes.length === 0) {
        return current
    }
    const currentItems = current.items
    const items = {...currentItems}
    let latest = current.latest
    for (const change of changes) {
        if (change.created) {
            const newPresence = change.created
            items[newPresence.id] = newPresence
        }
        if (change.updated) {
            const update = change.updated
            const currentValue: Data | undefined = currentItems[update.id]
            if (currentValue) {
                const updated: Data = Object.assign({}, currentValue, update)
                items[updated.id] = updated
            }
        }
        if (change.deleted) {
            delete items[change.deleted]
        }
        if (change.timestamp > latest) {
            latest = change.timestamp
        }
    }
    return {...current, items, latest}
}

function beginUpdate<Data extends HasId> (current: EntityTypeCollection<Data>): EntityTypeCollection<Data> {
    return {...current, updating: true, error: false}
}

function failUpdate<Data extends HasId> (current: EntityTypeCollection<Data>): EntityTypeCollection<Data> {
    return {...current, updating: false, error: true}
}

function finishUpdate<Data extends HasId> (current: EntityTypeCollection<Data>,
                                           changes: Array<EntityTypeChange<Data>>): EntityTypeCollection<Data> {
    return {...change(current, changes), updating: false, error: false}
}

export type EntityUpdate<Data extends HasId> = 'loading' | 'error' | Array<EntityTypeChange<Data>>

function applyEntityTypeChanges<Data extends HasId> (collection: EntityTypeCollection<Data>, update: EntityUpdate<Data>): EntityTypeCollection<Data> {
    if (update === 'loading') {
        return beginUpdate(collection)
    } else if (update === 'error') {
        return failUpdate(collection)
    } else {
        return finishUpdate(collection, update)
    }
}

export type AllEntities<Base> = {
    [P in keyof Base]: EntityTypeCollection<Base[P] & HasId>
    }

export type UpdateForAllEntities<Base> = {
    [P in keyof Base]?: EntityUpdate<Base[P] & HasId>
    }

export function applyUpdateForAllEntities<Base> (all: AllEntities<Base>, updates: UpdateForAllEntities<Base>): AllEntities<Base> {
    const newData: Partial<AllEntities<Base>> = {}
    for (const k in updates) {
        const old = all[k]
        const patched = applyEntityTypeChanges(old, updates[k] as EntityUpdate<Base[keyof Base] & HasId>) // cast to non-undefined (cannot be undefined because of iteration)
        // TODO: remove if typescript supports it ( works in ts playground)
        if (old !== patched) {
            (newData[k] as EntityTypeCollection<Base[keyof Base] & HasId>) = patched
        }
    }
    if (Object.keys.length > 0) {
        return Object.assign({}, all, newData)
    }
    return all
}

export function emptyCollection<T extends HasId> (): EntityTypeCollection<T> {
    return {
        error: false,
        items: {},
        latest: 0,
        updating: false,
    }
}

export function allEntites<T extends HasId> (collection: IndexedCollection<T>): T[] {
    const result: T[] = []
    for (const x in collection) {
        result.push(collection[x])
    }
    return result
}

export function entityOfYard<T extends HasId> (activeYard:Yard, collection: IndexedCollection<T>): T[] {
    const result: T[] = []
    for (const x in collection) {
        if (activeYard.id == collection[x]['yardId']){
            result.push(collection[x])
        }
    }
    return result
}

export function someEntites<T> (collection: IndexedCollection<T>, predicate: (test: T) => boolean): T[] {
    const result: T[] = []
    for (const x in collection) {
        if (predicate(collection[x])) {
            result.push(collection[x])
        }
    }
    return result
}
