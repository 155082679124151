import * as React from 'react'
import { connect } from 'react-redux'
import { createSelector } from 'reselect'
import { StatelessDropdown, TitleStyle } from '../../base/components/StatelessDropdown'
import { Target, TargetType } from '../../../models/app.models'
import { ApplicationState } from '../../../state/models'
import { someEntites } from '../../../state/updatetableCollection'
import { H_WorkProcessType } from 'helyosjs-sdk'

const activeWorkProcessType = createSelector(
    (state: ApplicationState) => state.entities.wProcessTypes,
    wProcessTypes => ({
        items: someEntites(wProcessTypes.items, t => true),
        keyGenerator: (item: H_WorkProcessType, index: number) => item.id,
        placeholder: <a>choose</a>,
        render: (item: H_WorkProcessType) => <a className="item">{item.name}</a>,
    })
)

export interface OwnProps {
    selectedItem?: H_WorkProcessType,
    onSelect: (item: H_WorkProcessType) => any,
    style: TitleStyle,
}

export interface Calculated {
    items: Target[],
    render: (item: H_WorkProcessType) => JSX.Element | string,
    key?: (item: H_WorkProcessType, index: number) => string,
    placeholder: JSX.Element | string,
}

export const MissionTypeDropdown = connect<Calculated, {}, OwnProps>(activeWorkProcessType)(StatelessDropdown)
