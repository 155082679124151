import * as React from 'react'
import { Action } from '../../../state/actions'
import './Switch'
export interface Props {
    label: string,
    active: boolean,
}

export interface DispatchToggle {
    toggle: () => Action,
}

export class Switch extends React.PureComponent<Props & DispatchToggle, {}> {
    activeClass () {
        return this.props.active ? 'active' : ''
    }
    render () {
        return (
            <div className="Switch" onClick={() => this.props.toggle()}>
                <div className="switchlabel float-left">{this.props.label}</div>
                <div className={'toggleContainer float-right ' + this.activeClass()}>
                    <div className={'toggleIndicator ' + this.activeClass()}/>
                </div>
            </div>
    )}
}
