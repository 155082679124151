import * as React from 'react'
import './Dropdown.scss'

export type TitleStyle = 'placeholder' | 'selected'

export interface Props<T> {
    selectedItem?: T,
    items: T[],
    render: (item: T) => JSX.Element | string,
    keyGenerator?: (item: T, index: number) => string,
    onSelect: (item: T) => any,
    placeholder: string | JSX.Element,
    style: TitleStyle,
    className?: string,
}

const defaultKey = (_: any, idx: number) => idx.toString()

export const StatelessDropdown = <T extends {}> (props: Props<T>) => {
    const makeClasses = (current: T | undefined, item: T) =>
        'is-submenu-item is-dropdown-submenu-item' + ((current === item) ? ' active' : '')

    return <ul className={(props.className || '') + ' Dropdown dropdown menu'}>
        <li>{(props.style === 'selected' && props.selectedItem) ? props.render(props.selectedItem) : props.placeholder}</li>
        <ul className="menu submenu is-dropdown-submenu first-sub vertical">
            {
                props.items.map((item, idx) =>
                    <li key={(props.keyGenerator || defaultKey)(item, idx)}
                        className={makeClasses(props.selectedItem, item)}
                        onClick={() => {props.onSelect(item)}}
                    >
                        {props.render(item)}
                    </li>
                )
            }
        </ul>
    </ul>
}
