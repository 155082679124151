import * as React from 'react';
import { Led, State as LedState } from '../../base/components/Led';
import { PresenceStatus, canMissionBeCancelled, MissionData, MissionStatus, DestinationData } from '../../../models/app.models'
import { EnrichedMission, EnrichedPresence } from '../../../state/enriched';
import './PresenceBar.scss';
import {  VehicleThumbnail } from '../container/VehicleThumbnail';
import { PlaybackButtons, PlaybackComponent } from '../../base/components/Playback';
import { createInstantAction, helyosService } from '../../../services/app-service';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { bufferDispatches, DispatchMessage, Store } from '../../../state/store';
import { allEntites } from '../../../state/updatetableCollection';
import { ApplicationState } from '../../../state/models';
import { appToHelyosModel } from '../../../models/app-helyos-model-convertors';
import { helyosToAppModel } from '../../../services/helyos-app-model-convertors';
import { changeEntityState, reloadScheduleEvents } from '../../../state/actions';
import { H_WorkProcess } from 'helyosjs-sdk';
import { MissionTrigger, missionButtons } from './MissionButtons';


const PresenceStatusComponent: React.SFC<{ status: PresenceStatus | undefined }> = ({status}) => {
    switch (status) {
        case PresenceStatus.CheckoutReady:
            return <Led label="Bereit zum Checkout" state={LedState.warning}/>
        case PresenceStatus.Error:
            return <Led label="Error" state={LedState.alert}/>
        case PresenceStatus.Loading:
            return <Led label="Loading..." state={LedState.ok}/>
        case PresenceStatus.Manual:
            return <Led label="Manual" state={LedState.warning}/>
        case PresenceStatus.OnMission:
            return <Led label="in Mission" state={LedState.alert}/>
        case PresenceStatus.Recharging:
            return <Led label="Rechargin" state={LedState.ok}/>
        case PresenceStatus.Waiting:
            return <Led label="Reserved" state={LedState.alert}/>
        case PresenceStatus.Planning:
            return <Led label="Planning Path" state={LedState.alert}/>
        case PresenceStatus.Free:
            return <Led label="Free" state={LedState.ok}/>
        default:
            return <Led label={status} state={LedState.alert}/>
    }
}

interface Props {
    cancelMission: (m: EnrichedMission) => any
    presence?: EnrichedPresence,
    viewOnly: boolean,
    commandView: any
}

function missionToStop (presence: EnrichedPresence): EnrichedMission | undefined {
    return presence.missions.find(canMissionBeCancelled)
}

let player;



export function createMission(presence_id:number|string, destination:string, destinationData: DestinationData, 
    schedStartAt=null, workType='driving',  settings={}, status=MissionStatus.Created) {

    const state = Store.getState();
    destinationData.yardId = state.activeYard.id;
    const mission = new MissionData(presence_id, destination, destinationData, schedStartAt, workType);
    let workprocess = appToHelyosModel.missionToWorkProcessFormat(mission);
    workprocess.status = status;

    helyosService.workProcess.create(workprocess)
    .then( r => {
            const wprocess = r;
            const dispatchMessage = [wprocess].map(w => {
                    const mission = helyosToAppModel.workProcessConvertor(w);
                    return new DispatchMessage('created', mission);
            }); 
            
    Store.dispatch(reloadScheduleEvents(true));
    return bufferDispatches({ missions: dispatchMessage });
});
}

export function createGenericMission(missionAgents:any, destinationData: DestinationData, 
    schedStartAt=null, workType='driving',  settings={}, description='', status=MissionStatus.Created) {
    const state = Store.getState();


    const workProcess : Partial<H_WorkProcess> = {
        yardId: state.activeYard.id as any ,
        status: 'dispatched',
        data: destinationData as any,
        workProcessTypeName: workType,
        schedStartAt: schedStartAt,
        description: description
    }

    if (missionAgents) {
        if (missionAgents.ids){
            workProcess.toolIds = missionAgents.ids.map(e => parseInt(e));
        } else {
            workProcess.agentUuids = missionAgents.uuids;
        }
    }

    if (!workProcess.toolIds) {
        workProcess.toolIds = [];
    }

    helyosService.workProcess.create(workProcess)
    .then( r => {
            const wprocess = r;
            const dispatchMessage = [wprocess].map(w => {
                    const mission = helyosToAppModel.workProcessConvertor(w);
                    mission.description = description;
                    return new DispatchMessage('created', mission);
            }); 
            
            Store.dispatch(reloadScheduleEvents(true));
            return Store.dispatch(changeEntityState({ missions: dispatchMessage }));
    });
}

const parseMissionData = (missionData, replaceVariable) => {
    const state: ApplicationState = Store.getState();
    const availableMissions = allEntites(state.entities.wProcessTypes.items);
    const selectedMissions = availableMissions.filter( m =>  m.name === missionData.runMission);
    let userInput = '';
    if (!selectedMissions.length) return;

    const requestComplement = {};
    if (missionData.inputMessage) {
            const defaultInputValue = missionData.defaultInputValue? missionData.defaultInputValue: ''
            userInput =  prompt(missionData.inputMessage, defaultInputValue);
            if (!userInput) {
                console.log(userInput);
                return;
            } 

            requestComplement[missionData.inputVariable] = userInput;
    }
    const missionTypeName = missionData.runMission;
    const requestData = {...missionData.defaultRequest, ...requestComplement};
    const requestDataInterpreted = replaceVariable(requestData)
    const missionAgents = missionData.missionAgents? {uuids: missionData.missionAgents}: null;
    return {missionTypeName,requestDataInterpreted, selectedMissions, missionAgents, userInput};

}


export const PresenceBar: React.SFC<Props> = ({presence, viewOnly, cancelMission, commandView}) => {
    if (!presence) {
        return null
    }


    const autotruck_app = commandView.missionUI;

    const instantAction = () => {
        const command = prompt(`Send a command directly to the agent ${presence.name}` );
        if (command) {
            createInstantAction(presence.id, command).then(() => alert("command sent!"));
        }
    }


    const replaceVariable = (requestData) => {
        let strData = JSON.stringify(requestData)
        strData = strData.replace('$agentId$', presence.id.toString());
        strData = strData.replace('$agentUUID$', presence.uuid);
        return JSON.parse(strData);
    }

    const loadAndPlay = (missionData: MissionTrigger) =>{
        
        const parsedData = parseMissionData(missionData, replaceVariable);
        const missionTypeName = parsedData.missionTypeName;
        const requestDataInterpreted = parsedData.requestDataInterpreted;
        const selectedMissions = parsedData.selectedMissions;
        let missionAgents = parsedData.missionAgents;
        const missionDescription = parsedData.userInput;


        const sched_start = new Date();
        const sched_end = null;

        if (missionData.requireAgent && !missionAgents) { 
            missionAgents = {ids: [presence.id]};
        }


        createGenericMission(missionAgents, 
                        requestDataInterpreted,
                        sched_start, missionTypeName,
                        selectedMissions[0].settings, missionDescription); 


        alert(`Mission ${missionTypeName} requested`);

    }

    const stopMission = missionToStop(presence);
    const currentMissions = allEntites(Store.getState().entities.missions.items);
    // const presenceMissions = currentMissions.filter(m => m.toolIds.includes(parseInt(presence.id as string)));
    // let latestPresenceMission = null;
    // if (presenceMissions && presenceMissions.length)
    //     latestPresenceMission = presenceMissions.reduce((prev, curr) => prev.id > curr.id ? prev : curr);
    

    return <aside>
    <div className={'VehicleBar ' + (viewOnly ? 'disabled' : '')}>
        <div className="header">
            <div className="licence_plate">
                <span className="id">{presence.main_licence_plate}</span> 
                <br></br>
                {/* {presenceMissions?.length? 
                <span>#{latestPresenceMission.id} {latestPresenceMission.workType}: {latestPresenceMission.status} </span>:null} */}
            </div>

            {presence.taskProgress && presence.taskProgress.value? 
                    <div style={{width:'15%', padding:'6px'}}>
                        <CircularProgressbar value={Math.round(100*presence.taskProgress.value/presence.taskProgress.maximum)} text={`${Math.round(100*presence.taskProgress.value/presence.taskProgress.maximum)}%`} />
                    </div>
                    : null
            }

            <div style={{ flexGrow: 1, }}></div>
            <button type="button" className="button" disabled={viewOnly} style={{backgroundColor:"#32527b" }} 
                    onClick={() => instantAction()}>
                Instant Action
            </button>

            <button type="button" className="alert button" disabled={!stopMission || viewOnly} style={{fontSize:18}}
                    onClick={() => stopMission && cancelMission(stopMission)}>
                STOP
            </button>
        </div>

        <div className="sensorsbar">
                <div style={{position: 'relative'}}  className = "truckLive">
                    <VehicleThumbnail vehicleId={presence.id} initialLayer={"png"}> </VehicleThumbnail>
                </div>
        </div>

        <PresenceStatusComponent status={presence.status}/>


        <div style={{display:'flex', justifyContent: 'space-between' }}>

                {presence.sensors? 
                    <div style={{width:'45%', padding:'0px 0px 0px 40px', overflowY:'auto', height:'20vh'}}>
                            <h3> Sensors </h3>
                                
                                {presence.sensors.map(sensor => {
                                        return(
                                            <div  key={sensor.key}>
        
                                                    <div>
                                                        {sensor.type === 'number' ? 
                                                            <div className="toolSensorInfo">
                                                                        <span  className="sensorTitle">{sensor.title} :</span>   <span> {Math.round(sensor.value * 100) / 100 } </span>  <span> {sensor.unit} </span>

                                                            </div> : null }
                                                    </div>


                                                    <div>
                                                        {sensor.type === 'boolean' ? 
                                                            <div className="toolSensorInfo">
                                                                        <span className="sensorTitle">{sensor.title} :</span>  <span> {sensor.value?  "True" : "False"  } </span>  

                                                            </div> : null }
                                                    </div>


                                                    <div>
                                                        {sensor.type === 'string' ? 
                                                            <div className="toolSensorInfo">
                                                                        <span  className="sensorTitle">{sensor.title} :</span>  <span> {sensor.value} </span>

                                                            </div> : null }
                                                    </div>
                                            </div>
                                        )
                                    })
                                }

                </div> : null } 
    


            {missionButtons(loadAndPlay, autotruck_app)}

    
    </div>
    
    </div>


    </aside >
}




export const NullPresenceBar: React.SFC<any> = ({commandView}) => {

    const autotruck_app = commandView.missionUI;

    const replaceVariable = (requestData) => {
        let strData = JSON.stringify(requestData)
        strData = strData.replace('$agentId$', "none");
        strData = strData.replace('$agentUUID$', "none");
        return JSON.parse(strData);
    }

    const loadAndPlay = (missionData: MissionTrigger) =>{

        if (missionData.requireAgent) {
                alert("Please select an Agent") 
                return;
        }
        const parsedData = parseMissionData(missionData, replaceVariable);
        const missionTypeName = parsedData.missionTypeName;
        const requestDataInterpreted = parsedData.requestDataInterpreted;
        const selectedMissions = parsedData.selectedMissions;
        const missionDescription = parsedData.userInput;
        let missionAgents = parsedData.missionAgents;

        const sched_start = new Date();
        const sched_end = null;


        createGenericMission(missionAgents, 
                        requestDataInterpreted,
                        sched_start, missionTypeName,
                        selectedMissions[0].settings, missionDescription); 


        alert(`Mission ${missionTypeName} requested`);

    }

    return <aside>
        <div className='VehicleBar'>
            <div className="header">
                <div className="licence_plate">
                        Select a vehicle
                </div>
            </div>

            {missionButtons(loadAndPlay, autotruck_app)}
            
            <div >
                    <PlaybackComponent></PlaybackComponent>
            </div>
        </div>
    </aside >
}




// const { canvasOverlay } = require('../../../../vendor/javascript/leafletjs-canvas-overlay/L.CanvasOverlay')
