import * as React from 'react'
import './Led.scss'

export enum State {
    ok = 'OK',
    warning = 'Warning',
    alert = 'Alert',
}

export interface Props {
    label: string,
    state: State,
}

export class Led extends React.PureComponent<Props, {}> {
    stateClass () {
        return this.props.state
    }
    render () {
        return (
            <div className="Led">
            <div className="ledlabel float-left">{this.props.label}</div>
                <div className={'ledlight float-right ' + this.stateClass()}></div>
            </div>
    )}
}
