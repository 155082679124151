import * as React from 'react'

import './Switch.scss'

/**
 * Foundation switch component that does not use element ids.
 *
 * Provides an additional 'tinier' class to switches smaller than the
 * foundation 'tiny' size (bc. I think even tiny is still fucking large).
 */

interface Props {
    className?: string
    id?: string
    isOn: boolean
    onClick?: (isOn: boolean) => void
    screenReaderText?: string
    style?: React.CSSProperties
    title?: string
}

export class Switch extends React.PureComponent<Props> {
    handleClick = () => {
        if (!this.props.onClick) {
            return
        }

        this.props.onClick(!this.props.isOn)
    }

    render () {
        return (
            <div className={`switch ${this.props.className || ''}`} style={this.props.style}>
                <input className="switch-input" id={this.props.id} type="checkbox" checked={this.props.isOn} readOnly />
                <span className="switch-paddle" title={this.props.title} onClick={this.handleClick}>
                    {this.props.screenReaderText ? <span className="show-for-sr">{this.props.screenReaderText}</span> : null}
                </span>
            </div>
        )
    }
}
