import * as React from 'react'
import { connect } from 'react-redux'
import { createSelector } from 'reselect'
import { Mission, Specification, Yard } from '../../../models/app.models'
import { EnrichedPresence } from '../../../state/enriched'
import { ApplicationState, Command } from '../../../state/models'
import { cancelMission, createInstantAction, editPresence } from '../../../services/app-service';
import { InjectedPresence, withSelectedPresence } from '../../base/modules/with-presence'
// import { Events as MapEvents, Props as VehicleMapProps, VehicleMapImpl } from '../../maps/components/VehicleMap'
import './CommandView.scss'
import { ModalDialog } from '../../base/components/ModalDialog';
import { Store, Button } from '../../base';
import { showDialog, collapseSchedule, selectPresence, editSelectedPresence, setYard } from '../../../state/actions';
import {FileBase64} from '../../base/components/React-file-base64';



interface PresenceDialogProps {
    showDialog : boolean;
    presence: EnrichedPresence;
    yard: any;
}

 

const ToolDetailsDialogSelector : (state: ApplicationState, selected: InjectedPresence) => (PresenceDialogProps) = createSelector(
    (state: ApplicationState) => state.activeYard,
    // (state: ApplicationState, selected: InjectedPresence) => selected.presence,
    (state: ApplicationState) => state.uiControl,
    (activeYard, uiControl) => {
        
        return {
            showDialog: uiControl.showDialog,
            presence: uiControl.selectedPresence,
            yard: (activeYard ? {id: activeYard.id, origin:activeYard.origin} : { origin: { lat: 51.0531973, lon: 13.7031056 } } as Yard),
        }

    }
)




const ToolDetailsDialog =  (props: PresenceDialogProps ) => {
        const confirmAction  = () => { 
            const inputElement: HTMLInputElement = document.getElementById('instantActionInput') as HTMLInputElement;
            if (inputElement && inputElement.value) {
                createInstantAction(props.presence.id, inputElement.value).then(() => alert("command sent!"));
            }
            editPresence( props.presence);
            Store.dispatch(showDialog(false))
        }
        const cancelAction = () => { Store.dispatch(showDialog(false))}

        const getFiles= (files) => {
            Store.dispatch(editSelectedPresence({picture: files['base64']}));
        };

        const handleChange = (event) => {
            const stateAttr = event.target.name;
            const statePatch = {}
            if (stateAttr === 'specification') {
                    try {
                        statePatch[stateAttr] = JSON.parse(event.target.value);
                    } catch (error) {
                        statePatch[stateAttr] = event.target.value;
                    }
            } else {
                statePatch[stateAttr] = event.target.value;
            }
            console.log(statePatch)

            Store.dispatch(editSelectedPresence(statePatch));
        }


          
            if (props.showDialog && props.presence) {
                    return (

                        <div style={{bottom: '212px !important'}}>
                        <ModalDialog   confirmAction={confirmAction} cancelAction={cancelAction} title="Vehicle Information">
                            <div style = {{display:'flex'}} >
                                <div>
                                    <img src={props.presence.picture}></img>
                                    <FileBase64
                                            multiple={ false }
                                            onDone={ getFiles.bind(this) } />

                                    {/* <pre>{JSON.stringify(props.vehicle, null, 2) }</pre> */}
                                </div>
                                <div style={{width:"50%"}}>
                                    <input  name="name" type="text" placeholder="Name" 
                                        value={props.presence.name}  onChange={handleChange.bind(this)}/>
                                    <input id="instantActionInput" name="_instantAction" type="text" placeholder="instant action command" />
                                    <h5> Tructrix geometry format </h5>
                                    <textarea  rows={5} value={JSON.stringify(props.presence.specification) } name="specification"
                                                onChange={handleChange.bind(this)} > 
                                    </textarea>
                                </div>                          
                            </div>                          
                        </ModalDialog>
                        </div>
                    )
            } else {

                 return <div></div>

            }
    }




export const ToolDetailsDialogConnected = connect<PresenceDialogProps>(ToolDetailsDialogSelector)(ToolDetailsDialog);