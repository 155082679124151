import * as _L from 'leaflet'

import { LatLonTuple, PolygonPoints } from '../types'

/**
 * Options for drawing leaflet map polygons
 */
export const LEAFLET_PM_DRAW_OPTIONS = {

    // the API won't deal with this
    allowSelfIntersection: false,

    draggable: false,

    // feels nice
    finishOn: 'dblclick',

    // unintrusive light snapping, helps when closing polygons
    snapDistance: 3,
    snappable: true,
}

/**
 * Return the PolygonPoints from a Leaflet Polygon layer.
 */
export function getPolygonPointsFromLayer (l: _L.Polygon): PolygonPoints {
    // @types/leaflet 1.2.0 is wrong here, getLatLngs of a poly layer returns a list of lists latlngs
    // to represent geojson multipolygons (outerhull + holes)
    const outerPolygonHullLatLngs: _L.LatLng[] = (l.getLatLngs()[0] as any)

    return outerPolygonHullLatLngs.map((latLng: _L.LatLng): LatLonTuple => [latLng.lat, latLng.lng])
}

/**
 * Check whether b differs from a by exactly one additional polygon point
 */
export function hasOnePointBeenAdded (a: PolygonPoints, b: PolygonPoints): boolean {
    if (a.length !== b.length - 1) {
        return false
    }

    let ai = 0
    let bi = 0
    let onePointAdded = false

    while (ai < a.length && bi < b.length) {
        if (a[ai][0] === b[bi][0] && a[ai][1] === b[bi][1]) {
            ai++
            bi++
        } else {
            if (onePointAdded) {
                return false
            }

            bi++
            onePointAdded = true
        }
    }

    return true
}
