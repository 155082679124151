import * as React from 'react'
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { createSelector } from 'reselect'
import { Led, State as LedState } from '../../base/components/Led'
import { AgentClass, Presence, PresenceStatus } from '../../../models/app.models'
import { ApplicationState } from '../../../state/models'
import { allEntites } from '../../../state/updatetableCollection'
import { VehicleThumbnail } from '../container/VehicleThumbnail';
import './AllPresences.scss';
import Basic from './mission_table';
// import Basic from './scheduledTools';
import { enrichPresence } from '../../../state/enriched';
import { Store } from '../../base';
import { setFilterStatus, showDialog, selectPresence } from '../../../state/actions';
import { CanvasHTMLAttributes } from 'react'
import { renderVehicle, VehicleInstance } from '../../maps/renderables/vehicle'
import { helyosLogin } from '../../../services/app-service'


interface Props {
    presences: Presence[],
    filterStatus: string,
}

const PresenceStatusComponent: React.SFC<{ presence: Presence, status: PresenceStatus | undefined }> = ({presence, status}) => {
    switch (status) {
        case PresenceStatus.CheckoutReady:
            return <Led label={presence.main_licence_plate} state={LedState.warning}/>
        case PresenceStatus.Error:
            return <Led label={presence.main_licence_plate} state={LedState.alert}/>
        case PresenceStatus.Loading:
            return <Led label={presence.main_licence_plate} state={LedState.ok}/>
        case PresenceStatus.Manual:
            return <Led label={presence.main_licence_plate} state={LedState.warning}/>
        case PresenceStatus.OnMission:
            return <Led label={presence.main_licence_plate} state={LedState.alert}/>
        case PresenceStatus.Recharging:
            return <Led label={presence.main_licence_plate} state={LedState.ok}/>
        case PresenceStatus.Waiting:
            return <Led label={presence.main_licence_plate} state={LedState.alert}/>
        case PresenceStatus.Free:
            return <Led label={presence.main_licence_plate} state={LedState.ok}/>
        case PresenceStatus.Planning:
            return <Led label={presence.main_licence_plate} state={LedState.warning}/>
        default:
            return <Led label={presence.main_licence_plate} state={LedState.warning}/>
    }
}






export class ToolThumbnail extends React.PureComponent<any> {

    constructor (props: any) {
        super(props)
        this.state = {


        }
    }


    componentWillReceiveProps(nextProps){
        this.draw(nextProps.presence)
    }


    draw(presence: Presence)  {
        if (!presence) { return; }
        const canvasElem = document.getElementById(presence.id.toString()) as any;
        const ctx = canvasElem.getContext("2d");
        const vehicle = new VehicleInstance(presence);
        if (!vehicle) { return; }
        if (!vehicle.parts) {return; }

        vehicle.position.x = -140+1120;  vehicle.position.y = 20;
        vehicle.parts.forEach(p=>p.angle= 0);
        ctx.canvas.style.zIndex = '100';
        renderVehicle(ctx, vehicle, 0.5, this.props.selected, null, 0.015);
    }


    show_detail(presence) {
        Store.dispatch(showDialog(true));
        Store.dispatch(selectPresence(presence));
    }

    render () {

        return (                
            <div  style={{width: '200', height:'80px', overflow: 'hidden' }} onDoubleClick={() => this.show_detail(this.props.presence)}>
                <canvas
                        width="200" height="80" id={this.props.presence.id.toString()}  >
                </canvas>
            </div>
            )
        }


    }


const AllPresencesBarImpl: React.SFC<Props> = ({presences, filterStatus}) => {
    if (!presences) {
        return <div></div>
    }
    let buttonLabel = 'All Agents';
    let dispacthFilterStatus = PresenceStatus.Free;
    if(filterStatus === PresenceStatus.Free) {
        buttonLabel = 'Free Agents'
        dispacthFilterStatus = null;
    }



    const show_detail = (presence) => {
        Store.dispatch(showDialog(true));
        Store.dispatch(selectPresence(presence));
    }
    return <aside>
        <div className={'AllPresences'}>
        <div className="header">
            <button type="button" className="button" 
                    onClick={() =>{ Store.dispatch(setFilterStatus(dispacthFilterStatus))}}>
             <span> {buttonLabel}</span>
            </button>
        </div>

        <div style={{height:'calc(50% - 110px)', overflow: 'auto'} }>
        {presences.filter(p=>p.agentClass==AgentClass.Vehicle).map(presence => {
                return (
                    <div key={presence.id + 'l'} className="crop-container">

                        <div className="truckli">
                            <div> <NavLink to={'/p' + presence.id}>

                                {presence.connectionStatus !== 'offline' ?
                                                    <PresenceStatusComponent presence={presence} status={presence.status}>
                                                    </PresenceStatusComponent>
                                                    :<div>
                                                    <span>{presence.main_licence_plate}</span> 
                                                    <span style={{fontSize:10}}> (offline)</span>
                                                    </div>
                                                }


                            </NavLink ></div>
                        </div>
              

                        
                        <div> <NavLink to={'/p' + presence.id}>
                        {presence.picture ?
                                    <div className="vehicle_selector" onDoubleClick={() => show_detail(presence)}>
                                        <img className="vehicle_selector_img" src={presence.picture} alt="load img"></img>
                                    </div> 
                                    :
                                    <ToolThumbnail presence={presence} selected={true}/>
                        }

                        </NavLink ></div>

                        {/* <div  className = "truckThummb">
                                <VehicleThumbnail vehicleId={presence.id} initialZoom={20} staticVehicle={true} > </VehicleThumbnail>
                        </div> */}

                    </div>

                    );
        })
        }
        </div>

        <span style={{color:'white'}}> Tools & Trailers:</span>
        <div style={{height:'calc(50% - 110px)', overflow: 'auto'} }>
        {presences.filter(p=>p.agentClass==AgentClass.Tool).map(presence => {
                return (
                    <div key={presence.id + 'l'} className="crop-container">

                        <div className="truckli">
                            <div> <NavLink to={'/p' + presence.id}>

                                {presence.connectionStatus !== 'offline' ?
                                                    <PresenceStatusComponent presence={presence} status={presence.status}>
                                                    </PresenceStatusComponent>
                                                    :<div>
                                                    <span>{presence.main_licence_plate}</span> 
                                                    <span style={{fontSize:10}}> (offline)</span>
                                                    </div>
                                                }


                            </NavLink ></div>
                        </div>
              

                        
                        <div> <NavLink to={'/p' + presence.id}>
                        {presence.picture ?
                                    <div className="vehicle_selector" onDoubleClick={() => show_detail(presence)}>
                                        <img className="vehicle_selector_img" src={presence.picture} alt="load img"></img>
                                    </div> 
                                    :
                                    <ToolThumbnail presence={presence} selected={true}/>
                        }

                        </NavLink ></div>

                        {/* <div  className = "truckThummb">
                                <VehicleThumbnail vehicleId={presence.id} initialZoom={20} staticVehicle={true} > </VehicleThumbnail>
                        </div> */}

                    </div>

                    );
        })
        }
        </div>

        <div className="helyoslogo"> 
        </div>
        </div>
    </aside >
}

const selectedPresence: (state: ApplicationState) => Props = createSelector(
    (state: ApplicationState) => state.activeYard,
    (state: ApplicationState) => state.entities.presences,
    (activeYard, presences) => {
        if (activeYard){
            return ( {
                presences: allEntites(presences.items).filter(p => p.yardId == activeYard.id ),
            })
        } else {
            return ( {
                presences: null,
            })
        }
        
    }
)

const selectedStatusPresence: (state: ApplicationState) => Props = createSelector(
    (state: ApplicationState) => state.activeYard,
    (state: ApplicationState) => state.entities.presences,
    (state: ApplicationState) => state.uiControl.filterStatus,
    (activeYard, presences, filterStatus) => {
        if (activeYard){
            return ( {
                presences: allEntites(presences.items).filter(p => p.yardId == activeYard.id && (p.status === filterStatus || !filterStatus)),
                filterStatus})
        } else {
            return ( {
                presences: null,
                filterStatus
            })
        }
        
    }
)



const AllPresencesTimeTableImpl: React.SFC<Props> = ({presences}) => {
    let updated: boolean;
    const setUpdate = (r)=>{
        updated = r;
    }
    
    return (
        // <div >
                <Basic presences={presences} updated={updated} minRows={4} setUpdateTime={setUpdate} />
        // </div>
    )
}


const selectedAndEnrichPresence: (state: ApplicationState) => Props = createSelector(
    (state: ApplicationState) => state.entities.presences,
    (state: ApplicationState) => state.entities.targets.items,
    (state: ApplicationState) => state.entities.missions.items,
    (presences, targets, missions) => {
        const stateSlice = allEntites(presences.items);
        const enrichedPresences = stateSlice.map(p=> enrichPresence(targets, missions, p))
        return ({
            presences: enrichedPresences,
        })
}
)

 const presenceListImp:  React.SFC<Props> = ({presences, filterStatus}) => {
    if (!presences) {
        return <div></div>
    }
    return <div>{presences.map(presence => {
                                return (
                                    <div key={presence.id + 'l'} className="crop-container">

                                        <div className="truckli">
                                            <div> <NavLink to={'/p' + presence.id}>

                                                {presence.connectionStatus !== 'offline' ?
                                                    <PresenceStatusComponent presence={presence} status={presence.status}>
                                                     </PresenceStatusComponent>
                                                     :
                                                     <Led label={presence.main_licence_plate + 'offline'} state={LedState.warning}>
                                                     </Led>
                                                 }
                                             </NavLink></div>
                                        </div>
                            
                                        <div  className = "truckThummb">
                                                <VehicleThumbnail vehicleId={presence.id} initialZoom={20} staticVehicle={true} > </VehicleThumbnail>
                                        </div>

                                    </div>

                                    );
                                    })
            }
            </div>
}


export const AllPresencesBar = connect(selectedStatusPresence)(AllPresencesBarImpl)
// export const AllPresencesBar = AllPresencesBarImpl;
export const PresenceList = connect(selectedStatusPresence)(presenceListImp)
export const AllPresencesTimeTable = connect(selectedAndEnrichPresence)(AllPresencesTimeTableImpl)

