import { Mission, Pose } from '../../../models/app.models'

interface Trajectory {
    poses: Pose[]
}

export function renderMission (ctx: CanvasRenderingContext2D, mission: Mission, mmPerPx: number) {
    if (mission.trajectory === undefined) {
        return
    }
    ctx.beginPath()
    ctx.lineWidth = 3 * mmPerPx
    ctx.fillStyle = 'transparent'
    ctx.strokeStyle = 'red'
    const traj = mission.trajectory as Trajectory
    if (traj.poses) {
        traj.poses.forEach(pose => {
            ctx.lineTo(pose.x, pose.y)
        })
    }
    ctx.stroke()
}
