import { createStore } from 'redux'
import { changeEntityState, markMissionsOutdated } from './actions'
import { initialState, ApplicationEntityBase } from './models'
import { autotruckLogic } from './reducers'
import { UpdateForAllEntities } from './updatetableCollection';

const DEBUG = false;

export const Store = createStore(autotruckLogic, initialState,
    (typeof window !== 'undefined') && (window as any).__REDUX_DEVTOOLS_EXTENSION__ && (window as any).__REDUX_DEVTOOLS_EXTENSION__()
)

export class DispatchMessage <T>{
    timestamp: Date;
    created?: T;
    updated?: T;
    deleted?: T;

    constructor(operation: 'created'|'updated'|'deleted', data:T){
        this.timestamp = new Date();
        this[operation]= data;
    }
}


// -------------------------- Buffering Dispatches -------------------------------
//  Store Dispatches triggered by database notifications (via websocket) are buffered to avoid excessive updates and re-renderings of the interface
//  which would decrease the app performance

export class dispatchTimerParams  { 
    timer: number;
    _tIni: number;
    interval: number;
    bufferTimeLimit: number;

    constructor( bufferTimeLimit:number, dispachInterval:number){
        var d = new Date();
        this._tIni= d.getTime();
        this.bufferTimeLimit = bufferTimeLimit;
        this.interval = dispachInterval;
    }

    getBufferTime(){
        var d = new Date();
        return d.getTime() - this._tIni ;
    }

    reset(){
        var d = new Date();
        this._tIni= d.getTime();
    }

    hasBufferTimeExceeded(){
        return (this.getBufferTime() - this.bufferTimeLimit > 0);
    }
};

const dParams = new dispatchTimerParams(100, 250);
const bufferedDispatchData: any[] = [];




function groupById(objArray: any[]) {
    let groupList: any[][] = [];
    let clone = [...objArray];
    if (!objArray.length) return [[]];

    while (clone.length) {
        let obj = clone[0];;
        let group = clone.filter((e:any) => e.updated.id===obj.updated.id);
        groupList.push(group);
        clone = clone.filter((e:any) => e.updated.id!==obj.updated.id);   
    }

    return groupList;
}

function mergeObjectArray(objArray: any[]){
    let mergedObj = {...objArray[0]};
    objArray.forEach(element => {
        mergedObj.updated = Object.assign(mergedObj.updated, element.updated);
    });

    return mergedObj;
}



 function mergeUpdates(dispatch) {
    for (const key in dispatch) {
        const updateDispatches = dispatch[key].filter(d=>d.hasOwnProperty('updated'));
        const notUpdateDispatches = dispatch[key].filter(d=>!d.hasOwnProperty('updated'));

        if (updateDispatches.length > 1){
            const mergedUpdates = [];

            const groupedUpdates: any[][] = groupById(updateDispatches);
            groupedUpdates.forEach(u => {
                const mergedObj = mergeObjectArray(u);
                mergedUpdates.push(mergedObj);
            });

            dispatch[key] = [ ...mergedUpdates, ...notUpdateDispatches];
        }
    }
    return dispatch;
 }


function mergeDispatches(bufferedDispatchData,dispatch: UpdateForAllEntities<ApplicationEntityBase>) {
    let clonedDispatch = {... dispatch};

    if (!bufferedDispatchData.length){
        clonedDispatch = mergeUpdates(clonedDispatch);
        bufferedDispatchData.push(clonedDispatch);
        return;
    }

    const lastElement = bufferedDispatchData[0];


    for (const key in dispatch){
        if (lastElement.hasOwnProperty(key)){
            if ( lastElement[key] && lastElement[key].length){  
                const updateDispatches = dispatch[key].filter(d=>d.hasOwnProperty('updated'));
                const notUpdateDispatches = dispatch[key].filter(d=>!d.hasOwnProperty('updated'));
                const updateElements = lastElement[key];

                updateDispatches.forEach( d => {
                    const elemToMerge = updateElements.find(e=> e.updated && e.updated.id===d.updated.id);
                    if (elemToMerge) {
                        Object.assign(elemToMerge.updated, d.updated);
                    } else {
                        lastElement[key]= lastElement[key].concat(d);
                    }
                })

                lastElement[key]= lastElement[key].concat(notUpdateDispatches);
            }else {
                lastElement[key] = dispatch[key];
            }
        }
        else {
            lastElement[key] = dispatch[key];
        }
    }

    return lastElement;
}


export function bufferDispatches(dispatch){
    
    if (DEBUG) {
        console.log("bufferedDispatchData Antes",[...bufferedDispatchData])
        console.log("dispatch To ADD",{...dispatch})
        console.log("bufferedDispatchData Result",[...bufferedDispatchData])
    }


    if(dParams.timer) {
        if (DEBUG) { console.log("SAVED DISPATCH FOR THE NEXT ITERATION",{...dispatch})};
        mergeDispatches(bufferedDispatchData,dispatch);

    } else {
        mergeDispatches(bufferedDispatchData,dispatch);
        var idTimer = window.setInterval( function () {
            if (DEBUG) { console.log("dispatch-tick", [...bufferedDispatchData])}            
            if (bufferedDispatchData.length > 0 ) {
                // console.log(Object.keys(bufferedDispatchData[bufferedDispatchData.length -1 ]));
                Store.dispatch(changeEntityState((bufferedDispatchData.shift())));
                dParams.timer = idTimer;
            } else {
                window.clearInterval(idTimer);
                dParams.timer = 0;
            }

        }, dParams.interval);
     }
};



// Filter items which are within the timeSlot of the list hearder. 
// hearderTime  < itemsFiltered < hearderTime + timeSlot
export function sliceTimeSlot(items: any[], field: string, timeSlot: number) {
    let itemsInSlot = [];
    let remainingItems = [...items];
    const startState = new Date(items[0][field]).getTime();
    let nextTime;

    for (let index = 0; index < items.length; index++) {
        const item = items[index];
        const itemTime = new Date(item[field]).getTime();

        if (itemTime<(startState+timeSlot)){
            const itemToRemove = remainingItems.findIndex(i=>i.id === item.id);
            remainingItems.splice(itemToRemove,1)
            itemsInSlot.push(item);
        } else {
            nextTime = itemTime;
            break;
        }
    }

    const deltaTime =  nextTime - startState;

    return { itemsInSlot, remainingItems, deltaTime};
}