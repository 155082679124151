import * as React from 'react'
import { connect } from 'react-redux'
import { createSelector } from 'reselect'
import { Mission, Specification, Yard } from '../../../models/app.models'
import { EnrichedPresence } from '../../../state/enriched'
import { ApplicationState } from '../../../state/models'
import { allEntites, entityOfYard } from '../../../state/updatetableCollection'
import { InjectedPresence, withSelectedPresence } from '../../base/modules/with-presence'
import { LayeredMapDrawComponent, MissionLayerProps, ObstacleLayerProps, Props as MapProps, TargetLayerProps, VehicleLayerProps } from '../../maps/components/LayeredMapDraw'
// import { Events as MapEvents, Props as VehicleMapProps, VehicleMapImpl } from '../../maps/components/VehicleMap'
import { Vehicle, VehiclePart } from '../../maps/renderables/vehicle'
import './CommandView.scss'
import { Store } from '../../base';
import { selectPresence } from '../../../state/actions';



const VehicleMapSelector: (state: ApplicationState, ownProps: any) => (MapProps) = createSelector(
    (state: ApplicationState) => state.entities.yards.items,
    (state: ApplicationState) => state.entities.presences.items,
    (state: ApplicationState) => state.entities.targets.items,
    (state: ApplicationState) => state.entities.obstacles.items,
    (state: ApplicationState) => state.entities.nobstacles.items,
    (state: ApplicationState) => state.entities.missions.items,
    (state: ApplicationState, ownProps: any) => ownProps.vehicleId,
    (state: ApplicationState, ownProps: any) => ownProps.staticVehicle,

    (yards, presences, targets, obstacles, nobstacles, missions, vehicleId, staticVehicle) => {
        let presenceArray = allEntites(presences)

        if (vehicleId) {
                presenceArray = presenceArray.filter(p=>p.id === vehicleId);
                if (staticVehicle) {
                    presenceArray[0] = {...presenceArray[0]};
                    presenceArray[0].pose = {...presenceArray[0].pose,...{orientations:[1570.8], x:0, y:0}};
                }
        }

        const vehiclePositions = presenceArray.map(presence => ({ x: presence.pose.x, y: presence.pose.y, id: presence.id }))
        // const vehicleParts: VehiclePart[][] = presenceArray.map(p=>p.parts);

        const vehicleParts: VehiclePart[][] = presenceArray.map((presence: Partial<Presence>) => {
            const presenceSpec = presence.specification as any
            if (!presenceSpec) {return null}
            const parts: Specification[] = presenceSpec.vehicles ? presenceSpec.vehicles : presenceSpec
            return parts.map((spec, index) => {
                let angle = 0;
                if (presence.pose.orientations &&  presence.pose.orientations[ index ] !== null) {
                    angle = presence.pose.orientations[ index ] / 1000;
                } else {
                    angle = presence.pose.orientation? presence.pose.orientation : 0;
                }
                return {
                        angle: angle,
                        ...spec}
            })
        });

        const vehicles = vehiclePositions.map((position, index) =>
            ({
                id: position.id,
                parts: vehicleParts[ index ],
                position: { x: position.x, y: position.y },
            })
        )
        // Store.dispatch(selectPresence(selectedPresence));
            
        const activeYard = yards[presenceArray[0].yardId];
        const obstaclesArray = entityOfYard(activeYard, obstacles)
        const nobstaclesArray = entityOfYard(activeYard, nobstacles)
        const missionsArray = allEntites(missions)
        return {   
            vehicleId,

            missionLayer: {
                missions: missionsArray.filter(mission => mission.trajectory !== undefined && mission.trajectory.path !== undefined ),
            } as MissionLayerProps,
            obstacleLayer: {
                nobstacles: [],
                obstacles: obstaclesArray.filter(obstacle => obstacle.isObstacle),
            } as ObstacleLayerProps,
            drivableLayer: {
                nobstacles: nobstaclesArray,
                obstacles:[],
            } as ObstacleLayerProps,
            targetLayer: {
                targets:[],
            } as TargetLayerProps,
            vehicleLayer: {
                selectedPresence: null,
                vehicleClicked: (presenceId: string) => {
                    Store.dispatch(selectPresence(presenceArray[0]));
                 },
                vehicles: (vehicles as Vehicle[]),
            } as VehicleLayerProps,
            yard: (activeYard ? {id: activeYard.id, origin:activeYard.origin} : { id:0, origin: { lat: 51.0531973, lon: 13.7031056 } } as Yard),
        }
    }
)






export const VehicleThumbnail = connect<MapProps>(VehicleMapSelector)(LayeredMapDrawComponent)

