import * as React from 'react'
import { connect } from 'react-redux'           
import { NavLink } from 'react-router-dom'
import { createSelector } from 'reselect'
import { Props as DropdownProps, StatelessDropdown, TitleStyle } from '../../base/components/StatelessDropdown'
import { Presence, Yard } from '../../../models/app.models'
import { ApplicationState } from '../../../state/models'
import { allEntites } from '../../../state/updatetableCollection'
import { InjectedPresence, withSelectedPresence } from '../modules/with-presence'
import { Store } from '../../../state/store';
import { setYard } from '../../../state/actions'


interface InjectedYard{
    yard : Yard,
    history: History
}

const goToYard = (yardId) => {
    Store.dispatch(setYard(yardId));
}


const possibleYards: (state: ApplicationState, selected: InjectedYard) => DropdownProps<Yard>  = createSelector(
    (state: ApplicationState) => state.entities.yards,
    (state: ApplicationState, selected: InjectedYard) => selected.yard,
    (state: ApplicationState, selected: InjectedYard) => selected.history,
    (yards, yard, history) => ({
        items: allEntites(yards.items),
        keyGenerator: (item: Yard, index: number) => item.id,
        onSelect: (yard: Yard) => goToYard(yard.id),
        placeholder: <NavLink to='/p'>Yard</NavLink>,
        render: (item: Yard) => <NavLink className="item" to={'/p'}>{item.name}</NavLink>,
        selectedItem: allEntites(yards.items).find(item => !!(yard && item.id === yard.id)),
        style: 'placeholder' as TitleStyle,
    })
)

const Connected = connect<DropdownProps<Yard>, {}, InjectedYard>(possibleYards)(StatelessDropdown)
export const YardDropdown = Connected;
