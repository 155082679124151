import * as React from 'react'

import { Button } from '../../base'

export const BUTTON_WIDTH = '2.4rem'

interface Props {
    active?: boolean
    disabled?: boolean
    onClick: () => void
    style?: React.CSSProperties
    title?: string
}

export class MapButton extends React.PureComponent<Props> {
    render () {
        const active: React.CSSProperties = this.props.active ? {
            backgroundColor: '#e0e0e0',
            border: '1px solid #b3b3b3',
        } : {}

        return (
            <Button className="LeafletMapComponent__Button"
                    disabled={this.props.disabled}
                    onClick={this.props.onClick}
                    style={{
                        // tuned to the same height as the GooglePlacesAutocomplete component
                        padding: '0.61rem 0.4rem',
                        width: BUTTON_WIDTH,
                        ...active,
                        ...this.props.style,
                    }}
                    title={this.props.title}
            >
                <div style={this.props.active ? {filter: 'brightness(1.3) saturate(1.1)'} : {}}>
                    {this.props.children}
                </div>
            </Button>
        )
    }
}
