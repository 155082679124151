import { GeoPoint, H_Guideline, H_Tools, Timestamp } from "helyosjs-sdk";


//  Data mapping between Helyos and the WebApp:

// H_TOOLS => PRESENCES => VEHICLES
// H_WORKPROCESS => MISSIONS
// H_SHAPES => OBSTACLES
// H_TARGET => TARGET
// H_GUIDELINE => GUIDELINE


// --------------- VEHICLES -----------------//
export interface Position {
    x: number,
    y: number,
}


export class OverlayPosition {
    p1: Partial<GeoPoint> = {};
    p2: Partial<GeoPoint>  = {};
    p3: Partial<GeoPoint>  = {};
    p4: Partial<GeoPoint>  = {};
    pCenter?:  Partial<GeoPoint> = {};
    Ref: Partial<GeoPoint> =  {};
    rotation?: number;
    RA: number;
    RB: number;
    RC: number;
    RD: number;
    m1: Partial<GeoPoint>  = {};
    m2: Partial<GeoPoint>  = {};
    m3: Partial<GeoPoint>  = {};
}


export class  Yard {
    id?: number | string;
    origin?: GeoPoint;
    name?: string;
    yardType?: string;
    createdAt?: string | Date;
    deletedAt?: string | Date;
    modifiedAt?: string | Date;
    picture?: string;
    picturePos?: OverlayPosition = new OverlayPosition();
}

export class Presence {
    id: string | number;
    uuid: string;
    battery_soc?: number; // 0..1
    created: string | Date;
    modified: string | Date;
    pose: Pose;
    specification: Specification[];
    status: PresenceStatus;
    connectionStatus: 'offline' | 'online';
    main_licence_plate: string;
    name?: string;
    yardId: string | number;
    sensors: any;
    deltaAnimation: number;
    posModifiedAt: number;
    picture: string;
    streamUrl: string;
    taskProgress: any;
    agentClass: AgentClass;
    toolType: string;


    constructor(tool: H_Tools){

    }
}

export interface Specification {
    id: number,
    tool_type: string,
    length: number,
    width: number,
    height: number,
    chassis_position: Position,
    rear_joint_position: Position,
    ground_clearance: number,
    max_front_joint_angle: number,
    axles: Axle[]
}

export interface Axle {
    id: number,
    position: Position,
    wheel_positions: number[],
    tire_diameter: number,
    tire_width: number,
}

export enum PresenceStatus {
    Waiting = 'ready',
    Free = 'free',
    OnMission = 'busy',
    Recharging = 'recharging',
    Loading = 'loading',
    Manual = 'manual',
    CheckoutReady = 'checkout_ready',
    Error = 'error',
    Planning = 'planning',
}


export enum AgentClass {
    Tool = 'tool',
    Vehicle = 'vehicle',
    Assistant = 'assistant',
    ChargeStation = 'charge_station'
}

export function getEnumIndex (enumDefinition,value:any){
    for(let index in enumDefinition){
        if (enumDefinition[index] == value)
            return enumDefinition[index]
    }

    return null
}



// --------------- MISSION -----------------//

export interface Mission extends HasId {
    id: string | number;
    name?: string;
    startsAt: Date | string;
    endsAt: Date | string;
    yardId: string | number;
    mission_type: MissionType,
    custom_payload: any,
    status: MissionStatus,
    start_time?: Timestamp,
    schedStartAt?: Timestamp,
    schedEndAt?: Timestamp,
    presence_id: string,
    target_id?: string,
    target_ids?: any[],
    trajectory?: Trajectory
    target_type?: string;
    target_name?: string;
    x ?: number,
    y ?: number,
    orientation?: number,
    orientations?: number[],
    workType: string;
    description?: string;
    anchor: TargetAnchor;
    toolIds: any[]
}
type Trajectory = any

export enum MissionType {
    EntranceToGate = 'entrance_to_gate',
    GateToGate = 'gate_to_gate',
    GateToExit = 'gate_to_exit',
    EntranceToParking = 'entrance_to_parking',
    ParkingToParking = 'drive',
    ParkingToExit = 'parking_to_exit',
    ParkingToGate = 'parking_to_gate',
    GateToParking = 'gate_to_parking',
}


export enum MissionStatus {
    Unplanned = 'unplanned',
    Planning = 'planning',
    Calculating = 'calculating',
    Driving = 'executing',
    Successful = 'succeeded',
    NoPathPossible = 'no_path_possible',
    NoTrajectoryPossible = 'planning_failed',
    Error = 'error',
    UserCancelled = 'cancelled',
    UserCanceled = 'canceled',
    Discarded = 'discarded',
    Failed = 'failed',
    Created = 'created',
}


export interface DestinationData {
    target_id?: string,
    targetIds?: any[],
    target_name?: string,
    pose?: Pose,
    targetType?: string,
    yardId?: number | string,
    anchor?:TargetAnchor,
    custom_payload: any,
}

export class MissionData implements Mission {
    id: string;
    mission_type: MissionType;
    custom_payload: any;
    startsAt: Date | string;
    endsAt: Date | string;
    status: MissionStatus;
    start_time: Timestamp;
    schedStartAt: Timestamp;
    schedEndAt?: Timestamp;
    presence_id: string;
    workType: string;
    target_id: string;
    target_ids: any[];
    trajectory: Trajectory;
    target_type?: string;
    target_name?: string
    x : number;
    y : number;
    anchor: TargetAnchor;
    orientation: number;
    orientations: number[];
    yardId: number | string;

    constructor(presence_id, destination: string, data: DestinationData, sched_start: Timestamp, workType:string = 'driving',schedEndAt:Timestamp=null){
        this.presence_id = presence_id;
        this.status =  MissionStatus.Created;
        this.yardId = data.yardId;
        this.workType = workType;
        this.schedStartAt = sched_start;
        this.schedEndAt = schedEndAt;
        this.custom_payload = data.custom_payload;
        this.anchor = data.anchor || TargetAnchor.Front;

        switch (destination) {
            case 'location':
                const data_pose: any = data.pose? data.pose: data;
                this.x = data_pose.x;
                this.y = data_pose.y;
                this.orientation = data_pose.orientations[0];
                this.orientations = data_pose.orientations;

                break;
 
            case 'target':
                this.target_id = data['targetId'] || data.target_id;
                this.target_name = data['targetName'] ||  data.target_name;

                break;

            case 'targets':
                this.target_ids = data.targetIds;
                break;
        
            default:
                break;
        }
    
    }

}


// --------------- OBSTACLES -----------------//

export interface Obstacle extends HasId {
    top?: number,         //
    bottom?: number,      // 
    type?: string,
    cost?: number,        //
    removable?: boolean   //
    points: Array<[number, number]>,
    layer: LayerType,
    geometryType: string,
    isObstacle: boolean,
    yardId: number | string,
    data: any;
}
export interface Nobstacle extends Obstacle{}

export enum LayerType {
    base = 'base',
    temporary = 'temporary',
}




// --------------- TARGETS -----------------//


export class Target {
    id: string;
    name: string;
    pose: Pose;
    display: any;
    type: TargetType;
    anchor: TargetAnchor;
    width: number = 2000;
    length: number = 2000;;
    yardId: number;

    constructor(yardId,name, x, y, orientations: number[], anchor= TargetAnchor.Front,type=TargetType.Parking){
        this.yardId= yardId;
        this.name= name;
        this.pose= {x, y , orientations};
        this.anchor = anchor;
        this.length = 2000;
        this.width = 2000;
        this.type = type;
    }

} 

export interface Guideline extends HasId  {
        name: string;
        startX: number;
        startY: number;
        startOrientation: number;
        type: string;
        points: Array<[number, number]>,
        geometry: any;
        geometryType: string;
        data: string;
        createdAt: Date;
        deletedAt: Date;
        yardId: string | number;
}

export class TargetData implements Target {
    id: string;
    name: string;
    pose: Pose;
    display: any;
    type: TargetType;
    anchor: TargetAnchor;
    width: number;
    length: number;
    yardId: number;

    constructor(yardId,name, x, y, orientations: number[], anchor= TargetAnchor.Front){
        this.yardId= yardId;
        this.name= name;
        this.pose= {x, y , orientations};
        this.anchor = anchor;
        this.length = 6000;
        this.width = 3000;
        this.type = TargetType.Parking;
    }

} 

export enum TargetType {
    Free = 'free',
    Entrance = 'entrance',
    Exit = 'exit',
    Gate = 'gate',
    Parking = 'parking',
}

export enum TargetAnchor {
    Front = 'front',
    Rear = 'back',
    Middle = 'middle',
}


export interface HasId {  id: string | number; }

export interface Pose {x: number, y: number, orientations: number[], orientation?: number}

export function canMissionBeCancelled (mission: Mission) {
    const status = mission.status
    return status === MissionStatus.Unplanned
        || status === MissionStatus.Planning
        || status === MissionStatus.Driving
        || status === MissionStatus.Created
        || status === MissionStatus.Calculating;
}
