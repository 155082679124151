 /**
 * Lower level leaflet interface.
 */
import * as _L from 'leaflet'
import { AddDistortedImagePlugin} from '../plugins/LeafletDistortableImage/leaflet.distortableimage'
import '../plugins/LeafletDistortableImage/leaflet.distortableimage.css'


export function _t (s: string, ...rest: any[]) {
    return s
}

// tslint:disable-next-line
require('leaflet.gridlayer.googlemutant')
require('leaflet.polylinemeasure')
require('leaflet-toolbar');
require('leaflet/dist/leaflet.css')


AddDistortedImagePlugin(_L);


import "leaflet-imageoverlay-rotated";
import 'leaflet.pm'
import 'leaflet.pm/dist/leaflet.pm.css' // includes the marker-icon class
import 'leaflet.polylinemeasure'


import { LatLonTuple, Point, PointTuple, PolygonPoints } from '../types'

import { CanvasLayerDrawFnParameters, LeafletCanvasOverlay } from './leafletCanvasOverlay'
import { LeafletMarkerOverlay } from './leafletMarkerOverlay'
import { LeafletPolygonOverlay } from './leafletPolygonOverlay'

import * as mapPolygons from './mapPolygons'
import { helyosService } from '../../../services/app-service'

// Do not use window.L as that is reserved for the old leaflet-0.7 that drives
// the backbone/coffeescript views.
// The @types/leaflet definitions create a global L binding though.
// tslint:disable-next-line
(_L as any).noConflict()

export type LayerName
    = 'google-satellite'
    | 'google-hybrid'
    | 'google-roadmap'
    | 'png'
/*    | 'bing-satellite'
    | 'bing-hybrid'*/

export interface LayerDescription {
    label: string
    name: LayerName
}

export const layers: LayerDescription[] = [
    {
        label: _t('leaflet_layer_google_maps_satellite'),
        name: 'google-satellite',
    },
    {
        label: _t('leaflet_layer_google_maps_hybrid'),
        name: 'google-hybrid',
    },
    {
        label: _t('leaflet_layer_google_maps_roadmap', {_: 'Google Strassenkarte'}),
        name: 'google-roadmap',
    },
    {
        label: _t('Map overlay', {_: 'User image'}),
        name: 'png',
    }
/*    {
        label: _t('leaflet_layer_bing_satellite'),
        name: 'bing-satellite',
    },
    {
        label: _t('leaflet_layer_bing_hybrid'),
        name: 'bing-hybrid',
    },*/
]

export { CanvasLayerDrawFnParameters, LeafletCanvasOverlay } from './leafletCanvasOverlay'
export { LeafletPolygonOverlay } from './leafletPolygonOverlay'
export { LeafletMarkerOverlay, Marker } from './leafletMarkerOverlay'

interface LeafletMapOptions {
    element: HTMLElement,
}

/**
 * Thin wrapper around leaflet
 */
export class LeafletMap {

    // leaflet options
    static DEFAULT_MAP_OPTIONS = {
        // prevents zooming in and out again at max zoom
        bounceAtZoomLimits: false,

        // We use double clicks already for closing the polygon and single
        // clicks for selecting and circling through stacked polygons may
        // sometimes be interpreted as double-clicks which was terribly
        // annoying to me during development.
        // Also - zooming is already possible with the scrollwheel and the +/-
        // zoom buttons on the map - no need to have a third way of zooming.
        doubleClickZoom: false,

        maxZoom: 23,
        zoomAnimation: true,

        // we use a custom zoom control instead
        zoomControl: false,
    }

    // factory function
    static createMap (options: LeafletMapOptions, overlayImg = null) {
        return new LeafletMap(options, overlayImg);
    }

    public reloadOverlay (overlayImg = null) {
        this.overlayImg = overlayImg;
    }

    // manually track leaflet tile layer loading state
    isLoaded: boolean = false

    // the leaflet map
    public map: _L.Map
    // the reference to the leaflet.polylinemeasure when enabled
    private measureControl: _L.Control.PolylineMeasure

    // required to fix position calculation of canvas corrds during movements
    private lastStaticMapPanePos: any

    // true when the map is being moved or panned automatically
    private isMoving: boolean

    // reference to the current tile layer if any has been selected
    private tileLayer?: _L.Layer
    private tileLayerName?: LayerName
    

    // overlay image
    private overlayImg
    public callback : () => {};

    // reference to the current pm:create callback, so we can remove it when
    // cancel adding polygons
    private _onFinishDrawPolygon?: (createEvent: any) => void

    private constructor (options: LeafletMapOptions, overlayImg = null) {
        this.overlayImg = overlayImg
        if(overlayImg) {
            this.callback = overlayImg.editCallBack;
        }
        this.map = new _L.Map(options.element, LeafletMap.DEFAULT_MAP_OPTIONS)

        // track move state to be able to correctly create canvas pixel coords
        // from latlng tuples *during map moving*
        this.map.on('movestart', () => {
            this.lastStaticMapPanePos = (this.map as any)._getMapPanePos()
            this.isMoving = true
        })
        this.map.on('moveend', () => {
            this.lastStaticMapPanePos = undefined
            this.isMoving = false
        });

        // remove link to leaflet in (c) footer of map
        (this.map as any).attributionControl.setPrefix('')
    }

    /**
     * Select one of the base supported tile layers (google, bing).
     *
     * Do nothing when the layer is already shown.
     */
    selectMapLayer (name: LayerName, reload=false) {
        if (this.tileLayerName === name && !reload) {
            return
        }

        if (this.tileLayer) {
            this.tileLayer.removeFrom(this.map)
        }

        // const bingApiKey = 'AhbuTxZKfCRonB6lrhbGB5QXzFSXvljunZjxqanisysvHylVy2weCwQLF-vg3TQH'
        // const bingOptions: any = {maxNativeZoom: 18, maxZoom: 25}

        // Customized defined map using png
        let layer: _L.Layer;
        let layer2: _L.Layer;


        // let marker1;
        // function repositionImage(overlay) {
		// 	overlay.reposition(marker1.getLatLng(), marker2.getLatLng(), marker3.getLatLng());
		// };
		
	

        function assertNever (x: never): never {
            throw new Error('Unknown Layer Name: ' + x)
        }

        switch (name) {
        case 'google-satellite':
            layer = (_L as any).gridLayer.googleMutant({type: 'satellite'})
            break

        case 'google-hybrid':
            layer = (_L as any).gridLayer.googleMutant({type: 'hybrid'})
            break

        case 'google-roadmap':
            layer = (_L as any).gridLayer.googleMutant({type: 'roadmap'})
            break

        case 'none':
            let a =  _L.tileLayer('');
            // a['options']={showOnSelector: false};
            layer = (_L as any).control.layers( {Empty:a},null,{hideSingleBase: true});

            break    

        case 'png':
            let point1, point2, point3, point4;
            let allHasValue: boolean = false;
            
            if (this.overlayImg && this.overlayImg.corners ){ 
                const corners = this.overlayImg.corners;
                allHasValue = true;
                for ( let point in corners) {
                    if (['p1','p2','p3','p4'].indexOf(point)==-1) continue;
                    allHasValue = allHasValue && corners[point].lon && corners[point].lat;
                }
            }


            if (allHasValue){ 
                const corners = this.overlayImg.corners;
                point1 = new _L.latLng(parseFloat(corners.p1.lat), parseFloat(corners.p1.lon));
                point2 = new _L.latLng(parseFloat(corners.p2.lat), parseFloat(corners.p2.lon));
                point3 = new _L.latLng(parseFloat(corners.p3.lat), parseFloat(corners.p3.lon));
                point4 = new _L.latLng(parseFloat(corners.p4.lat), parseFloat(corners.p4.lon));
            } else { // IVV Yard
                point1 = new _L.latLng(51.027155,13.736878);
                point2 = new _L.latLng(51.027187,13.739818);
                point3 = new _L.latLng(51.026247,13.736902);
                point4 = new _L.latLng(51.026278,13.739843);
            }

            console.log("this.overlayImg", this.overlayImg)

            if (this.overlayImg &&  this.overlayImg.picture) {
                layer = new (_L as any).DistortableImageOverlay(
                                            // 'http://localhost:8090/src/assets/images/ivimap.png',
                                            this.overlayImg.picture,
                                            {   corners: [point1, point2, point3, point4],  
                                                fullResolutionSrc: this.overlayImg.picture,
                                            }
                                        );      

                layer2 = (_L as any).gridLayer.googleMutant({type: 'hybrid'})
            } else {
                name = 'google-hybrid'
                layer = (_L as any).gridLayer.googleMutant({type: 'hybrid'})
            }
       

            break    

        /*case 'bing-satellite':
            layer = bingLayer(bingApiKey, bingOptions)
            break

        case 'bing-hybrid':
            // other bing types are: AerialWithLabels | Birdseye | BirdseyeWithLabels | Road
            layer = bingLayer(bingApiKey, {...bingOptions, type: 'AerialWithLabels'})
            break*/

        default:
            // typescript discriminated union (LayerName) exhaustiveness check
            return assertNever(name)
        }

        if (layer2) {layer2.addTo(this.map);
        
        
            let hasValue;
            
            var myIcon = _L.icon({
                iconUrl: 'src/assets/images/mark-icon.png',
                iconSize: [38, 38],
                iconAnchor: [16, 38],
                popupAnchor: [0, 0],
                // shadowUrl:'src/assets/images/mark-icon.png',
                // shadowSize: [68, 95],
                // shadowAnchor: [22, 94]
            });


            if (this.overlayImg && this.overlayImg.corners ){ 
                const markers = this.overlayImg.corners;
                hasValue = true;
                for ( let point in markers) {
                    if (['m1','m2','m3','m4'].indexOf(point)==-1) continue;
                    hasValue =  markers[point].lon && markers[point].lat;
                    if (markers[point].obj) {
                        this.map.removeLayer(markers[point].obj)
                    }
                    if (hasValue) {
                        markers[point].obj = _L.marker([parseFloat(markers[point].lat), parseFloat(markers[point].lon)], {icon: myIcon, title: point});
                        markers[point].obj.addTo(this.map);
                    }
                }
            }

        
        
        };
        layer.addTo(this.map)


        if (name =='png') {




            const self = this;
            console.log("layer",layer)            
            layer.editing._overlay.editing._toggleOutline(true);

            _L.DomEvent.on(layer._image, 'load',layer.editing.enable, layer.editing); 

           
            _L.DomEvent.on(layer._image, 'load', function() {
                        layer.editing._overlay.editing._toggleLock();

                        var img = this;
                        img.on('edit', function() { console.log('edited'); 
                                                    console.log(img);
                                                    self.overlayImg.editCallBack(img);
                                                 },
                                img);

                        _L.DomEvent.on(img._image, 'mouseup', function() {console.log('mouseup'); console.log(img)}, img);

                        _L.DomEvent.on(img._image, 'touchend', function() {console.log('touchend');  self.overlayImg.editCallBack(img);}, img);
                    }, layer);
        };



        this.tileLayer = layer
        this.tileLayerName = name
    }

    /**
     * Set the maps tile layer (google, bing, ...) opacity
     */
    setBaseLayerOpacity (opacity: number) {
        const tilePane = this.map.getPane('tilePane')

        if (!tilePane) {
            return
        }

        tilePane.style.opacity = `${opacity}`
    }

    // overlayed canvas

    /**
     * Add a canvas using the given drawing function.
     *
     * Use the latLngToCanvasPoint method to get the correct mapping into
     * canvas coords also during map moves.
     *
     * Always use the provided canvas in the drawFn as it might change when
     * toggling the canvas visibility.
     */
    addCanvasLayer (zIndex: number, drawFn: (params: CanvasLayerDrawFnParameters) => void) {
        return LeafletCanvasOverlay.createCanvasOverlay(this.map, zIndex, (layer: any, params: any) => drawFn(params))
    }

    /**
     * Add a layer rendering polygons using the leaflet SVG renderer at the given zIndex.
     *
     * Returns a handle to modify the layer.
     */
    addPolygonLayer (zIndex: number) {
        return LeafletPolygonOverlay.createPolygonOverlay(this.map, zIndex)
    }

    /**
     * Overlay to control leaflet map markers
     */
    addMarkerLayer (zIndex: number) {
        return LeafletMarkerOverlay.createMarkerOverlay(this.map, zIndex)
    }

    /**
     * Set the maps center position
     */
    setView (location: LatLonTuple, zoomLevel: number) {
        // track the is loaded state as leaflet does not provide any such
        // public method and with isLoaded we can provide an easy
        // initialization in the LeafletMapComponent via the homeCoordinates
        // prop
        this.isLoaded = true

        this.map.setView(location, zoomLevel)
    }

    /**
     * Zoom in
     */
    zoomIn () {
        this.map.zoomIn()
    }

    /**
     * Zoom out
     */
    zoomOut () {
        this.map.zoomOut()
    }

    // measuring

    measureStart () {
        if (!this.measureControl) {
            const lineColor = 'rgba(255,155,155,0.8)'
            const markerStyle = {
                color: '#666',
                fillColor: '#fff',
                fillOpacity: 1,
                radius: 5,
                weight: 1,
            }

            // styles
            const options = {
                currentCircle: markerStyle,
                endCircle: markerStyle,
                fixedLine: {color: lineColor, weight: 2},
                intermedCircle: markerStyle,
                measureControlClasses: ['u-display-none'], // hide the leaflet control as we're using our own
                startCircle: markerStyle,
                tempLine: {color: lineColor, weight: 2},
                unit: 'metres',
            }

            this.measureControl = _L.control.polylineMeasure(options)
            this.measureControl.addTo(this.map)
        }

        // this.measureControl._enableMeasure()
    }

    measureEnd () {
        if (!this.measureControl) {
            return
        }

        // this.measureControl._disableMeasure()
    }

    // utilities
    latLngToLayerPoint (latLonPoint: LatLonTuple): Point {
        // actually we could just use the rounded values from:
        //   `return this.map.latLngToContainerPoint(latLonPoint)`
        // but doing this manually gives us sub-pixel precision
        const point = this.map.project(latLonPoint, this.map.getZoom())
        const origin = this.map.getPixelOrigin()
        const result = this.map.layerPointToContainerPoint(point.subtract(origin))

        return result
    }

    /**
     * Compute the canvas pixel position of the given lat long coordiante.
     *
     * Works also when the canvas is translated because of the map beeing
     * panned around by the user.
     */
    // TODO-ERIK 09.10.2017: This logic belongs into the _L.OverlayCanvas, but
    //                       maybe we should to fork (and refactor it for
    //                       ES/Typescript) it first before adding too many
    //                       inline patches.

    latLngToCanvasPoint (latLonPoint: LatLonTuple): Point {
        const point = this.map.project(latLonPoint, this.map.getZoom())
        const origin = this.map.getPixelOrigin()
        let mapPanePos: _L.Point

        if (this.isMoving) {
            // when moving the map, the pixel-position of latLonPoint in the
            // canvas does not change because we move the canvas along with the map
            mapPanePos = this.lastStaticMapPanePos || (this.map as any)._getMapPanePos()
        } else {
            mapPanePos = (this.map as any)._getMapPanePos()
        }

        const result = point.subtract(origin).add(mapPanePos)

        return result
    }

    layerPointTolatLng (pixelPoint: PointTuple) {
        return this.map.layerPointToLatLng(pixelPoint)
    }

    /**
     * Return the current resolution of the map in millimeter per pixels.
     */
    getMmPerPx (ref=null): number {
        
        let centerLatLng = ref? ref:this.map.getCenter()
        if (Array.isArray(centerLatLng)){
            centerLatLng = new _L.latLng(centerLatLng[0], centerLatLng[1]);
        }
        
        const centerPx = this.map.latLngToContainerPoint(centerLatLng)
        const pointX: [number, number] = [centerPx.x + 1000, centerPx.y+1000] // add 1000 pixel to x (metres per 1000px == mm per px)
        const latLngX = this.map.containerPointToLatLng(pointX)

        return centerLatLng.distanceTo(latLngX)/Math.sqrt(2); // calculate latitude distance between center and point on the right
    }

    getMmPerPxCoord(ref=null) {
        
        let centerLatLng = ref? ref:this.map.getCenter()
        if (Array.isArray(centerLatLng)){
            centerLatLng = new _L.latLng(centerLatLng[0], centerLatLng[1]);
        }
        
        const centerPx = this.map.latLngToContainerPoint(centerLatLng);
        const pointX: [number, number] = [centerPx.x + 1000, centerPx.y] // add 1000 pixel to x (metres per 1000px == mm per px)
        const pointY: [number, number] = [centerPx.x, centerPx.y + 1000] // add 1000 pixel to x (metres per 1000px == mm per px)

        const latLngX = this.map.containerPointToLatLng(pointX);
        const latLngY = this.map.containerPointToLatLng(pointY);

        // helyOS SDK distance calculator
        const mmX = helyosService.convertLatLngToMM(centerLatLng.lat, centerLatLng.lng,[[latLngX.lat, latLngX.lng]] )[0];
        const mmY = helyosService.convertLatLngToMM(centerLatLng.lat, centerLatLng.lng,[[latLngY.lat, latLngY.lng]])[0];
        const distanceX = Math.sqrt(Math.pow(mmX[0],2) +  Math.pow(mmX[1],2))/1000;
        const distanceY = Math.sqrt(Math.pow(mmY[0],2) +  Math.pow(mmY[1],2))/1000;
        return { x: distanceX, y: distanceY, centerLatLng}

        // leaflet distance calculator
        // return { x: centerLatLng.distanceTo(latLngX), y: centerLatLng.distanceTo(latLngY), centerLatLng}; 

    }

    /**
     * Call the _L.Map.remove method to destroy the map and clean everything up.
     */
    remove () {
        this.map.remove()
    }

    // interactively add polygons using leaflet.pm
    // polygon editing happens in leafletPolygonOverlay

    /**
     * Draw a new polygon on this layer
     */
    startDrawPolygon (onComplete: (p: PolygonPoints) => void) {
        this.abortDrawPolygon()

        this._onFinishDrawPolygon = (e: any) => {
            // clean up this listener as we may have more than one layer that
            // draws polygons
            this.map.off('pm:create', this._onFinishDrawPolygon)
            this._onFinishDrawPolygon = undefined

            // Remove the polygon to do not keep any polygon state here.
            // To conform to the react-way of dealing with
            // state we call a callback and eventually LeafletMap will set
            // this polygon to be drawn.
            // Its a bit inefficient but *way* more maintainable.
            e.layer.removeFrom(this.map)

            const points = mapPolygons.getPolygonPointsFromLayer(e.layer)
            const lastTwo = points.slice(points.length - 2)
            const lastTwoAreIdentical = lastTwo[0][0] === lastTwo[1][0] && lastTwo[0][1] === lastTwo[1][1]

            if (lastTwoAreIdentical) {
                // that happens when dblclking to close a polygon - leaflet.pm inserts two points for the dblclk
                points.pop()
            }

            onComplete(points)
        }

        this.map.on('pm:create', this._onFinishDrawPolygon)

        const anymap: any = this.map // leaflet.pm typings are of low quality

        // Optimization Opportunity:
        // use sth like a 'null' renderer to avoid uneccesary dom ops
        // pathOptions: { renderer: <null>, }
        anymap.pm.enableDraw('Poly', mapPolygons.LEAFLET_PM_DRAW_OPTIONS)
    }

    abortDrawPolygon () {
        if (!this._onFinishDrawPolygon) {
            return
        }

        this.map.off('pm:create', this._onFinishDrawPolygon)
        this._onFinishDrawPolygon = undefined

        const anymap: any = this.map

        anymap.pm.disableDraw('Poly')
    }

    /**
     * Set a generic map on click listener.
     */
    setOnClick (callback: (location: LatLonTuple) => void) {
        this.map.on('click', (ev: any) => {
            const {lat, lng} = ev.latlng

            callback([lat, lng])
        })
    }
}
