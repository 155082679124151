import * as React from 'react'
import { Obstacle } from '../../../models/app.models';



export interface Props {
        shapeProps?: Partial<Obstacle>,
        change?: (ev: any) => void
        reloadMap?: (ev: any) => void
        style?: React.CSSProperties
}

export class ShapePropForm extends React.PureComponent<Props, {}> {
        props: Props;
        shape: any = null
        state: any;

        
        constructor(props) {
                super(props);
                const shape = {
                        bottom: 0,
                        top: 9999,
                        type: 'obstacle',
                        data: '{}'
                };

                this.shape = shape;
                this.state = {
                                shape: shape,
                }
        }

        componentWillReceiveProps(nextProps) {
                const shape = {
                        bottom: 0,
                        top: 9999,
                        type: 'obstacle',
                        data: '{}'
                };

                if (nextProps.shapeProps) {
                        this.shape = {...shape, ...nextProps.shapeProps};
                } else {
                        this.shape = {...shape };
                }

                this.setState({shape: this.shape});

        }



        render() {

                return (
                        <div id="OverlayMapInputs">

                                <div style={{ minWidth: 30, marginTop:10 }}> Elevation: top and botttom </div>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <input name="top" type="number" placeholder="top" disabled={!this.props.shapeProps}
                                                value={this.shape.top} onChange={this.props.change} />

                                        <input name="bottom" type="number" placeholder="bottom"  disabled={!this.props.shapeProps}
                                                value={this.shape.bottom} onChange={this.props.change} />
                                </div>


                                <div style={{ minWidth: 30 }}> Type:  </div>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <input name="type" type="text" placeholder="obstacle"  disabled={!this.props.shapeProps}
                                                value={this.shape.type || ''} onChange={this.props.change} />

                                </div>

                                <div style={{ minWidth: 30 }}> Action params:  </div>
                                  <textarea  rows={3} value={this.shape.data || "{}" } name="data"  placeholder="{}"  disabled={!this.props.shapeProps}
                                                onChange={this.props.change} >
                                 </textarea>         

                        </div>

                )
        }
}


