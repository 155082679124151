import { getEnumIndex, PresenceStatus, Obstacle, Presence, Target, Mission, LayerType, MissionStatus, HasId, Yard } from "./app.models";
import { IndexedCollection, EntityTypeChange } from "../state/updatetableCollection";
import { H_Yard, H_Target, H_WorkProcess, H_Tools, H_Shape } from "helyosjs-sdk";
import { H_MapObject, MoveToTargetDescriptor } from "helyosjs-sdk/dist/helyos.models";


type dispatchTypes= 'created' | 'updated' | 'deleted'


export function dataToDispatchMsg(data, action:  dispatchTypes) {
    const timestamp =  new Date();
    const dispatchMessage = { timestamp };
    dispatchMessage[action] = data;
}


function getPolygonCoord (target: Target, width=200) {
    const rotate = (centerX, centerY, x, y, radians) => {
            const cos = Math.cos(radians);
            const sin = Math.sin(radians);
            const nx = (cos * (x - centerX)) + (sin * (y - centerY)) + centerX;
            const ny = (cos * (y - centerY)) - (sin * (x - centerX)) + centerY;
        return [nx, ny];
    }
    const safeMargin = target.length*0.1; // not used.
    const yPoint = -(target.pose.y);
    const rectangle = [ 
        [(target.pose.x - target.width / 2),  yPoint],
        [(target.pose.x + target.width / 2),  yPoint],
        [(target.pose.x + target.width / 2), (yPoint - target.length)],
        [(target.pose.x - target.width / 2), (yPoint - target.length)]
    ];
    
    const rotated_rectangle = rectangle.map( r => rotate(target.pose.x, -target.pose.y,
                                                         r[0], r[1], -target.pose.orientations[0]/1000 + Math.PI/2 ));                                         
    return rotated_rectangle
}


export class AppToHelyosModelConvertor {

  
    obstacleToShapeFormat(obstacle) {
        const dataNow = new Date().toUTCString();
        obstacle.cost = 0;
        obstacle.bottom = obstacle.bottom ?  obstacle.bottom : 0;
        obstacle.top = obstacle.top ?  obstacle.top : 100000;
        obstacle.removable = false;
        const shape : Partial<H_Shape> = {
            id: obstacle.id? obstacle.id : null,
            // isPermanent: obstacle.type == LayerType.base,
            type: obstacle.type?  obstacle.type : 'obstacle' ,
            isObstacle: obstacle.isObstacle,
            metadata: obstacle.data,
            yardId :obstacle.yardId
        };

        if  (obstacle.geometry) { 
            shape.data = {geometry_type: obstacle.geometryType, ...obstacle.geometry};
        } else {
            shape.data ={ top: obstacle.top,
                            bottom: obstacle.bottom,
                            points: obstacle.points,
                            geometry_type: obstacle.geometryType,
            }
        }

        return shape;
    }




    targetToHelyosFormat(target: Target) : Partial<H_Target>{
        const hTarget = {
                anchor : target.anchor,
                targetName : target.name,
                targetType : target.type,
                x : Math.round(target.pose.x),
                y : Math.round(target.pose.y),
                orientation:  Math.round(target.pose.orientations[0]),
                yardId : target.yardId,
        }

        return hTarget;
    }

    targetToMapObject(target: Target) : Partial<H_Target>{
        const hMapObject: Partial<H_MapObject> = {
                metadata: {anchor : target.anchor,
                          targetName : target.name,
                          targetType : target.type,
                          x : target.pose.x,
                          y : -target.pose.y,
                          orientation:  Math.round(target.pose.orientations[0]),
                },
                yardId : target.yardId,
                dataFormat: "trucktrix-map",
                name: target.name,
                type: "target",
                data: { 'top':0, 'bottom':0, 'points':getPolygonCoord(target), 'geometry_type': 'polygon'}
        }

        return hMapObject;
    }

    
    yardToHelyosFormat(yard: Yard) : Partial<H_Yard>{
        const hTarget: Partial<H_Yard> = {
                id : yard.id,
        }
        if (yard.name) hTarget['name'] = yard.name;
        if (yard.yardType) hTarget['yardType'] = yard.yardType;
        if (yard.origin)   hTarget['mapData'] =  JSON.stringify({origin: yard.origin});
        if (yard.picture)  hTarget['picture'] =  yard.picture;
        if (yard.picturePos)  hTarget['picturePos'] =  JSON.stringify(yard.picturePos);

        return hTarget;
    }



    missionToWorkProcessFormat(mission: Mission) : Partial<H_WorkProcess> {
        const jsonDescription: any = {
            tool_id: parseInt(mission.presence_id),
        };

        if (mission.target_id) {jsonDescription['target_id'] = parseInt(mission.target_id)};
        if (mission.target_type) {jsonDescription['target_type'] = mission.target_type};
        if (mission.target_name) {jsonDescription['target_name'] = mission.target_name};
        if (mission.custom_payload) {jsonDescription['custom_payload'] = mission.custom_payload};

        if (mission.x) {jsonDescription['x'] = mission.x};
        if (mission.y) {jsonDescription['y'] = mission.y};
        if (mission.orientation) {jsonDescription['orientation'] = mission.orientation};
        if (mission.orientations) {jsonDescription['orientations'] = mission.orientations};
        if (mission.schedStartAt) {jsonDescription['schedStartAt'] = mission.schedStartAt};
        if (mission.schedEndAt) {jsonDescription['schedEndAt'] = mission.schedEndAt};
        
        
        if (!mission.workType) {mission.workType = 'driving'};


        jsonDescription['anchor'] = mission.anchor;

        const workProcess = {
            toolIds: [parseInt(mission.presence_id)],
            yardId: parseInt(mission.yardId as string),
            status: mission.status,
            description: mission.description,
            data: jsonDescription,
            workProcessTypeName: mission.workType,
            schedStartAt: mission.schedStartAt,
            id: mission.id,
        }

        return workProcess as Partial<H_WorkProcess>;
    }

    presenceToToolFormat(presence:Partial<Presence>) {
        const tool: Partial<H_Tools> = {id: presence.id.toString()};
        if (presence.picture) tool['picture'] = presence.picture;
        if (presence.specification) {
                try {
                    tool['geometry'] = JSON.parse(presence.specification as any);
                } catch (error) {
                    tool['geometry'] = presence.specification;
                }
        }
        if (presence.main_licence_plate) tool['name'] = presence.main_licence_plate;
        if (presence.name) tool['name'] = presence.name;
        if (presence.streamUrl) tool['streamUrl'] = presence.streamUrl;

        return tool;
    }

} // end - FormatToOldDB


export const appToHelyosModel = new AppToHelyosModelConvertor();
