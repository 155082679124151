import * as React from 'react'

export interface Props {
    className?: string
    disabled?: boolean
    onClick?: () => void
    style?: React.CSSProperties
    title?: string
}

export class Button extends React.PureComponent<Props, {}> {
    render () {
        return (
            <button
                type="button"
                className={`button ${this.props.className}`}
                onClick={this.props.onClick}
                style={this.props.style}
                disabled={this.props.disabled}
                title={this.props.title}>
                {this.props.children}
            </button>
        )
    }
}
