import { Map } from 'leaflet'
import * as React from 'react'
import { ModalDialog } from '../../base/components/ModalDialog'
import { Mission, Obstacle, Pose, Presence, Target, Yard, TargetData, MissionData, TargetAnchor } from '../../../models/app.models'
import { createMission, createMultiDriveWorkProcess, createTarget, getWorkProcessDetails, helyosService, saveWorkProcessDetails } from '../../../services/app-service'
import { LeafletMapComponent } from '../../maps'
import { LeafletMapComponentCanvasOverlay, LeafletMapComponentOverlayIndex, LeafletMapComponentPolygonOverlay, LeafletMapProxy } from '../../maps/components/LeafletMapComponent'
import { CanvasLayerDrawFnParameters, LeafletCanvasOverlay } from '../modules/leafletCanvasOverlay'
import { renderMission } from '../renderables/mission'
import { renderTarget } from '../renderables/target'
import { Vehicle, renderVehicle, VehicleInstance, fillColors } from '../renderables/vehicle'
import { LatLonTuple, Polygon, PolygonPoints } from '../types'
import DatePicker from 'react-datepicker'; import 'react-datepicker/dist/react-datepicker-cssmodules.css';


let moment = require('moment');
import './Map.scss'
import './Obstacles.scss'
import * as lodash from 'lodash';
import { ApplicationState, UiControl } from '../../../state/models';
import { Store } from '../../../state/store';
import { MissionTypeDropdown } from '../../command/components/MissionTypeDropdown'

const obstacleClassNames = {
    added: 'Obstacleadded',
    dynamic: 'Obstacledynamic',
    edited: 'Obstacleedited',
    fixed: 'Obstaclefixed',
    drivable: 'Obstacledrivable',
    target: 'Obstacletarget',
    crop_field: 'Obstaclecrop',
}


const nObstacleClassNames = {
    added: 'nObstacleadded',
    dynamic: 'nObstacledynamic',
    edited: 'nObstacleedited',
    fixed: 'nObstaclefixed',
}

// Obstacles #####################################
export interface ObstacleLayerProps {
    obstacles: Obstacle[],
    nobstacles: Obstacle[],
}

export interface PathLayerProps {
    path: any[],
}

export interface ObstacleChangeEvent {
    updateInformation: {[index: string]: ShapeData | null},
    added: ShapeData[]
}

export interface ShapeData extends Polygon{
    isObstacle: boolean;
    type: string;
}

interface ObstacleEditProps {
    editableObstacles: Obstacle[],
    editableObstacleIds: string[],
    editing: boolean,
    editingObstacleShape: boolean,
    shapeProperties: { top: number, bottom: number, type: string },
    changesDeployed: boolean,
    updateObstacles: (ev: ObstacleChangeEvent) => void
    obstacleClicked: any;

}

interface ObstacleEditState extends ObstacleChangeEvent {
    shouldUpdate: boolean,
}

// Vehicles ######################################
export interface VehicleLayerProps {
    vehicles: Vehicle[],
    vehicleClicked?: (presenceId: string) => void
    selectedPresence: Presence,
}

// Targets #######################################
export interface TargetLayerProps {
    targets: Target[]
}

// Missions ######################################
export interface MissionLayerProps {
    missions: Mission[]
}

// ###############################################

interface ClickIdMapping {
    id: string,
    tag: string,
    colorInt: number,
}

export interface Props {
    yard?: Yard,
    missionLayer?: MissionLayerProps,
    obstacleLayer?: ObstacleLayerProps,
    pathLayer?: any,
    guidelineLayer?: PathLayerProps,
    obstacleEditLayer?: ObstacleEditProps,
    targetLayer?: TargetLayerProps,
    vehicleLayer?: VehicleLayerProps
    drivableLayer?: ObstacleLayerProps,
    newTargetLayer?: ObstacleLayerProps,
    uiControl?:UiControl,
    redraw?: boolean

}

enum Layer {
    vehicle,
    obstacle,
    target,
    mission,
    drivable,
    newTarget,
    path,
    guideline
}

interface State {
    leafletMapComp: LeafletMapComponent | null,
    selectedTarget: Target | null,
    anchor: TargetAnchor | null,
    selectedMissionType: H_WorkProcessType | null,
    clickIdMappings: ClickIdMapping[],
    visibleLayers: Layer[],
    selectedMapLocation,
    redraw,
    ghostVehicle,
    orientationsGoal,
    orientationTrailerGoal,
    workTypeName,
    wpTypeSettings,
    wpTypeId,
    targetName,
    sched_start,
    saveTarget,
    mapStretched,
    lastUpdated,
    missionRequests
}

interface VehicleTransitionState {
    fromPoses?: {[id: string]: Pose},
    toPoses?: {[id: string]: Pose},
    transitionStartTime?: number,
    expectedDuration?: number,
}

export class LayeredMapComponent extends React.PureComponent<Props, State & ObstacleEditState & VehicleTransitionState> {

    constructor (props: Props) {
        super(props)
        this.state = {
            added: [],
            clickIdMappings: [],
            leafletMapComp: null,
            selectedTarget: null,
            anchor: null,
            selectedMapLocation: null,
            redraw: false,
            shouldUpdate: true,
            updateInformation: {},
            visibleLayers: [Layer.vehicle, Layer.target, Layer.mission, Layer.path],
            ghostVehicle: null,
            orientationsGoal: 0,
            orientationTrailerGoal: 0,
            workTypeName: 'driving',
            wpTypeSettings:'{}',
            wpTypeId: null,
            targetName: "new_parking",
            sched_start: new Date(),
            saveTarget: false,
            mapStretched:false,
            lastUpdated : new Date(),
            missionRequests: null,


        }
    }

    mmToLatLonTuple (map: Map, xy: {x: number, y: number}): number[] {
        const origin = this.props.yard.origin
        return helyosService.convertMMtoLatLng(origin.lat, origin.lon,[[xy.x, xy.y]])[0];
        // const mmPerLat = map.distance([origin.lat, origin.lon], [origin.lat + 0.1, origin.lon]) * 10000
        // const mmPerLon = map.distance([origin.lat, origin.lon], [origin.lat, origin.lon + 0.1]) * 10000
        // const resultLat = origin.lat + xy.y / mmPerLat;
        // const resultLon = origin.lon + xy.x / mmPerLon
        // return [resultLat, resultLon]
    }

    latLonTupleToMm (map: Map, latlon: LatLonTuple): number[] {
        const origin = this.props.yard.origin;
        return helyosService.convertLatLngToMM(origin.lat, origin.lon, [latlon])[0];
        // const mmPerLat = map.distance([origin.lat, origin.lon], [origin.lat + 0.1, origin.lon]) * 10000
        // const mmPerLon = map.distance([origin.lat, origin.lon], [origin.lat, origin.lon + 0.1]) * 10000
        // const resultX = (latlon[0] - origin.lat) * mmPerLat;
        // const resultY = (latlon[1] - origin.lon) * mmPerLon;
        // return [resultY, resultX];
    }

    prepareCanvas (map: LeafletMapProxy, params: CanvasLayerDrawFnParameters) {
        const canvas = params.canvas
        const ctx = canvas.getContext('2d')
        if (!ctx) {
            return {instance: null}
        }
        const mmPerPxFar = map.getMmPerPxCoord();
        const resultOrigin = map.latLngToCanvasPoint([this.props.yard.origin.lat, this.props.yard.origin.lon]);
        const currentOrigin = map.latLngToCanvasPoint([mmPerPxFar.centerLatLng.lat,  mmPerPxFar.centerLatLng.lng]);

        ctx.setTransform(1, 0, 0, 1, 0, 0)
        ctx.clearRect(-100000, -100000, 200000, 200000)
        ctx.setTransform(
            1 / mmPerPxFar.x,
            0,
            0,
            1 / mmPerPxFar.y,
            currentOrigin.x,
            currentOrigin.y,
        )
        const mapComp = this.state.leafletMapComp;
        let originOffsetMm = [0, 0];
        const origin = [ mmPerPxFar.centerLatLng.lat, mmPerPxFar.centerLatLng.lng]
        if (mapComp) originOffsetMm = this.latLonTupleToMm(mapComp.leafletMap.map, origin);

        return { instance: ctx, originOffsetMm};
    }

    getColorFromMapping (id: string, tag: string) {
        const colorNumber = this.state.clickIdMappings.find(mapping => mapping.tag === tag && mapping.id === id)
        if (!colorNumber) {
            // // tslint:disable-next-line:no-console
            // console.warn(tag + ' id "' + id + '" not found in mapping')
            return
        }
        const colorPadded = '00000' + colorNumber.colorInt.toString(16) // make the hex and pad it with zeros
        const colorString = '#' + colorPadded.substring(colorPadded.length - 6) // cut off the excess padding and make it a color
        return colorString
    }

    getUnusedMappingColor (currentMappings: ClickIdMapping[]) {
        for (let i = currentMappings.length; true; i++) {
            const colorNumber = i
            if (!currentMappings.find(mapping => mapping.colorInt === colorNumber)) {
                return colorNumber
            }
        }
    }

    // Obstacles #####################################
    getEditedObstaclePolygons (obstaclesFromDB: Obstacle[], classNames: any, thiss: LayeredMapComponent): Polygon[] {
        if (!obstaclesFromDB) return [] ;
        const result = obstaclesFromDB.map(obstacle => {
                        if (obstacle.type && obstacle.type !== 'drivable' && obstacle.type !== 'obstacle'  ) {
                            return ({shapeId: obstacle.id, points: obstacle.points, className: classNames[obstacle.type] } as Polygon)
                        }
                        
                        if (obstacle.isObstacle) { return ({shapeId: obstacle.id, points: obstacle.points, className: classNames['dynamic'] } as Polygon)}
                        return ({shapeId: obstacle.id, points: obstacle.points, className:classNames['drivable'] } as Polygon)
                        })


        if (thiss.props.obstacleEditLayer) {
            // add undeployed edited obstacles
            for (const i in thiss.state.updateInformation) {
                const index: number = thiss.props.obstacleEditLayer.editableObstacleIds.findIndex(id => id === i)
                if ( index >= 0) {
                    if (thiss.state.updateInformation[i] != null) {
                        result[index] = (thiss.state.updateInformation[i] as Polygon)
                    } else {
                        delete result[index]
                    }
                }
            }
            // add undeployed new obstacles
            for (const i in thiss.state.added) {
                result.push({...this.state.added[i], className: obstacleClassNames.added,  
                    isObstacle: this.props.obstacleEditLayer.editingObstacleShape})
            }
        }

        return result
    }

    onObstacleAdd (polygon: Array<[number, number]>, thiss: LayeredMapComponent) {
        if (this.state.leafletMapComp && this.props.obstacleEditLayer) {
            const newAdded = thiss.state.added.map(added => added)
            newAdded.push({points: polygon, className: obstacleClassNames.added, 
                           isObstacle: this.props.obstacleEditLayer.editingObstacleShape} as ShapeData);
            this.setState({added: newAdded})
            this.sendObstaclesUpdate()
        }
    }

    onObstacleEdit (pointsGeo: PolygonPoints, polyIndex: number, thiss: LayeredMapComponent) {
        if (thiss.props.obstacleEditLayer) {

            const newAdded: Polygon[] = thiss.state.added.map(oldAdded => oldAdded)
            const newUpdateInfo: {[index: string]: (ShapeData | null)} = {}
            console.log("thiss.state.updateInformation",thiss.state.updateInformation)
            for (const i in thiss.state.updateInformation) {
                newUpdateInfo[i] = thiss.state.updateInformation[i]
                newUpdateInfo[i]['isObstacle'] = this.props.obstacleEditLayer.editingObstacleShape;
            }

            if (polyIndex >= thiss.props.obstacleEditLayer.editableObstacleIds.length) {
                if (newAdded.length > polyIndex - thiss.props.obstacleEditLayer.editableObstacleIds.length) {
                    newAdded[polyIndex - thiss.props.obstacleEditLayer.editableObstacleIds.length].points = pointsGeo
                } else {
                    newAdded.push({points: pointsGeo, className: obstacleClassNames.edited,
                            isObstacle: this.props.obstacleEditLayer.editingObstacleShape} as ShapeData);   
                }         
            } else {
                newUpdateInfo[thiss.props.obstacleEditLayer.editableObstacleIds[polyIndex]] = {points: pointsGeo, className:obstacleClassNames.edited,
                             isObstacle: this.props.obstacleEditLayer.editingObstacleShape} as ShapeData;
            }

            thiss.setState({added: newAdded, updateInformation: newUpdateInfo})
            thiss.sendObstaclesUpdate()
        }
    }



    getGuidelineLayer () {
        let guidelineLayer: LeafletMapComponentPolygonOverlay = null;
        if (this.props.guidelineLayer ) {
            const obstaclesGeo = this.props.guidelineLayer.path.map(obstacle => {
                const obstacleGeo = JSON.parse(JSON.stringify(obstacle)) as Obstacle;
                const map = this.state.leafletMapComp
                if (map && map.leafletMap && map.leafletMap.map) {
                    obstacleGeo.points = []
                    obstacle.points.forEach(point => obstacleGeo.points.push(this.mmToLatLonTuple(map.leafletMap.map, {x: point[0], y: point[1]})))
                }
                return obstacleGeo
            })

            guidelineLayer = {
                    layerType: 'polygon',
                    polygon: this.getEditedObstaclePolygons(obstaclesGeo, obstacleClassNames, this),
                    visible: this.layerVisible(Layer.guideline),
            }            
        }
        return {guidelines: guidelineLayer}
    }



    getObstacleLayer () {
        let obstacleLayer: LeafletMapComponentPolygonOverlay = null;
        if (this.props.obstacleLayer) {
            const obstaclesGeo = this.props.obstacleLayer.obstacles.map(obstacle => {
                const obstacleGeo = JSON.parse(JSON.stringify(obstacle)) as Obstacle;
                const map = this.state.leafletMapComp
                if (map && map.leafletMap && map.leafletMap.map) {
                    obstacleGeo.points = []
                    obstacle.points.forEach(point => obstacleGeo.points.push(this.mmToLatLonTuple(map.leafletMap.map, {x: point[0], y: point[1]})))
                }
                return obstacleGeo
            })

            obstacleLayer = {
                    layerType: 'polygon',
                    polygon: this.getEditedObstaclePolygons(obstaclesGeo, obstacleClassNames, this),
                    visible: !!this.props.obstacleEditLayer || this.layerVisible(Layer.obstacle),
            }            
        }
        return {obstacles: obstacleLayer}
    }


    getDrivableLayer () {
        let obstacleLayer: LeafletMapComponentPolygonOverlay = null;
        if (this.props.drivableLayer) {
            const obstaclesGeo = this.props.drivableLayer.nobstacles.map(obstacle => {
                const obstacleGeo = JSON.parse(JSON.stringify(obstacle)) as Obstacle;
                const map = this.state.leafletMapComp
                if (map && map.leafletMap && map.leafletMap.map) {
                    obstacleGeo.points = []
                    obstacle.points.forEach(point => obstacleGeo.points.push(this.mmToLatLonTuple(map.leafletMap.map, {x: point[0], y: point[1]})))
                }
                return obstacleGeo
            })

            obstacleLayer = {
                    layerType: 'polygon',
                    polygon: this.getEditedObstaclePolygons(obstaclesGeo, obstacleClassNames, this),
                    visible: !!this.props.obstacleEditLayer || this.layerVisible(Layer.drivable),
            }            
        return {drivable: obstacleLayer}

        }
        return {}
    }


    getNewTargetLayer () {
        let obstacleLayer: LeafletMapComponentPolygonOverlay = null;
        if (this.props.newTargetLayer) {
            const obstaclesGeo = this.props.newTargetLayer.obstacles.map(obstacle => {
                const obstacleGeo = JSON.parse(JSON.stringify(obstacle)) as Obstacle;
                const map = this.state.leafletMapComp;
                if (map && map.leafletMap && map.leafletMap.map) {
                    obstacleGeo.points = [];
                    obstacle.points.forEach(point => obstacleGeo.points.push(this.mmToLatLonTuple(map.leafletMap.map, {x: point[0], y: point[1]})))
                }
                return obstacleGeo;
            })

            obstacleLayer = {
                    layerType: 'polygon',
                    polygon: this.getEditedObstaclePolygons(obstaclesGeo, obstacleClassNames, this),
                    visible: !!this.props.obstacleEditLayer || this.layerVisible(Layer.newTarget),
                    onClick: (shapeId) => this.handleNewTargetClick(shapeId),
            }            
        return {newTarget: obstacleLayer};

        }
        return {}
    }



    getObstacleEditLayer () {
        if (this.props.obstacleEditLayer) {
            const obstaclesGeo = this.props.obstacleEditLayer.editableObstacles.map(obstacle => {
                const obstacleGeo = JSON.parse(JSON.stringify(obstacle)) as Obstacle
                const map = this.state.leafletMapComp
                if (map && map.leafletMap && map.leafletMap.map) {
                    obstacleGeo.points = []
                    obstacle.points.forEach(point => obstacleGeo.points.push(this.mmToLatLonTuple(map.leafletMap.map, {x: point[0], y: point[1]})))
                }
                return obstacleGeo
            })

            const obstacleEditLayer: LeafletMapComponentPolygonOverlay = {
                layerType: 'polygon',
                onEdit: (poly, index) => {this.onObstacleEdit(poly, index, this)},
                polygon: this.getEditedObstaclePolygons(obstaclesGeo, obstacleClassNames, this),
                visible: true,
                onClick: (shapeId) => this.handleObstacleClick(shapeId),
            }
            return {editableObstacles: obstacleEditLayer}
        }
        return {}
    }


    getPathLayer () {
        let guidelineLayer: LeafletMapComponentPolygonOverlay = null;
        if (this.props.pathLayer && this.props.pathLayer.path && this.props.pathLayer.path.length ) {
            const obstaclesGeo = this.props.pathLayer.path.map(obstacle => {
                const obstacleGeo = JSON.parse(JSON.stringify(obstacle)) as Obstacle;
                const map = this.state.leafletMapComp
                if (map && map.leafletMap && map.leafletMap.map) {
                    obstacleGeo.points = []
                    obstacle.points.forEach(point => obstacleGeo.points.push(this.mmToLatLonTuple(map.leafletMap.map, {x: point[0], y: point[1]})))
                }
                return obstacleGeo
            })

            guidelineLayer = {
                    layerType: 'polygon',
                    polygon: this.getEditedObstaclePolygons(obstaclesGeo, obstacleClassNames, this),
                    visible: this.layerVisible(Layer.path)
            }            
        return {paths: guidelineLayer}

        }
        return {}
    }



    handleObstacleClick(shapeId: number | string) {
        this.props.obstacleEditLayer.obstacleClicked(shapeId);

    }


    // Vehicles ######################################
    getVehicleLayers () {
        if (this.props.vehicleLayer) {
            const vehicleLayer: LeafletMapComponentCanvasOverlay = {
                drawFn: (map, params: CanvasLayerDrawFnParameters) => this.drawVehicles(map, params),
                layerType: 'canvas',
                visible: this.layerVisible(Layer.vehicle),
            }
            const vehicleClickLayer: LeafletMapComponentCanvasOverlay = {
                drawFn: (map, params: CanvasLayerDrawFnParameters) => this.drawClickVehicles(map, params),
                layerType: 'canvas',
                visible: this.layerVisible(Layer.vehicle),
            }

            if ( this.state.ghostVehicle){
                const ghostVehicleLayer: LeafletMapComponentCanvasOverlay = {
                    drawFn: (map, params: CanvasLayerDrawFnParameters) => this.drawSingleVehicle(map, params, 
                        this.state.ghostVehicle),
                    layerType: 'canvas',
                    visible: this.layerVisible(Layer.vehicle),
                    }
                    
                return {vehicles: vehicleLayer, vehiclesClick: vehicleClickLayer, ghostvehicle:ghostVehicleLayer}

            } else {
                return {vehicles: vehicleLayer, vehiclesClick: vehicleClickLayer}
            }
            
        }
    }

    interpolate (a: number, b: number, progress: number) {
        // cubic easing function
        // progress = progress < .5 ? 4 * progress * progress * progress : (progress - 1) * (2 * progress - 2) * (2 * progress - 2) + 1
        // quad easing
        // progress = progress < .5 ? 2 * progress * progress : -1 + (4 - 2 * progress) * progress
        // sin easing
        // progress = 1 - (Math.cos(progress * Math.PI) + 1) * .5
        return a * (1 - progress) + b * progress
    }

    
    interpolateAngle (a: number, b: number, progress: number) {
        if (Math.abs(a-b)>1.5*Math.PI) {
            console.log("jumping angles", a,b);
            return b;
        }
        return a * (1 - progress) + b * progress
    }

    interpolateVehicle (vehicle: Vehicle, originOffsetMm=[0, 0]): Partial<Vehicle> {
        if (this.state.fromPoses && this.state.fromPoses[vehicle.id]
            && this.state.toPoses && this.state.toPoses[vehicle.id]
            && this.state.transitionStartTime && this.state.expectedDuration) {
            const transVehicle = {
                id: vehicle.id,
                parts: vehicle.parts.map(part => part),
                position: {x: 0, y: 0},
            }

            let transitionStartTime  =this.state.transitionStartTime;
            let expectedDuration = this.state.expectedDuration;

            // Carlos: set duration for each vehicle
            // if (vehicle.deltaAnimation) {
            //     expectedDuration = vehicle.deltaAnimation*1;
            // }

            const fromPose = {...this.state.fromPoses[vehicle.id]};
            const toPose = {...this.state.toPoses[vehicle.id]};
            fromPose.x += originOffsetMm[0];
            fromPose.y += originOffsetMm[1];
            toPose.x += originOffsetMm[0];
            toPose.y += originOffsetMm[1];

            let t = Math.min(1, (new Date().getTime() - transitionStartTime) / expectedDuration)

            const transPosition = {
                x: this.interpolate(fromPose.x, toPose.x, t),
                y: this.interpolate(fromPose.y, toPose.y, t),
            }
            transVehicle.position = transPosition;
            
            const origin = this.props.yard.origin
            const latLng = helyosService.convertMMtoLatLng(origin.lat, origin.lon, [[transPosition.x, transPosition.y]])[0];
            const pixelpos = this.state.leafletMapComp.leafletMapProxy.latLngToCanvasPoint(latLng as [number, number]);
            const originPixelpos = this.state.leafletMapComp.leafletMapProxy.latLngToCanvasPoint([origin.lat, origin.lon]);
            const calibratedX = pixelpos.x - originPixelpos.x; 
            const calibratedY = fromPose.y - originPixelpos.y; 
            

            // transVehicle.position =  {
            //     x: this.interpolate(fromPose.x, calibratedX, t),
            //     y: this.interpolate(fromPose.y, calibratedY, t),
            // };

            for (let i = 0; i < vehicle.parts.length; i++) {
                const fromOrientation = fromPose.orientations[i] ? fromPose.orientations[i] : 0
                const toOrientation = toPose.orientations[i] ? toPose.orientations[i] : 0
                vehicle.parts[i].angle = this.interpolateAngle(fromOrientation, toOrientation, t)
            }
            return transVehicle
        }
        return {...vehicle, position: {x: -vehicle.position.x, y: -vehicle.position.y}}
    }

    drawVehicles (map: LeafletMapProxy, params: CanvasLayerDrawFnParameters): void {
        if (!this.props.vehicleLayer) {
            return
        }

        const canvas = this.prepareCanvas(map, params);
        const ctx = canvas.instance;
        if (!ctx) {
            return
        }
        ctx.canvas.id = 'vehicles'
        ctx.canvas.style.zIndex = '100' // LeafletMapComponent basically draws the canvas layers in a random zIndex
        const vehicles = this.props.vehicleLayer.vehicles
        for (let i = 0; i < vehicles.length; i++) {
            const vehicle = lodash.cloneDeep(vehicles[i]);
            const xOffset = canvas.originOffsetMm[0], yOffset = canvas.originOffsetMm[1]; 
            vehicle.position.x -= xOffset;
            vehicle.position.y -= yOffset;
            const isSelected = this.props.vehicleLayer.selectedPresence ? this.props.vehicleLayer.selectedPresence.id === vehicle.id : false

            const interpolatedVehicle = this.interpolateVehicle(vehicle, canvas.originOffsetMm)
            if (vehicle.status !== 'free') {
                renderVehicle(ctx, interpolatedVehicle, map.getMmPerPx(), isSelected, fillColors.busy )
            } else {
                renderVehicle(ctx, interpolatedVehicle, map.getMmPerPx(), isSelected,)
            }

        }
    }


    drawSingleVehicle (map: LeafletMapProxy, params: CanvasLayerDrawFnParameters, vehicle: Vehicle): void {
        if (!this.props.vehicleLayer || !vehicle) {
            return
        }

        const canvas = this.prepareCanvas(map, params);
        const ctx = canvas.instance;
        if (!ctx) {
            return
        }

        ctx.canvas.id = 'vehicles'
        ctx.canvas.style.zIndex = '100' // LeafletMapComponent basically draws the canvas layers in a random zIndex
        const xOffset = canvas.originOffsetMm[0], yOffset = canvas.originOffsetMm[1]; 
        vehicle = lodash.cloneDeep(vehicle);
        vehicle.position.x -= xOffset;
        vehicle.position.y -= yOffset;
        const interpolatedVehicle = this.interpolateVehicle(vehicle,canvas.originOffsetMm)
        renderVehicle(ctx, interpolatedVehicle, map.getMmPerPx(), false)

    }

    drawClickVehicles (map: LeafletMapProxy, params: CanvasLayerDrawFnParameters): void {
        if (!this.props.vehicleLayer) {
            return
        }

        const canvas = this.prepareCanvas(map, params);
        const ctx = canvas.instance;
        if (!ctx) {
            return
        }
        ctx.canvas.id = 'clickVehicles'
        ctx.canvas.style.visibility = 'hidden'
        const vehicles = this.props.vehicleLayer.vehicles
        for (let i = 0; i < vehicles.length; i++) {
            const color = this.getColorFromMapping(vehicles[i].id, 'vehicle')
            if (color) {
                const vehicle = lodash.cloneDeep(vehicles[i]);
                const xOffset = canvas.originOffsetMm[0], yOffset = canvas.originOffsetMm[1]; 
                vehicle.position.x -= xOffset;
                vehicle.position.y -= yOffset;
                const interpolatedVehicle = this.interpolateVehicle(vehicle,canvas.originOffsetMm)
                renderVehicle(ctx, interpolatedVehicle, map.getMmPerPx(), this.props.vehicleLayer.selectedPresence ? this.props.vehicleLayer.selectedPresence.id === vehicle.id : false, color)
            }
        }
    }

    handleVehicleClick (location: LatLonTuple): boolean {
        if (this.state.leafletMapComp && this.props.vehicleLayer && this.props.vehicleLayer.vehicleClicked) {
            const pixelpos = this.state.leafletMapComp.leafletMapProxy.latLngToCanvasPoint(location)

            const vehicleClickLayer = this.state.leafletMapComp.overlays.vehiclesClick as LeafletCanvasOverlay
            const pixelColor = vehicleClickLayer.getPixelColor(pixelpos.x, pixelpos.y)
            if (pixelColor && pixelColor[3] === 255) {
                const pixelColorInt = pixelColor[0] * 256 * 256 + pixelColor[1] * 256 + pixelColor[2]
                const mapping = this.state.clickIdMappings.find(mapping => mapping.colorInt === pixelColorInt)
                if (mapping) {
                    const vehicle: Vehicle = this.props.vehicleLayer.vehicles.find(vehicle => vehicle.id === mapping.id)
                    if (vehicle) {
                        this.props.vehicleLayer.vehicleClicked(vehicle.id)
                        this.setState({ orientationsGoal: vehicle.parts[0].angle*1000});
                        return true
                    }
                }
            }
        }
        return false
    }

    // Targets ######################################

    handleMapPosClick (location: LatLonTuple): boolean {
        if (this.state.selectedTarget) {return false}

        let ghostVehicle: VehicleInstance = null;
        const mapComp = this.state.leafletMapComp;
        const mmLocation = this.latLonTupleToMm(mapComp.leafletMap.map, location)
        if (this.props.vehicleLayer && this.props.vehicleLayer.selectedPresence){
            const state: ApplicationState = Store.getState();
            if (state.uiControl.playback.visible) {
                alert('You are in the playback mode. CLOSE the playback to create a mission');
                return false;
            }
            if (this.props.vehicleLayer.selectedPresence.status !== 'free') {
                alert('Vehicle is already assigned to a mission');
                return false;
            }
            // if (this.props.vehicleLayer.selectedPresence.connectionStatus === 'offline') {
            //     alert('Vehicle is offline');
            //     return false;
            // }
            const presence = JSON.parse(JSON.stringify(this.props.vehicleLayer.selectedPresence));
            presence.pose.x = mmLocation[0];
            presence.pose.y = mmLocation[1];
            ghostVehicle = new VehicleInstance(presence);
            ghostVehicle.id = '0';
            if (ghostVehicle) { 
                ghostVehicle.setAngle([this.state.orientationsGoal]);
            }
            this.setState({selectedMapLocation: mmLocation, ghostVehicle});
        } else{
            this.setState({selectedMapLocation: mmLocation});
        }
        return true;
    }

    handleTargetClick (location: LatLonTuple): boolean {
        if (this.state.leafletMapComp && this.props.targetLayer && this.props.vehicleLayer && this.props.vehicleLayer.selectedPresence) {
            const pixelpos = this.state.leafletMapComp.leafletMapProxy.latLngToCanvasPoint(location)
            const targetClickLayer = this.state.leafletMapComp.overlays.targetsClick as LeafletCanvasOverlay
            const pixelColor = targetClickLayer.getPixelColor(pixelpos.x, pixelpos.y)
            if (pixelColor && pixelColor[3] === 255) {
                const pixelColorInt = pixelColor[0] * 256 * 256 + pixelColor[1] * 256 + pixelColor[2]
                const targetMapping = this.state.clickIdMappings.find(mapping => mapping.colorInt === pixelColorInt)
                if (targetMapping) {
                    const target = this.props.targetLayer.targets.find(target => target.id === targetMapping.id)
                    if (target) {
                        const state: ApplicationState = Store.getState();
                        if (state.uiControl.playback.visible) {
                            alert('You are in the playback mode. CLOSE the playback to create a mission')
                            return false;
                        }
                        this.setState({selectedTarget: target})
                        return true
                    }
                }
            }
        }
        return false
    }


    handleNewTargetClick(mapObjectId:number | string){
        const target = this.props.targetLayer.targets.find(target => target.id == mapObjectId)
                    if (target) {
                        const state: ApplicationState = Store.getState();
                        if (state.uiControl.playback.visible) {
                            alert('You are in the playback mode. CLOSE the playback to create a mission')
                            return false;
                        }
                        this.setState({selectedTarget: target})
                        return true
                    }
    }

    drawTargets (map: LeafletMapProxy, params: CanvasLayerDrawFnParameters) {
        if (!this.props.targetLayer) {
            return
        }

        const canvas = this.prepareCanvas(map, params);
        const ctx = canvas.instance;
        if (!ctx) {
            return
        }
        ctx.canvas.style.zIndex = '50'
        const xOffset = canvas.originOffsetMm[0], yOffset = canvas.originOffsetMm[1]; 

        for (let i = 0; i < this.props.targetLayer.targets.length; i++) {
            const target = lodash.cloneDeep(this.props.targetLayer.targets[i]);
            target.pose.x -= xOffset;
            target.pose.y += yOffset;
            renderTarget(ctx, target, map.getMmPerPx())
        }

        ctx.restore()
    }

    drawClickTargets (map: LeafletMapProxy, params: CanvasLayerDrawFnParameters) {
        if (!this.props.targetLayer) {
            return
        }

        const canvas = this.prepareCanvas(map, params);
        const ctx = canvas.instance;
        if (!ctx) {
            return
        }
        const xOffset = canvas.originOffsetMm[0], yOffset = canvas.originOffsetMm[1]; 
        ctx.canvas.style.visibility = 'hidden'
        const targets = this.props.targetLayer.targets
        for (let i = 0; i < targets.length; i++) {
            const target = lodash.cloneDeep(this.props.targetLayer.targets[i]);
            target.pose.x -= xOffset;
            target.pose.y += yOffset;
            const color = this.getColorFromMapping(target.id, 'target')
            if (color) {
                renderTarget(ctx, target, map.getMmPerPx(), color)
            }
        }

        ctx.restore()
    }

    getTargetLayers () {
        if (this.state.leafletMapComp && this.props.targetLayer) {
            const targetLayer: LeafletMapComponentCanvasOverlay = {
                drawFn: (map, params: CanvasLayerDrawFnParameters) => this.drawTargets(map, params),
                layerType: 'canvas',
                visible: this.layerVisible(Layer.target),
            }
            const targetClickLayer: LeafletMapComponentCanvasOverlay = {
                drawFn: (map, params: CanvasLayerDrawFnParameters) => this.drawClickTargets(map, params),
                layerType: 'canvas',
                visible: this.layerVisible(Layer.target),
            }
            return {targets: targetLayer, targetsClick: targetClickLayer}
        }
    }

    // Missions #############################################
    drawMissions (map: LeafletMapProxy, params: CanvasLayerDrawFnParameters) {
        if (!this.props.missionLayer) {
            return
        }

        const canvas = this.prepareCanvas(map, params);
        const ctx = canvas.instance;
        if (!ctx) {
            return
        }
        ctx.canvas.style.zIndex = '51'

        for (let i = 0; i < this.props.missionLayer.missions.length; i++) {
            renderMission(ctx, this.props.missionLayer.missions[i], map.getMmPerPx())
        }

        ctx.restore()
    }

    getMissionsLayer () {
        if (this.state.leafletMapComp && this.props.missionLayer) {
            const missionLayer: LeafletMapComponentCanvasOverlay = {
                drawFn: (map, params: CanvasLayerDrawFnParameters) => this.drawMissions(map, params),
                layerType: 'canvas',
                visible: this.layerVisible(Layer.mission),
            }
            return {missions: missionLayer}
        }
    }

    // push changes to container, units converted to millimeter coordinates
    sendObstaclesUpdate () {
        //TODO add here information to update bottom, type and top
        if (this.props.obstacleEditLayer && this.state.leafletMapComp) {
            const map = this.state.leafletMapComp;
            const newUpdateInfo: {[index: string]: (ShapeData | null)} = {}
            for (const key in this.state.updateInformation) {
                const updateInfoCopy = {...this.state.updateInformation[key]} as ShapeData | null
                if (updateInfoCopy && map && map.leafletMap && map.leafletMap.map) {
                    updateInfoCopy.points = updateInfoCopy.points.map(point => this.latLonTupleToMm(map.leafletMap.map, point) as [number, number])
                }
                newUpdateInfo[key] = updateInfoCopy
            }
            const newAdded = this.state.added.map(poly => {
                const points = poly.points.map(point => this.latLonTupleToMm(map.leafletMap.map, point))
                return {
                    className: poly.className,
                    points,
                    isObstacle: poly.isObstacle
                }
            })
            const remappedState: ObstacleEditState = {
                added: newAdded,
                shouldUpdate: this.state.shouldUpdate,
                updateInformation: newUpdateInfo,
            }
            console.log(newUpdateInfo)
            this.props.obstacleEditLayer.updateObstacles(remappedState)
        }
    }

    componentWillUpdate (nextProps: Props) {
        if (this.props.uiControl && this.props.uiControl.commandView.collapseSchedule && !this.state.mapStretched){
            this.setState({redraw:true});
            this.setState({mapStretched:true});
        }

        if (this.props.uiControl && !this.props.uiControl.commandView.collapseSchedule && this.state.mapStretched){
            this.setState({redraw:true});
            this.setState({mapStretched:false});
        }

        if (this.props.uiControl &&  this.props.uiControl.lastMapUpdateRequest > this.state.lastUpdated ){
            const now = new Date();
            this.setState({redraw:true});
            this.setState({lastUpdated: now});
        }

        if (this.props.obstacleEditLayer) {
            // on deployment of changes, stop updating until the deployed data is fetched from the database
            if (!this.props.obstacleEditLayer.changesDeployed && nextProps.obstacleEditLayer && nextProps.obstacleEditLayer.changesDeployed) {
                this.setState({shouldUpdate: false})
                this.setState({added:[]});
                this.setState({redraw:true});

            } else {
                if (!this.state.shouldUpdate) {
                    this.setState({added: [], updateInformation: {}, shouldUpdate: true})
                }
            }
            // start editing if the editing prop switches to true
            if (!this.props.obstacleEditLayer.editing && nextProps.obstacleEditLayer && nextProps.obstacleEditLayer.editing) {
                if (this.state.leafletMapComp) {
                    this.state.leafletMapComp.leafletMap.startDrawPolygon(polygon => this.onObstacleAdd(polygon, this))
                }
            }
        }

        const newClickMappings = this.state.clickIdMappings.map(mapping => mapping)
        let clickMappingAdded = false

        // make click id to color mappings
        if (nextProps.targetLayer) {
            const targets = nextProps.targetLayer.targets
            for (let t = 0; t < targets.length; t++) {
                if (!this.state.clickIdMappings.find(mapping => mapping.tag === 'target' && mapping.id === targets[t].id)) {
                    const newMapping = {id: targets[t].id, tag: 'target', colorInt: this.getUnusedMappingColor(newClickMappings)}
                    newClickMappings.push(newMapping)
                    clickMappingAdded = true
                }
            }
        }

        if (nextProps.vehicleLayer) {
            const vehicles = nextProps.vehicleLayer.vehicles
            for (let v = 0; v < vehicles.length; v++) {
                if (!this.state.clickIdMappings.find(mapping => mapping.tag === 'vehicle' && mapping.id === vehicles[v].id)) {
                    const newMapping = {id: vehicles[v].id, tag: 'vehicle', colorInt: this.getUnusedMappingColor(newClickMappings)}
                    newClickMappings.push(newMapping)
                    clickMappingAdded = true
                }
            }

            if (this.props.vehicleLayer && nextProps.vehicleLayer !== this.props.vehicleLayer) {
                // transition data
                const newPoses: {[id: string]: Pose} = {}
                const oldVehicles = this.props.vehicleLayer.vehicles
                vehicles.forEach(vehicle => {
                    if (!vehicle) {return; }
                    if (!vehicle.parts) {return; }
                    newPoses[vehicle.id] = {
                        orientations: vehicle.parts.map((part, i) => {
                            // handle angle jumps around 0
                            const oldVehicle = oldVehicles.find(oldVehicle => oldVehicle.id === vehicle.id)
                            try { // if the angle difference is bigger than half a turn
                                if (oldVehicle) {
                                    const oldAngle = oldVehicle.parts[i].angle
                                    let newAngle = part.angle
                                    
                                    while (Math.abs(oldAngle - newAngle) >= Math.PI) {
                                        if (newAngle > oldAngle) {
                                            newAngle -= Math.PI * 2
                                        } else {
                                            newAngle += Math.PI * 2
                                        }
                                    }
                                    return Math.round(newAngle * 1000) / 1000
                                }
                                
                            } catch (error) {
                                
                            }
            
                            return Math.round(part.angle * 1000) / 1000
                        }),
                        x: -vehicle.position.x,
                        y: -vehicle.position.y,
                    }
                })
                const now = new Date().getTime()
                // const duration = this.state.transitionStartTime ? now - this.state.transitionStartTime : 1000
                const duration = 1000;

                if (this.state.fromPoses && this.state.toPoses && this.state.transitionStartTime && this.state.expectedDuration) {
                    const from = this.state.fromPoses
                    const to = this.state.toPoses
                    const t = Math.min(1, (new Date().getTime() - this.state.transitionStartTime) / this.state.expectedDuration)
                    const newFromPoses: {[id: string]: Pose} = {}
                    this.props.vehicleLayer.vehicles.forEach(vehicle => {
                        if (!vehicle) { return; }
                        if (!vehicle.parts) {return; }
                        
                        if (from[vehicle.id] && to[vehicle.id]) {

                            const a = from[vehicle.id]
                            const b = to[vehicle.id]
                            // console.log('ab ' + vehicle.id, a, b)
                            const newPose: Pose = {
                                orientations: a.orientations.map((ori, i) => this.interpolateAngle(ori, b.orientations[i], t)),
                                x: this.interpolate(a.x, b.x, t),
                                y: this.interpolate(a.y, b.y, t),
                            }
                            // console.log('newPose', newPose)
                            newFromPoses[vehicle.id] = newPose
                        } else {
                            newFromPoses[vehicle.id] = {x: -vehicle.position.x, y: -vehicle.position.y, orientations: vehicle.parts.map(part => part.angle)}
                        }
                    })
                    this.setState({fromPoses: newFromPoses, toPoses: newPoses, transitionStartTime: now, expectedDuration: duration * 1.0})
                } else {
                    this.setState({fromPoses: this.state.toPoses, toPoses: newPoses, transitionStartTime: now, expectedDuration: duration * 1.0})
                }
            }
        }

        if (clickMappingAdded) {
            this.setState({clickIdMappings: newClickMappings})
        }
    }

    componentDidUpdate () {
        // log('LayeredMap Updated')
        const map = this.state.leafletMapComp
        if (map && map.leafletMapProxy) {
            map.leafletMapProxy.redrawAllCanvasLayers()
        }
    }

    componentDidMount () {
        const redrawingFunction = () => {
            const map = this.state.leafletMapComp
            if (map && map.leafletMapProxy) {
                map.leafletMapProxy.redrawCanvasLayer('vehicles')
                map.leafletMapProxy.redrawCanvasLayer('clickVehicles')
            }
            // window.setTimeout(redrawingFunction, 100)
            window.requestAnimationFrame(redrawingFunction)
        }
        redrawingFunction()
    }


    handleClick (location: LatLonTuple) {
        console.log("map click", location);
        if (!this.handleVehicleClick(location)) {
            if(!this.handleTargetClick(location)){
                this.handleMapPosClick(location);
            }
        }
        const mapComp = this.state.leafletMapComp;
        const mmLocation = this.latLonTupleToMm(mapComp.leafletMap.map, location);
        console.log("map click", mmLocation);

    }



    renderLocationConfirmation () {
        if (this.props.vehicleLayer && this.props.vehicleLayer.selectedPresence && this.state.selectedMapLocation) {
            const presenceId = this.props.vehicleLayer.selectedPresence.id
            const x = this.state.selectedMapLocation[0];
            const y = this.state.selectedMapLocation[1];
            let orientation = this.state.orientationsGoal;
            let orientationTrailer = this.state.orientationTrailerGoal;

            let ghostVehicle = lodash.cloneDeep(this.state.ghostVehicle)
            if (ghostVehicle) { 
                ghostVehicle.setAngle([this.state.orientationsGoal, this.state.orientationTrailerGoal])
            }

            const handleChange = (event) => {
                const stateAttr = event.target.name;
                const statePatch = {};
                let value = event.target.type ==='checkbox'? event.target.checked : event.target.value;
                 
                if (stateAttr==='orientationsGoal'){
                    value = Number(value);
                    ghostVehicle.setAngle([value, orientationTrailer ]);
                } 


                if (stateAttr==='orientationTrailerGoal'){
                    value =  Number(value);
                    ghostVehicle.setAngle([orientation, value ]);
                } 
                
                if (ghostVehicle) { statePatch['ghostVehicle'] = ghostVehicle; }
                statePatch[stateAttr] = value;
                this.setState(statePatch);

            }
            
            const rotateVehicle = (signal) => {
                orientation= this.state.orientationsGoal - signal*196.35
                ghostVehicle.setAngle([orientation, orientationTrailer]);
                this.setState({ ghostVehicle:ghostVehicle, orientationsGoal: orientation  });
            }


            const handleValueChange = sched_start => {
                console.log(sched_start ); // && sched_start.format('HH:mm:ss'));
                this.setState({ sched_start });
            };


            const addMissionDataToRequest = (driveType: string, sched_start, sched_end) => {
                let missionRequests;
                let editingMission = new MissionData(presenceId, driveType,
                                                               {pose:{ x,y,
                                                                orientations:[this.state.orientationsGoal]}, 
                                                                yardId:this.props.yard.id
                                                                 },
                                                     sched_start,
                                                     this.state.workTypeName,
                                                     sched_end,
                                                    );

                if (!this.state.missionRequests) {
                    missionRequests = [editingMission];
                } else {
                    missionRequests = [...this.state.missionRequests];
                    missionRequests.push(editingMission);
                }

                return missionRequests;
            }


            const nextAction = () => {
                let sched_start = new Date();
                let sched_end = null;

                if (this.state.sched_start) {
                    sched_start = this.state.sched_start;
                }
                const missionRequests = addMissionDataToRequest('location', sched_start, sched_end);
                this.setState({selectedTarget: null, selectedMapLocation: null, ghostVehicle: null, missionRequests});
            }


            const selectWProcessType = (wprocType) => {
                this.setState({ selectedMissionType: wprocType,
                                wpTypeId: wprocType.id, 
                                workTypeName: wprocType.name, 
                                wpTypeSettings: JSON.stringify(wprocType.settings, null, 2)});
            }

            const loadSettings = () => {
                getWorkProcessDetails(this.state.workTypeName)
                .then(r => {
                     if(r){
                            this.setState({wpTypeId: r.id, wpTypeSettings: JSON.stringify(r.settings, null, 2)});
                     } else{
                            this.setState({wpTypeId: null});
                        }
                });
            }


            const saveSettings = () => {
                let patch;
                try {
                    patch = {id: this.state.wpTypeId, settings: JSON.parse(this.state.wpTypeSettings)};
                    saveWorkProcessDetails(patch);
                } catch (error) {
                    alert('invalid JSON')
                }
            }

            const confirmAction = () => {
                if(this.state.saveTarget){
                    const newTarget = new TargetData(this.props.yard.id, this.state.targetName,
                                                                            x,-y,[this.state.orientationsGoal]);                                                                              
                    createTarget(newTarget);
                }

                let sched_start = new Date();
                let sched_end = null;
                if (this.state.sched_start) {
                    sched_start = this.state.sched_start;
                }
               
                if (this.state.missionRequests && this.state.missionRequests.length ) {
                    const missionRequests = addMissionDataToRequest('location', sched_start, sched_end)  
                    createMultiDriveWorkProcess(missionRequests, this.state.workTypeName, sched_start, sched_end);

                } else {
                    // createMission(presenceId, 'location', {pose:{x,y,orientations:[this.state.orientationsGoal, this.state.orientationTrailerGoal]}}, sched_start, this.state.workTypeName,  this.state.wpTypeSettings); 
                    createMission(presenceId, 'location', {pose:{x,y,orientations:[this.state.orientationsGoal], orientation: this.state.orientationTrailerGoal}}, sched_start, this.state.workTypeName,  this.state.wpTypeSettings); 
                }

                this.setState({selectedMapLocation: null,  ghostVehicle: null, missionRequests: null})
            }


            const cancelAction = () => {this.setState({selectedMapLocation: null, ghostVehicle: null, missionRequests: null})}


            return (

                <ModalDialog confirmAction={confirmAction} cancelAction={cancelAction} title="Neu Mission">

                <div style={{display:'flex', alignItems:'start', justifyContent:'space-around'}}>

                    <div className="mission-section">
                        <div>
                            {this.props.vehicleLayer.selectedPresence.main_licence_plate} drives to this position.
                        </div>
                        <div style={{display:'relative'}}>
                            <div style={{display:'flex', alignItems:'center'}} >
                                <button className="actionButton"  onClick={event => {event.stopPropagation(); rotateVehicle(-1)}}>  &#8634; </button>
                                <button className="actionButton" onClick={event => {event.stopPropagation(); rotateVehicle(+1)}}>  &#8635; </button>

                                <input  id="spinButton" name="orientationsGoal" type="number"  step="10" style={{width:79, marginBottom:0}}
                                                    value={this.state.orientationsGoal}  onChange={handleChange.bind(this)}/>
                            </div>
                            <DatePicker onChange={handleValueChange}   selected={this.state.sched_start}
                                timeFormat="HH:mm"
                                dateFormat="MMMM d, yyyy HH:mm"
                                timeIntervals={1}  
                                showTimeSelect = {true}
                                popperPlacement="top-end"
                                timeCaption="start" />
                        </div>

                        <form>
                                    <input name="saveTarget"  type="checkbox"
                                            checked={this.state.saveTarget} onChange={handleChange.bind(this)} />   
                                    &nbsp; Save position as target
                                    

                                    { this.state.saveTarget && 
                                                <input  name="targetName" type="text" placeholder="target name" disabled={!this.state.saveTarget}
                                                value={this.state.targetName}  onChange={handleChange.bind(this)}/>
                                    }
                        </form>

                        <div className="dialog-mission-form"> 
                                <span> Mission </span>

                                <MissionTypeDropdown className="float-left" onSelect={selectWProcessType} style="selected" selectedItem={this.state.selectedMissionType}/>
                                
                                <button className="button confirm" onClick={nextAction}>
                                            Next vehicle
                                </button>
                        </div>
                    </div>


                    <div className="mission-section">
                            <div style={{display: 'flex'}}>
                               Mission Settings
                               <button className="reload-button" onClick={loadSettings}> load </button>
                               <button className="reload-button" onClick={saveSettings}> save </button>
                            </div>
                            <textarea id="wpTypeSettings" rows={5} value={this.state.wpTypeSettings} name="wpTypeSettings"
                                                onChange={handleChange.bind(this)} > 
                            </textarea>
                    </div>
                </div>
                </ModalDialog>
            )
        }
    }


    renderTargetConfirmation () {
        if (this.props.vehicleLayer && this.props.vehicleLayer.selectedPresence && this.state.selectedTarget) {
            const presenceId = this.props.vehicleLayer.selectedPresence.id
            const targetId = this.state.selectedTarget.id



            const handleValueChange = sched_start => {
                console.log(sched_start ); // && sched_start.format('HH:mm:ss'));
                this.setState({ sched_start });
              };


            const nextAction = () => { alert('not yet implemented') }

            const confirmAction = () => {

                let sched_start = new Date();
                if (this.state.sched_start) {
                    sched_start = this.state.sched_start;
                }

                let anchor = this.state.anchor? this.state.anchor:this.state.selectedTarget.anchor;
                createMission(presenceId, 'target', {targetId:targetId}, sched_start, this.state.workTypeName, this.state.wpTypeSettings,this.state.anchor);

                this.setState({selectedTarget: null, anchor:null})
            
            }
            const cancelAction = () => {this.setState({selectedTarget: null, anchor:null})}
            
            const handleAnchorChange = (event) =>{
               this.setState({ anchor: event.target.value  });
            }

            const selectWProcessType = (wprocType) => {
                this.setState({ selectedMissionType: wprocType,
                                wpTypeId: wprocType.id, 
                                workTypeName: wprocType.name, 
                                wpTypeSettings: JSON.stringify(wprocType.settings, null, 2)});
            }

            return (
                <ModalDialog confirmAction={confirmAction} cancelAction={cancelAction} title="New Mission">
                    <div>
                        {this.props.vehicleLayer.selectedPresence.main_licence_plate} drives to {this.state.selectedTarget.name}.
                    </div>

                    <div style={{display:'relative'}}>
                        <select  onChange={handleAnchorChange} defaultValue={this.state.selectedTarget.anchor}>
                            <option  value="front">Front</option>
                            <option  value="rear">Rear</option>
                        </select>


                        <div className="dialog-mission-form"> 
                                <span> Mission </span>

                                <MissionTypeDropdown className="float-left" onSelect={selectWProcessType} style="selected" selectedItem={this.state.selectedMissionType}/>
                                
                                {/* <button className="button confirm" onClick={nextAction}>
                                            Next vehicle
                                </button> */}
                        </div>                        


                        <DatePicker onChange={handleValueChange}   selected={this.state.sched_start}
                            timeFormat="HH:mm"
                            dateFormat="MMMM d, yyyy HH:mm"
                            timeIntervals={2}  
                            showTimeSelect = {true}
                            popperPlacement="top-end"
                            timeCaption="start" />
                    </div>

                    <button className="button confirm" onClick={nextAction}>
                                Next
                    </button>
                </ModalDialog>
            )
        }
    }

    getVisibleLayers () {
        const layers = []
        if (this.props.vehicleLayer) {
            layers.push(<div key="vehicles" className={'checkbox' + (this.layerVisible(Layer.vehicle) ? ' active' : '')} onClick={() => this.toggleVisibleLayer(Layer.vehicle)}>Vehicles</div>)
        }
        if (this.props.obstacleLayer && !this.props.obstacleEditLayer) {
            layers.push(<div key="obstacles" className={'checkbox' + (this.layerVisible(Layer.obstacle) ? ' active' : '')} onClick={() => this.toggleVisibleLayer(Layer.obstacle)}>Obstacles</div>)
        }
        if (this.props.targetLayer) {
            layers.push(<div key="targets" className={'checkbox' + (this.layerVisible(Layer.target) ? ' active' : '')} onClick={() => this.toggleVisibleLayer(Layer.target)}>Targets</div>)
        }
        if (this.props.missionLayer) {
            // layers.push(<div key="missions" className={'checkbox' + (this.layerVisible(Layer.mission) ? ' active' : '')} onClick={() => this.toggleVisibleLayer(Layer.mission)}>Missionen</div>)
        }
        if (this.props.drivableLayer) {
            layers.push(<div key="drivable" className={'checkbox' + (this.layerVisible(Layer.drivable) ? ' active' : '')} onClick={() => this.toggleVisibleLayer(Layer.drivable)}>Drivable</div>)
        }
        if (this.props.newTargetLayer) {
            layers.push(<div key="newTarget" className={'checkbox' + (this.layerVisible(Layer.newTarget) ? ' active' : '')} onClick={() => this.toggleVisibleLayer(Layer.newTarget)}>Target area</div>)
        }
        if (this.props.obstacleLayer) {
            layers.push(<div key="paths" className={'checkbox' + (this.layerVisible(Layer.path) ? ' active' : '')} onClick={() => this.toggleVisibleLayer(Layer.path)}>Paths</div>)
        }
        if (this.props.guidelineLayer) {
            layers.push(<div key="guidelines" className={'checkbox' + (this.layerVisible(Layer.guideline) ? ' active' : '')} onClick={() => this.toggleVisibleLayer(Layer.guideline)}>Guidelines</div>)
        }
        return layers
    }

    layerVisible (layer: Layer) {
        return this.state.visibleLayers.indexOf(layer) >= 0
    }

    toggleVisibleLayer (layer: Layer) {
        const layers = this.state.visibleLayers.map(l => l)
        const index = layers.indexOf(layer)
        if (index >= 0) {
            layers.splice(index, 1)
        } else {
            layers.push(layer)
        }
        this.setState({visibleLayers: layers})
    }

    render () {
        if(!this.props.yard || !this.props.yard.origin ) {return(<div></div>)};
        const coords: LatLonTuple = [this.props.yard.origin.lat, this.props.yard.origin.lon]
        const overlays = {...this.getObstacleLayer(),...this.getGuidelineLayer(), ...this.getPathLayer(),  ...this.getObstacleEditLayer(),
                          ...this.getDrivableLayer(), ...this.getTargetLayers(), ...this.getNewTargetLayer(), ...this.getMissionsLayer(), 
                          ...this.getVehicleLayers()} as LeafletMapComponentOverlayIndex
        const layers = this.getVisibleLayers()
        return (
            <div className="LayeredMap Map">
            {
                layers.length > 0 ?
                <div id="layerSelect">
                    <div className="icon"></div>
                    <div className="layers">{layers}</div>
                </div>
                : ''
            }
                {this.renderTargetConfirmation()}
                {this.renderLocationConfirmation()}
            <LeafletMapComponent initialHomeCoordinates={coords} ref={comp => {if (!this.state.leafletMapComp) {this.setState({leafletMapComp: comp})}}} 
                    onMapClick={ (location: LatLonTuple) => this.handleClick(location)}
                    onRedraw={ (L:any) => this.setState({redraw:false})}
                    initialZoomLevel={this.props.yard.origin.zoomLevel} initialLayer="google-satellite" overlays={overlays} redraw={this.state.redraw} yard={this.props.yard}
            />
            </div>
            )
        }
    }
