
import 'cheap-ruler'
import cheapRuler = require('cheap-ruler');

// const ruler = cheapRuler(51.05332576949652,'kilometers');
export function  checkImageCenter(picturePos) {
    let hasAllValues = true;
    let latCenter = 0;
    let lonCenter = 0;

    for ( let point in picturePos) {
            if (['p1','p2','p3','p4'].indexOf(point)==-1) continue;
            hasAllValues = hasAllValues && picturePos[point].lon && picturePos[point].lat;
            if (hasAllValues){
                latCenter+= parseFloat(picturePos[point].lat);
                lonCenter+= parseFloat(picturePos[point].lon);
            }
    }
    return hasAllValues && {lat: latCenter/4, lon:lonCenter/4}
}

export function addLatLon(point, dpoint) {
    point.lat+= dpoint.lat;
    point.lon+= dpoint.lon;
}


export function positionImage(pCenter, picturePos) {
    if (!pCenter.lat) {
        pCenter.lat = picturePos.pCenter.lat;
    }

    if (!pCenter.lon) {
        pCenter.lon = picturePos.pCenter.lon;
    }
    if (!(picturePos.p1 && picturePos.p1.lat && picturePos.p1.lon )){
        picturePos.p1 = {lat:(parseFloat(pCenter.lat) + 0.0005), lon:(parseFloat(pCenter.lon) - 0.001)};
    }

    if (!(picturePos.p2 && picturePos.p2.lat && picturePos.p2.lon )){
        picturePos.p2 = {lat:(pCenter.lat + 0.0005), lon:(pCenter.lon + 0.001)};
    }

    if (!(picturePos.p3 && picturePos.p3.lat && picturePos.p3.lon )){
        picturePos.p3 = {lat:(pCenter.lat - 0.0005), lon:(pCenter.lon - 0.001)};
    }

    if (!(picturePos.p4 && picturePos.p4.lat && picturePos.p4.lon )){
        picturePos.p4 = {lat:(pCenter.lat - 0.0005), lon:(pCenter.lon + 0.001)};
    }

    return picturePos;
}


function rotate(vector: number[] | string[], angle:number | string) {
    const rad = Math.PI/180*(parseFloat(angle as string));
    const x = Math.cos(rad)*parseFloat(vector[0] as string) - Math.sin(rad)*parseFloat(vector[1] as string);
    const y = Math.sin(rad)*parseFloat(vector[0] as string) + Math.cos(rad)*parseFloat(vector[1] as string);
    return [x, y];
}

export function calculatePValues(Ref, RA, RB, RC, RD, rotation = 0) {
    const ruler = cheapRuler(parseFloat(Ref.lat),'kilometers');

    const P1Km  = rotate([-RD,  RA], rotation);
    const P2Km  = rotate([ RB,  RA], rotation);
    const P3Km  = rotate([-RD, -RC], rotation);
    const P4Km  = rotate([ RB, -RC], rotation);

    const P1 = ruler.offset([parseFloat(Ref.lon), parseFloat(Ref.lat)], P1Km[0], P1Km[1]);
    const P2 = ruler.offset([parseFloat(Ref.lon), parseFloat(Ref.lat)], P2Km[0], P2Km[1]);
    const P3 = ruler.offset([parseFloat(Ref.lon), parseFloat(Ref.lat)], P3Km[0], P3Km[1]);
    const P4 = ruler.offset([parseFloat(Ref.lon), parseFloat(Ref.lat)], P4Km[0], P4Km[1]);

    console.log([RA, RB, RC, RD, Ref])
    console.log('calcpvalues', P1)
    console.log('calcpvalues', P2)
    console.log('calcpvalues', P3)
    console.log('calcpvalues', P4)

    return {
        p1: {lat: P1[1], lon:P1[0]},
        p2: {lat: P2[1], lon:P2[0]},
        p3: {lat: P3[1], lon:P3[0]},
        p4: {lat: P4[1], lon:P4[0]},
    }

}
