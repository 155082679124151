import { Mission, Presence, Target } from '../models/app.models'
import { IndexedCollection, someEntites } from './updatetableCollection'

export interface EnrichedMission extends Mission {
    target: Target | undefined
}

export interface EnrichedPresence extends Presence {
    missions: EnrichedMission[]
}


export function orderBy(array: any[], key: string) {
    if(array === []) { return [] };
    array.sort( (a,b) => {
        if (a[key] < b[key]) return 1;
        if (a[key] > b[key]) return -1;
        return 0;
    });
    return array;
}

export function enrichPresence (targets: IndexedCollection<Target>, missions: IndexedCollection<Mission>, presence: Presence): EnrichedPresence {
    const presenceMissions: EnrichedMission[] = someEntites(missions, x => x.toolIds.includes(parseInt(presence.id as any)));
    const orderedPresenceMissions: EnrichedMission[] = orderBy(presenceMissions, 'modifiedAt')

    return {...presence, missions: orderedPresenceMissions}
}

function enrichMission ( targets: IndexedCollection<Target>, mission: Mission): EnrichedMission {
    return {...mission, target: targets[mission.target_id]}
}
