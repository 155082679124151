import { Target, TargetAnchor, TargetType } from '../../../models/app.models'

function transform (ctx: CanvasRenderingContext2D, target: Target) {
    ctx.translate(target.pose.x, target.pose.y);
    ctx.rotate(target.pose.orientations[0] / 1000);

   // The drawing geometry is independent from how the target is used
    // ctx.rotate(Math.PI);
    ctx.translate(-target.width / 2, -target.length);    
    let targetName;
    switch (target.anchor) {
        case TargetAnchor.Rear:
            targetName = target.name? target.name: 'R';
            ctx.save();
            ctx.fillStyle = 'rgba(255, 180, 0, 0.8)';
            ctx.font = "600px Arial black";
            ctx.fillText("R", target.width/2-target.width/12,target.length);
            ctx.restore();
            break
        case TargetAnchor.Front:
            targetName = target.name? target.name: 'F';
            ctx.save();
            ctx.fillStyle = 'rgba(255, 180, 0, 0.8)';
            ctx.font = "600px Arial black";
            ctx.fillText(targetName, target.width/2-target.width/8,target.length);
            ctx.restore()
            break
        case TargetAnchor.Middle:
            targetName = target.name? target.name: 'M';
            ctx.save();
            ctx.fillStyle = 'rgba(255, 180, 0, 0.8)';
            ctx.font = "600px Arial black";
            ctx.fillText(targetName, target.width/2-target.width/12,target.length);
            ctx.restore()
            break
        default:
            targetName = target.name? target.name: 'P';
            ctx.save();
            ctx.fillStyle = 'rgba(255, 180, 0, 0.8)';
            ctx.font = "600px Arial black";
            ctx.fillText(targetName, target.width/2-target.width/8,target.length);
            ctx.restore();
            break

    }

}

function renderGate (ctx: CanvasRenderingContext2D, target: Target, asClickTarget: boolean) {
    const gateWallDimensions = {w: 3000, l: 800}
    ctx.save()
    transform(ctx, target)
    ctx.rect(0, 0, target.width, target.length)
    // ctx.beginPath()
    // ctx.moveTo(0, 0)
    // ctx.lineTo(0, target.length)
    // ctx.lineTo(target.width, target.length)
    // ctx.lineTo(target.width, 0)

    const startLineWidth = ctx.lineWidth
    ctx.lineWidth = startLineWidth * 1.5
    if (!asClickTarget) {
        ctx.strokeStyle = 'rgba(255,255,255,0.5)'
    }
    ctx.stroke()
    ctx.lineWidth = startLineWidth
    if (!asClickTarget) {
        ctx.strokeStyle = 'rgba(0,180,20,0.5)'
    }
    ctx.stroke()
    ctx.fill()
    ctx.beginPath()
    ctx.lineWidth = startLineWidth / 2
    if (!asClickTarget) {
        ctx.fillStyle = 'rgba(0,0,0,0.7)'
        ctx.strokeStyle = 'rgba(255,255,255,0.5)'
    }

    switch (target.anchor) {
        case TargetAnchor.Rear:
            ctx.fillStyle = 'rgba(0,50,0,0.7)'
            ctx.strokeStyle = 'rgba(0,255,0,0.5)'
            break
        case TargetAnchor.Front:
            ctx.fillStyle = 'rgba(0,0,0,0.7)'
            ctx.strokeStyle = 'rgba(255,255,255,0.5)'
            break
     }

    ctx.rect((target.width - gateWallDimensions.w) / 2, target.length + gateWallDimensions.l, gateWallDimensions.w, -gateWallDimensions.l)
    ctx.fill()
    ctx.stroke()
    ctx.lineWidth = startLineWidth
    ctx.restore()
}

function renderEntrance (ctx: CanvasRenderingContext2D, target: Target, asClickTarget: boolean) {
    if (!asClickTarget) {
        ctx.strokeStyle = 'rgba(0, 255,123,0.5)'
    }
    ctx.save()
    transform(ctx, target)
    ctx.rect(0, 0, target.width, target.length)
    ctx.stroke()
    ctx.fill()
    ctx.restore()
}

function renderExit (ctx: CanvasRenderingContext2D, target: Target, asClickTarget: boolean) {
    if (!asClickTarget) {
        ctx.strokeStyle = 'rgba(255, 0, 0, 0.5)'
    }
    ctx.save()
    transform(ctx, target)
    ctx.rect(0, 0, target.width, target.length)
    ctx.stroke()
    ctx.fill()
    ctx.restore()
}

function renderParking (ctx: CanvasRenderingContext2D, target: Target, asClickTarget: boolean) {
    if (!asClickTarget) {
        ctx.strokeStyle = 'rgba(255, 180, 0, 0.5)'
    }
    ctx.save()
    transform(ctx, target)
    // ctx.rect(0, 0, target.width, target.length)
    ctx.arc(target.width/2,target.length,target.width/2.5,0, 2*Math.PI)
    ctx.stroke()
    ctx.fill()

    ctx.restore()
}

export function renderTarget (ctx: CanvasRenderingContext2D, target: Target, mmPerPx: number, clickableColor?: string) {
    ctx.beginPath()
    ctx.lineWidth = 3 * mmPerPx
    if (clickableColor) {
        ctx.fillStyle = clickableColor
        ctx.strokeStyle = ctx.fillStyle
    } else {
        ctx.fillStyle = 'rgba(0,0,0,0.01)'
    }
    switch (target.type) {
        case TargetType.Gate:
            renderGate(ctx, target, !!clickableColor)
            break
        case TargetType.Entrance:
            renderEntrance(ctx, target, !!clickableColor)
            break
        case TargetType.Exit:
            renderExit(ctx, target, !!clickableColor)
            break
        case TargetType.Free:
            renderParking(ctx, target, !!clickableColor)
            break
        case TargetType.Parking:
            renderParking(ctx, target, !!clickableColor)
            break

    }
}
