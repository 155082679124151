import * as React from 'react'

interface Props {
    position: 'topLeft' | 'topRight' | 'bottomRight' | 'bottomLeft'
    style?: React.CSSProperties
}

export class MapControlPanel extends React.PureComponent<Props> {
    render () {
        const style: React.CSSProperties = {
            position: 'absolute',
            zIndex: 900,
            ...(this.props.style || {}),
        }

        switch (this.props.position) {
            case 'topLeft':
                style.margin = '1rem 0 0 1rem'
                style.top = 0
                style.left = 0
                break
            case 'topRight':
                style.margin = '1rem 1rem 0 0'
                style.top = 0
                style.right = 0
                break
            case 'bottomRight':
                style.margin = '0 1rem 1rem 0'
                style.right = 0
                style.bottom = 0
                break
            case 'bottomLeft':
                style.margin = '0 0rem 1rem 1rem'
                style.left = 0
                style.bottom = 0
                break
        }

        return (
            <div style={style}>
                {this.props.children}
            </div>
        )
    }
}
