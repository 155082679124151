import * as _L from 'leaflet'

import { Polygon, PolygonPoints } from '../types'

import * as mapPolygons from './mapPolygons'

/**
 * Wrap canvas and polygon overlays to be able to redraw, modify, toggle visibility and z-order them.
 */
export class LeafletPolygonOverlay {
    static createPolygonOverlay (map: _L.Map, zIndex: number) {
        return new LeafletPolygonOverlay({
            map,
            polygonLayer: _L.layerGroup([]),
            zIndex,
        })
    }

    // custom map event to signal that a polygon on another polygon overlay is
    // being edited
    private CUSTOM_OVERLAY_EDIT_END_EVENT = 'customOverlayEditEnd'

    private _isVisible: boolean
    private _map: _L.Map
    private _polygons: Polygon[]
    private _polygonLayerGroup: _L.LayerGroup
    private _polygonLayerEditing?: any
    private _polygonLayerEditingIndex?: number
    private _polygonLayerEditingTime?: number
    private _zIndex: number
    private _renderer?: _L.Renderer

    // events
    private _onEdit?: (p: PolygonPoints, index: number) => void
    private _onSelect?: (i: number | undefined) => void

    constructor (options: {
        map: _L.Map
        polygonLayer: _L.LayerGroup
        zIndex: number
    }) {
        this._isVisible = false
        this._polygons = []

        this._map = options.map
        this._polygonLayerGroup = options.polygonLayer
        this._zIndex = options.zIndex

        this._renderer = _L.svg()
        this._renderer.on('add', () => {
            (this._renderer as any)._container.style.zIndex = this._zIndex
        })

        this._setupEditListeners()
    }

    /**
     * Toggle the visibility of this overlay
     */
    setVisibility (visible: boolean) {
        if (visible) {
            this._polygonLayerGroup.addTo(this._map)
            this._isVisible = true
        } else {
            this._isVisible = false
            this._polygonLayerGroup.removeFrom(this._map)
        }

        return this
    }

    /**
     * Update the polygons of this layer
     *
     * (use LeafletMap.drawPolygon for drawing a polygon)
     */
    setPolygons (polygons: Polygon[], onClickCallback: (id)=>void = null, reloadShapes=false) {
        const prevPolygons = this._polygons

        // if (prevPolygons === polygons) {
        //     return
        // }


        const group = this._polygonLayerGroup
        if (reloadShapes) {
                group.clearLayers();
        }
        const layers: _L.Polygon[] = group.getLayers() as any

        // detect whether a setPolygon call was just because we changed a
        // single point and don't have to disable editing
        const onlyModified = prevPolygons.length === polygons.length
        let onlyOnePointAddedToaSinglePolygon: boolean | undefined = onlyModified
            ? undefined // need to check if only one point was modified
            : false // nah, polygons have been added/removed -> more than one point of a single poly was affected

        // update existing polygons
        polygons.forEach((p, i) => {
            p.points = p.points.filter( point => !(Number.isNaN(point[0]) || Number.isNaN(point[1])));

            if (p === prevPolygons[i]) {
                return
            }

            const style = {
                // when clicking to edit the polygon, do not bubble the event
                // to the map which would trigger a deselect of the clicked
                // polygon
                bubblingMouseEvents: false,

                className: p.className,
                renderer: this._renderer,
            }

            if (i < layers.length) {
                // update an existing leaflet polygon layer
                layers[i].setLatLngs([...p.points])

                 // does not update the className property, as className is
                 // specific to the SVG renderer (and does not work in the
                 // canvas renderer)
                layers[i].setStyle(style)

                // ... thus, update the className manually
                if (prevPolygons[i].className !== p.className) {
                    const path = layers[i].getElement() as HTMLElement

                    if (path) {
                        _L.DomUtil.removeClass(path, prevPolygons[i].className)
                        _L.DomUtil.addClass(path, p.className)
                    }
                }

                if (onlyOnePointAddedToaSinglePolygon === undefined) {
                    onlyOnePointAddedToaSinglePolygon = mapPolygons.hasOnePointBeenAdded(prevPolygons[i].points, p.points)
                }
            } else {
                // create a new leaflet polygon layer
                const pl = _L.polygon([...p.points], style)

                // setup click-to-edit
                pl.on('click', (e: any) => {
                    const o: MouseEvent = e.originalEvent
                    const res = this._choosePolygonLayer(o.clientX, o.clientY)

                    if (res) {
                        this._editStart(res.layer, res.index)
                    } else {
                        this._editStart(pl, i)
                    }
                    if (p.shapeId){
                        onClickCallback(p.shapeId)
                    }

                })
                pl.on('pm:edit', () => {
                    const index = this._polygonLayerEditingIndex
                    const polygonLayer = this._polygonLayerEditing

                    if (!polygonLayer || index === undefined) {
                        return
                    }

                    if (!this._onEdit) {
                        return
                    }

                    const polygonPoints = this._getPolygonPoints(polygonLayer)

                    this._onEdit(polygonPoints, index)

                    if (!polygonPoints.length) {
                        // not a polygon any more -> delete & quit
                        this._editEnd()
                        return
                    }

                    this._polygonLayerEditingTime = Date.now()
                })

                group.addLayer(pl)
            }
        })

        // remove deleted polygon layers
        layers.slice(polygons.length).forEach(l => group.removeLayer(l))

        this._polygons = polygons
        // this._polygons = JSON.parse(JSON.stringify(polygons))

        // retrigger editing, as the previously selected polygon may have been deleted
        // but only if we are not in the middle of adding & dragging a single point with the mouse
        if (this._polygonLayerEditingIndex !== undefined && !onlyOnePointAddedToaSinglePolygon) {
            const editingLayerIndex = this._polygonLayerEditingIndex
            const editingLayerAfterUpdate = this._polygonLayerGroup.getLayers()[editingLayerIndex]

            this._editEnd()

            if (editingLayerAfterUpdate) {
                this._editStart(editingLayerAfterUpdate, editingLayerIndex)
            }
        }
    }

    /**
     * Set the edit callback for this polygon overlay and make polygons clickable.
     *
     * When onEdit is defined, disable event bubbling for polygon click/touch
     * events on this overlay (we need this to correctly identify clicks
     * outside the selected polygon to deselect it).
     * That means clicking into a polygon when onEdit is active does not
     * trigger map.on('click') events which other plugins may rely on.
     */
        setEditable (onEdit: undefined | ((p: PolygonPoints, index: number) => void)) {
        this._onEdit = onEdit
        this._polygonLayerGroup.getLayers().forEach(l => {
            // activate event bubbling when not editing - need this for
            // leaflet.polylinemeasure to work properly.
            (l as _L.Polygon).setStyle({bubblingMouseEvents: !onEdit} as any)
        })
    }

    /**
     * Set the onSelect handler.
     */
        setOnSelect (onSelect: undefined | ((i: number | undefined) => void)) {
        this._onSelect = onSelect
    }

    /**
     * Deselect the currently selected polygon (if any).
     */
        editEnd () {
        this._editEnd()
    }

    /* polygon editing with leaflet.pm */

        _getPolygonPoints (polygonLayer: _L.Polygon) {
        const polygonPoints = mapPolygons.getPolygonPointsFromLayer(polygonLayer)

        if (polygonPoints.length < 3) {
            return [] // its not a polygon anymore -> gone
        }

        return polygonPoints
    }

        _togglePolygonElementSelectedClass (add: boolean) {
        if (!this._polygonLayerEditing) {
            return
        }

        const polygonElement = this._polygonLayerEditing.getElement() as HTMLElement

        if (!polygonElement) {
            return
        }

        const selectClassName = 'LeafletPolygonOverlay__selectedPolygon'

        if (add) {
            _L.DomUtil.addClass(polygonElement, selectClassName)
        } else {
            _L.DomUtil.removeClass(polygonElement, selectClassName)
        }
    }

        _setupEditListeners () {
        // edit listener setup
        this._map.on('click', this._editEnd)
        this._map.on(this.CUSTOM_OVERLAY_EDIT_END_EVENT, () => {this._editEnd()}) // custom event to stop editing when another poly layer is added

        this._map.on('unload', () => {
            this._map.off('click', this._editEnd)
            this._map.off(this.CUSTOM_OVERLAY_EDIT_END_EVENT, this._editEnd)
        })
    }

    // deselect the polygon
        _editEnd = () => {
        const index = this._polygonLayerEditingIndex
        const polygonLayer = this._polygonLayerEditing

        if (!polygonLayer || index === undefined) {
            return
        }

        // Track the time that has passed since the last 'pm:edit' event.
        // Why? Vecause when dragging and releasing a polygon marker, a map
        // click event can get fired if you're moving the mouse too fast and
        // outside of the markers bounds. To detect whether the click into the
        // map was actually meant to deselect the current polygon, check the
        // time delta, if its as low as a two single frames, it was probably
        // caused by accident and we keep the polygon selected.
        // Maybe we wont need this once Leaflet fixes the laggy marker
        // dragging (its not an issue with our setup or leaflet.pm - dragging
        // markers in plain leaflet has the same laggyness).
        const lastEditTimeDelta = Date.now() - (this._polygonLayerEditingTime || 0)

        if (lastEditTimeDelta < 32) {
            return
        }

        polygonLayer.pm.disable()
        this._togglePolygonElementSelectedClass(false)

        if (this._onSelect) {
            this._onSelect(undefined)
        }

        this._polygonLayerEditing = undefined
        this._polygonLayerEditingIndex = undefined
        this._polygonLayerEditingTime = undefined
    }

        _editStart (polygonLayer: any, i: number) {
        if (!this._onEdit) {
            return
        }

        if (this._polygonLayerEditing === polygonLayer) {
            return
        }

        this._map.fire(this.CUSTOM_OVERLAY_EDIT_END_EVENT)

        if (this._onSelect) {
            // fire the select callback *after* the unselect of any previously
            // selected polygon has been fired as
            // CUSTOM_OVERLAY_EDIT_END_EVENT on the map
            this._onSelect(i)
        }

        // start editing
        this._polygonLayerEditing = polygonLayer
        this._polygonLayerEditingIndex = i
        this._polygonLayerEditingTime = Date.now()
        this._polygonLayerEditing.pm.enable(mapPolygons.LEAFLET_PM_DRAW_OPTIONS)
        this._togglePolygonElementSelectedClass(true)
    }

    // Look through polygons above/below the current one at the point of the
    // mouse click and return the next one or nothing
        _choosePolygonLayer (clickPosX: number | undefined, clickPosY: number | undefined) {
        if (clickPosX === undefined || clickPosY === undefined) {
            return
        }

        if (!document.elementsFromPoint) {
            // tslint:disable-next-line
            console && console.error('"document.elementsFromPoint" is not supported')
        }

        if (!this._polygonLayerEditing || this._polygonLayerEditingIndex === undefined) {
            return
        }

        // I'm always astonished about the wealth of things that are possible in modern browsers ...
        // first item is the topmost element
        const elementsAtPoint = document.elementsFromPoint(clickPosX, clickPosY)

        // ignore all non-polygon layer elements
        const ourPathsAtPoint = elementsAtPoint.filter(e => e.nodeName === 'path' && (this._renderer as any)._container.contains(e))
        // there is no other polygon svg node (<path>) above or below the current position
        if (ourPathsAtPoint.length === 1) {
            return
        }

        // map the found path elements (actually a polygon depth list) to indexes into our polygons
        const layers = this._polygonLayerGroup.getLayers()
        const layerPaths = layers.map(l => (l as _L.Polygon).getElement())
        const indicesAtPoint = ourPathsAtPoint.map(e => layerPaths.indexOf(e))

        // choose the next item from the depth list ...
        const nextPathAtPointIndex = (indicesAtPoint.indexOf(this._polygonLayerEditingIndex) + 1) % indicesAtPoint.length

        // ... and map it back to a polygon index which can be passed to editStart and set as _polygonLayerEditingIndex
        const index = indicesAtPoint[nextPathAtPointIndex]
        // hey, we found another polygon below (or the topmost polygon if we
        // already were at the bottom)
        return {
            index,
            layer: layers[index],
        }
    }
}
