import { Action, ActionTypes } from './actions'
import { ApplicationEntityBase, ApplicationState, OutdatedMissions, UiControl } from './models'
import { AllEntities, applyUpdateForAllEntities, emptyCollection } from './updatetableCollection'
import * as lodash from 'lodash';
import { Yard, OverlayPosition } from '../models/app.models';
import { checkImageCenter, addLatLon, positionImage } from '../components/base/modules/geomap_calc';


function handleInvalidMissions (state: OutdatedMissions, action: Action) {
    switch (action.type) {
        case ActionTypes.ChangeEntityState:
            if (!action.changes.missions) {
                return state
            }
            let copy: OutdatedMissions | undefined
            for (const key in state) {
                if (state[key] === 'outdated') {
                    if (!copy) {
                        copy = {...state}
                    }
                    delete copy[key]
                }
            }
            return copy ? copy : state
        case ActionTypes.MarkMissionsOutdated:
            if (state[action.presenceId] !== action.outdatedType) {
                return {...state, [action.presenceId]: action.outdatedType}
            }
            break
    }
    return state
}

function handleEntities (state: AllEntities<ApplicationEntityBase>, action: Action) {
    if (action.type === ActionTypes.ChangeEntityState) {
        const updated = applyUpdateForAllEntities(state, action.changes)
        if (updated !== state) {
            return updated
        }
        return updated
    }

    if (action.type === ActionTypes.CleanEntity) {
        const updated = {...state}
        updated.nobstacles = emptyCollection();
        updated.obstacles = emptyCollection();
        return updated
    }
    return state

}



export function reducerInteface (state: ApplicationState, action: Action) {
    let pCenter;
    let activeYard = new Yard();
    switch (action.type) {
        case ActionTypes.SetYard:
            activeYard =  {...activeYard, ...lodash.cloneDeep(state.entities.yards.items[action.yardId])};
            const picturePos = new OverlayPosition();
            if (activeYard.picturePos) {
                Object.assign(picturePos, activeYard.picturePos )
                Object.assign( activeYard.picturePos, picturePos )
            } else {
                activeYard.picturePos = picturePos ;
            }

            return activeYard
            

        case ActionTypes.CenterImageOverlay:
            activeYard = {...state.activeYard};

            pCenter = checkImageCenter(activeYard.picturePos);
            if(pCenter) {
                const dLat = action.pCenter.lat - activeYard.picturePos.pCenter.lat;
                const dLon = action.pCenter.lon - activeYard.picturePos.pCenter.lon;
                addLatLon(activeYard.picturePos.p1, {lat: dLat, lon: dLon});
                addLatLon(activeYard.picturePos.p2, {lat: dLat, lon: dLon});
                addLatLon(activeYard.picturePos.p3, {lat: dLat, lon: dLon});
                addLatLon(activeYard.picturePos.p4, {lat: dLat, lon: dLon});

            } else {
                 positionImage(action.pCenter, activeYard.picturePos);
            }
            activeYard.picturePos.pCenter= action.pCenter

            return activeYard




        case ActionTypes.EditActiveYard:
            activeYard = {...state.activeYard};
            if (action.yard.picturePos) {
                Object.assign(state.activeYard.picturePos, action.yard.picturePos);
                delete action.yard.picturePos;
            }
            Object.assign(state.activeYard, action.yard);
            activeYard = {...state.activeYard};

            pCenter = checkImageCenter(activeYard.picturePos)
            if(pCenter) {
                activeYard.picturePos.pCenter= pCenter;
            } else {
                activeYard.picturePos.pCenter.lat = '' as any;
                activeYard.picturePos.pCenter.lon = '' as any;
            }
            return activeYard


        default:
            return state.activeYard;    
            break
    }
}


export function reducerIntefaceControl (state: ApplicationState, action: Action) {
    let uiControlClone: UiControl;

    switch (action.type) {

        case ActionTypes.SetFilterStatus:
            uiControlClone = {...state.uiControl};
            uiControlClone.filterStatus =  action.status;
            return uiControlClone;    

        case ActionTypes.SetPlaybackStatus:
            uiControlClone = {...state.uiControl};
            uiControlClone.playback.status =  action.status;
            return uiControlClone;    

        case ActionTypes.SetPlayTime:
            uiControlClone = {...state.uiControl};
            uiControlClone.playback.currentPlayTime =  action.time;
            return uiControlClone;   
    
        case ActionTypes.SetPlayback:
            uiControlClone = {...state.uiControl};
                uiControlClone.playback = { ...uiControlClone.playback, ...action.playback};
            return uiControlClone;    

        case ActionTypes.ReloadScheduleEvents:
            uiControlClone = {...state.uiControl};
                uiControlClone.schedule.reloadEvents = action.reload;
            return uiControlClone;

        case ActionTypes.ShowDialog:
            uiControlClone = {...state.uiControl};
                uiControlClone.showDialog = action.show;
            return uiControlClone;    

        case ActionTypes.SelectPresence:
            uiControlClone = {...state.uiControl};
                uiControlClone.selectedPresence = action.presence;
            return uiControlClone;    

        case ActionTypes.EditSelectedPresence:
            uiControlClone = {...state.uiControl};
            Object.assign(uiControlClone.selectedPresence, action.presence);
            return uiControlClone;    

        case ActionTypes.CollapseSchedule:
            uiControlClone = {...state.uiControl};
            uiControlClone.commandView.collapseSchedule = action.collapse;
            return uiControlClone;   

        case ActionTypes.SetMissionUI:
            uiControlClone = {...state.uiControl};
            let missionUI = {};
                action.missions.forEach( m => missionUI = {...missionUI, ...m.settings.autotruck_app});
                uiControlClone.commandView.missionUI = missionUI;
            return uiControlClone;   

        case ActionTypes.RequestMapUpdate:
            uiControlClone = {...state.uiControl};
                uiControlClone.lastMapUpdateRequest = new Date();
            return uiControlClone;             

        default:
            return state.uiControl;    
            break
    }
}



export function reducerAutentication (state: ApplicationState, action: Action) {
    switch (action.type) {

    case ActionTypes.SetAuthenticationStatus:
        const authentication = action.status;
        return authentication;       
    
    default:
        return state.authentication;
        break
    }
}


export function autotruckLogic (state: ApplicationState, action: Action): ApplicationState {
    const newYard = reducerInteface (state, action);
    const newUiControl = reducerIntefaceControl (state, action)
    const newEntities = handleEntities(state.entities, action)
    const newOutdated = handleInvalidMissions(state.outdatedMissions, action)
    const newAutentication = reducerAutentication(state, action)
    if ((state.entities !== newEntities) || (state.outdatedMissions !== newOutdated)|| state.activeYard !== newYard || state.uiControl !== newUiControl ||
          state.authentication !== newAutentication ) {
        return {entities: newEntities, outdatedMissions: newOutdated, activeYard: {...newYard}, uiControl: newUiControl, authentication: newAutentication}
    }
    return state
}



