import { History} from 'history'
import * as React from 'react'
import { connect } from 'react-redux'
import { Route, RouteComponentProps, Switch } from 'react-router'
import { createSelector } from 'reselect'
import { EnrichedPresence, enrichPresence } from '../../../state/enriched'
import { ApplicationState, Command } from '../../../state/models'
import { Yard } from '../../../models/app.models';

const selectedPresence: (state: ApplicationState, selected?: string) => EnrichedPresence | undefined = createSelector(
    (state: ApplicationState) => state.entities.targets.items,
    (state: ApplicationState) => state.entities.missions.items,
    (state: ApplicationState, selected: string) => selected && state.entities.presences.items[selected],
    (targets, missions, presence) => (
        presence ? enrichPresence(targets, missions, presence) : undefined
    )
)

export interface InjectedPresence {
    history: History,
    presence: EnrichedPresence | undefined,
    missionsOutdated: boolean,
    commandViewControl: Command,
    activeYard: Yard
}

const activePresence: (state: ApplicationState, op: RouteComponentProps<any>) => InjectedPresence = createSelector(
    (state: ApplicationState) => state.uiControl,
    (state: ApplicationState) => state.activeYard,
    (state: ApplicationState, op: RouteComponentProps<any>) => selectedPresence(state, op.match.params.presenceId),
    (state: ApplicationState, op: RouteComponentProps<any>) => op.match.params.presenceId && state.outdatedMissions[op.match.params.presenceId],
    (_: any, op: RouteComponentProps<any>) => op.history,
    (uiControl, activeYard, presence, outdated, history) => ({
        history,
        missionsOutdated: !!outdated,
        presence,
        commandViewControl: {...uiControl.commandView},
        activeYard,
    })
)

const connector = connect(activePresence)

export const withSelectedPresence = (component: React.SFC<InjectedPresence> | React.ComponentClass<InjectedPresence>) => {
    const connected = connector(component)
    return () => <Switch>
        <Route exact path="/p:presenceId?" component={connected}/>
        <Route component={connected} />
    </Switch>
}
