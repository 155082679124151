import * as _L from 'leaflet'

// leafletjs-canvas-overlay (https://github.com/danwild/leafletjs-canvas-overlay)
// had to patch it heavily - might as well fork it
// tslint:disable-next-line
const { canvasOverlay } = require('../../../vendor/javascript/leafletjs-canvas-overlay/L.CanvasOverlay')

export interface CanvasLayerDrawFnParameters {
    bounds: any
    canvas: HTMLCanvasElement
    size: any
    zoom: number
    zoomScale: number
}

export type CanvasLayerDrawFn = (layer: any, params: any) => void

export class LeafletCanvasOverlay {

    static createCanvasOverlay (map: _L.Map, zIndex: number, drawFn: CanvasLayerDrawFn) {
        return new LeafletCanvasOverlay({
            canvasLayer: canvasOverlay(drawFn),
            map,
            zIndex,
        })
    }

    private _canvasLayer: _L.Layer
    private _isVisible: boolean
    private _map: _L.Map
    private _zIndex: number

    constructor (options: {
        map: _L.Map
        canvasLayer: _L.Layer
        zIndex: number
    }) {
        this._canvasLayer = options.canvasLayer
        this._map = options.map
        this._zIndex = options.zIndex

        this._isVisible = false

        this._canvasLayer.on('add', () => {
            const canvas: HTMLCanvasElement = (this._canvasLayer as any).canvas()

            if (canvas) {
                canvas.style.zIndex = `${this._zIndex}`
                canvas.style.pointerEvents = 'none'
            }

            return
        })
    }

    /**
     * Toggle the visibility of this overlay
     */
    setVisibility (visible: boolean) {
        if (visible) {
            this._canvasLayer.addTo(this._map)
            this._isVisible = true
        } else {
            this._isVisible = false
            this._canvasLayer.removeFrom(this._map)
        }

        return this
    }

    remove () {
        this._canvasLayer.removeFrom(this._map)
    }

    getPixelColor (x: number, y: number) {
        const canvas: HTMLCanvasElement = (this._canvasLayer as any).canvas()

        if (canvas) {
            const ctx = canvas.getContext('2d') as CanvasRenderingContext2D
            return ctx.getImageData(x, y, 1, 1).data
        }
    }

    getCanvasID () {
        const canvas: HTMLCanvasElement = (this._canvasLayer as any).canvas()
        return canvas.id
    }

    /**
     * Force a redraw (e.g. to drive an animation)
     */
    redraw () {
        if (!this._isVisible) {
            return this
        }

        (this._canvasLayer as any).redraw()

        return this
    }
}
