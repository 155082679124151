import * as React from 'react'
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { createSelector } from 'reselect'
import { Props as DropdownProps, StatelessDropdown, TitleStyle } from '../../base/components/StatelessDropdown'
import { Presence } from '../../../models/app.models'
import { ApplicationState } from '../../../state/models'
import { allEntites } from '../../../state/updatetableCollection'
import { InjectedPresence, withSelectedPresence } from '../modules/with-presence'

const possiblePresences: (state: ApplicationState, selected: InjectedPresence) => DropdownProps<Presence>  = createSelector(
    (state: ApplicationState) => state.entities.presences,
    (state: ApplicationState, selected: InjectedPresence) => selected.presence,
    (state: ApplicationState, selected: InjectedPresence) => selected.history,
    (presences, presence, history) => ({
        items: allEntites(presences.items),
        keyGenerator: (item: Presence, index: number) => item.id,
        onSelect: (presence: Presence) => history.push('/p' + presence.id),
        placeholder: <NavLink to='/p'>Vehicles</NavLink>,
        render: (item: Presence) => <NavLink className="item" to={'/p' + item.id}>{item.main_licence_plate}</NavLink>,
        selectedItem: allEntites(presences.items).find(item => !!(presence && item.id === presence.id)),
        style: 'placeholder' as TitleStyle,
    })
)

const Connected = connect<DropdownProps<Presence>, {}, InjectedPresence>(possiblePresences)(StatelessDropdown)
export const PresenceDropdown = withSelectedPresence(p => <Connected {...p}/> )
