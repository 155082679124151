import * as React from 'react'

/**
 * A simple placeholder component
 */
interface Props {
    className?: string
    style?: React.CSSProperties
    label?: string
    labelSize?: string,
    labelPositionX?: 'left' | 'center' | 'right'
    labelPositionY?: 'top' | 'center' | 'bottom'
}

export class MockUpBox extends React.PureComponent<Props, {}> {
    render () {
        let justifyContent: 'flex-start' | 'center' | 'flex-end'
        switch (this.props.labelPositionX) {
            case 'left':
                justifyContent = 'flex-start'
                break
            case 'center':
                justifyContent = 'center'
                break
            default:
                justifyContent = 'flex-end'
        }

        let alignItems: 'flex-start' | 'center' | 'flex-end'
        switch (this.props.labelPositionY) {
            case 'top':
                alignItems = 'flex-start'
                break
            case 'center':
                alignItems = 'center'
                break
            default:
                alignItems = 'flex-end'
        }

        const labelSize = this.props.labelSize || '1rem'
        const style = Object.assign({minHeight: '3rem', width: '100%'}, this.props.style || {}, {position: 'relative'})

        return (
            <div style={style} className={this.props.className}>
                <svg version="1.1" viewBox="0 0 100 100" preserveAspectRatio="none" style={{position: 'absolute', width: '100%', height: '100%'}}>
                    <line vectorEffect="non-scaling-stroke" fill="#888" stroke="#ddd" strokeWidth="3" x1="0" y1="0" x2="100" y2="100"/>
                    <line vectorEffect="non-scaling-stroke" fill="#888" stroke="#ddd" strokeWidth="3" x1="0" y1="100" x2="100" y2="0"/>
                </svg>
                <div style={{position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, display: 'flex', justifyContent, alignItems, border: '3px solid #ddd'}}>
                    <div style={{backgroundColor: '#fff', color: '#888', fontSize: labelSize, padding: `calc(${labelSize}/4)`}}>
                        {this.props.label}
                    </div>
                </div>
            </div>
        )
    }
}
