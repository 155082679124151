import * as React from 'react'

import {
    Switch,
} from './Switch'

import {
    LayerDescription,
    LayerName,
} from '../modules/leafletMap'

export interface OverlayLegend {
    type: 'square'
    fillStyle: string
    strokeStyle: string
}

interface Props {
    baseLayerOpacity: number
    enabledOverlays: boolean[]
    layers: LayerDescription[]
    onBaseLayerOpacityChange: (v: number) => void
    onCanvasClick: (i: number) => void
    onClick: (n: LayerName) => void
    overlays: Array<{
        label: string
        legend?: OverlayLegend
    }>
    selected: LayerName
}

export class MapLayerMenu extends React.PureComponent<Props> {

    render () {
        const props = this.props

        const baseTileLayers = props.layers.map(l => {
            return (
                <div key={l.name} className="u-flex u-margin-bottom-small">
                    <Switch className="tinier u-margin-none"
                            isOn={l.name === props.selected}
                            onClick={() => props.onClick(l.name)}
                    />
                    <label className="u-padding-left-small u-cursor-pointer"
                           onClick={() => props.onClick(l.name)}>
                        {l.label}
                    </label>
                </div>
            )
        })

        const overlays = props.overlays.map((cl, i) => {
            const enableState = props.enabledOverlays[i]

            return (
                <div key={i} className="u-flex u-margin-bottom-small u-position-relative">

                    <div style={{width: '1.25rem', height: '1.25rem', marginRight: '0.5rem', opacity: enableState ? 1 : 0.2}}>
                        {cl.legend
                         ? (<div style={{height: '100%', width: '100%', borderWidth: '2px', borderStyle: 'solid', backgroundColor: cl.legend.fillStyle, borderColor: cl.legend.strokeStyle}} />)
                         : null}
                    </div>
                    <Switch className="tinier"
                            isOn={!!enableState}
                            onClick={() => props.onCanvasClick(i)}/>
                    <label className="u-padding-left-small u-cursor-pointer"
                           onClick={() => props.onCanvasClick(i)}>
                        {cl.label}
                    </label>
                </div>
            )
        })

        return (
            <div className="u-background-color-white u-margin" style={{}}>
                <div style={{marginLeft: '1.75rem'}}>
                    {baseTileLayers}
                </div>
                <div className="u-margin-top-large">{overlays}</div>
            </div>
        )
    }
}
