import { Map } from 'leaflet'
import * as React from 'react'
import { ModalDialog } from '../../base/components/ModalDialog'
import { Mission, Obstacle, Pose, Presence, Target, Yard, TargetData } from '../../../models/app.models'
import { LeafletMapComponent } from '../../maps'
import { LeafletMapComponentCanvasOverlay, LeafletMapComponentOverlayIndex, LeafletMapComponentPolygonOverlay, LeafletMapProxy } from '../../maps/components/LeafletMapComponent'
import { CanvasLayerDrawFnParameters, LeafletCanvasOverlay } from '../modules/leafletCanvasOverlay'
import { Vehicle, renderVehicle, VehicleInstance } from '../renderables/vehicle'
import { LatLonTuple, Polygon, PolygonPoints } from '../types'
import './Map.scss'
import './Obstacles.scss'
import * as lodash from 'lodash';
import { Store } from '../../base';
import { showDialog, selectPresence } from '../../../state/actions';
import { helyosService } from '../../../services/app-service'



// Obstacles #####################################
export interface ObstacleLayerProps {
    obstacles: Obstacle[],
    nobstacles: Obstacle[],
}

export interface ObstacleChangeEvent {
    updateInformation: {[index: string]: ShapeData | null},
    added: ShapeData[]
}

export interface ShapeData extends Polygon{
    isObstacle: boolean;
}

interface ObstacleEditProps {
    editableObstacles: Obstacle[],
    editableObstacleIds: string[],
    editing: boolean,
    editingObstacleShape: boolean,
    changesDeployed: boolean,
    updateObstacles: (ev: ObstacleChangeEvent) => void
    obstacleClicked: any;

}

interface ObstacleEditState extends ObstacleChangeEvent {
    shouldUpdate: boolean,
}

// Vehicles ######################################
export interface VehicleLayerProps {
    vehicles: Vehicle[],
    vehicleClicked?: (presenceId: string) => void
    selectedPresence: Presence,
}

// Targets #######################################
export interface TargetLayerProps {
    targets: Target[]
}

// Missions ######################################
export interface MissionLayerProps {
    missions: Mission[]
}

// ###############################################

interface ClickIdMapping {
    id: string,
    tag: string,
    colorInt: number,
}

export interface Props {
    yard: Yard,
    missionLayer?: MissionLayerProps,
    obstacleLayer?: ObstacleLayerProps,
    obstacleEditLayer?: ObstacleEditProps,
    targetLayer?: TargetLayerProps,
    vehicleLayer?: VehicleLayerProps
    drivableLayer?: ObstacleLayerProps,
    vehicleId?: string,
    initialZoom?: number,
    initialLayer?: string,

}

enum Layer {
    vehicle,
    obstacle,
    target,
    mission,
    drivable,
}

interface State {
    leafletMapComp: LeafletMapComponent | null,
    selectedTarget: Target | null,
    clickIdMappings: ClickIdMapping[],
    visibleLayers: Layer[]
}

interface VehicleTransitionState {
    fromPoses?: {[id: string]: Pose},
    toPoses?: {[id: string]: Pose},
    transitionStartTime?: number,
    expectedDuration?: number,
}

export class LayeredMapDrawComponent extends React.PureComponent<Props, State & ObstacleEditState & VehicleTransitionState> {

    constructor (props: Props) {
        super(props)
        this.state = {
            added: [],
            clickIdMappings: [],
            leafletMapComp: null,
            selectedTarget: null,
            selectedMapLocation: null,
            shouldUpdate: true,
            updateInformation: {},
            visibleLayers: [Layer.vehicle, Layer.obstacle, Layer.target, Layer.mission],
            ghostVehicle: null,
            orientationsGoal: 0,
            targetName: "new_parking",
            saveTarget: false,
            vehicleLatLon: [this.props.yard.origin.lat, this.props.yard.origin.lon];

        }
    }

    mmToLatLonTuple (map: Map, xy: {x: number, y: number}): number[] {
        const origin = this.props.yard.origin
        return helyosService.convertMMtoLatLng(origin.lat, origin.lon,[[xy.x, xy.y]])[0];
        // const mmPerLat = map.distance([origin.lat, origin.lon], [origin.lat + 0.1, origin.lon]) * 10000
        // const mmPerLon = map.distance([origin.lat, origin.lon], [origin.lat, origin.lon + 0.1]) * 10000
        // const resultLat = origin.lat + xy.y / mmPerLat;
        // const resultLon = origin.lon + xy.x / mmPerLon
        // return [resultLat, resultLon]
    }

    latLonTupleToMm (map: Map, latlon: LatLonTuple): number[] {
        const origin = this.props.yard.origin;
        return helyosService.convertLatLngToMM(origin.lat, origin.lon, [latlon])[0];
        // const mmPerLat = map.distance([origin.lat, origin.lon], [origin.lat + 0.1, origin.lon]) * 10000
        // const mmPerLon = map.distance([origin.lat, origin.lon], [origin.lat, origin.lon + 0.1]) * 10000
        // const resultX = (latlon[0] - origin.lat) * mmPerLat;
        // const resultY = (latlon[1] - origin.lon) * mmPerLon;
        // return [resultY, resultX];
    }

    prepareCanvas (map: LeafletMapProxy, params: CanvasLayerDrawFnParameters) {
        const canvas = params.canvas
        const ctx = canvas.getContext('2d')
        if (!ctx) {
            return {instance: null}
        }
        const mmPerPxFar = map.getMmPerPxCoord();
        const resultOrigin = map.latLngToCanvasPoint([this.props.yard.origin.lat, this.props.yard.origin.lon]);
        const currentOrigin = map.latLngToCanvasPoint([mmPerPxFar.centerLatLng.lat,  mmPerPxFar.centerLatLng.lng]);

        ctx.setTransform(1, 0, 0, 1, 0, 0)
        ctx.clearRect(-100000, -100000, 200000, 200000)
        ctx.setTransform(
            1 / mmPerPxFar.x,
            0,
            0,
            1 / mmPerPxFar.y,
            currentOrigin.x,
            currentOrigin.y,
        )
        const mapComp = this.state.leafletMapComp;
        let originOffsetMm = [0, 0];
        const origin = [ mmPerPxFar.centerLatLng.lat, mmPerPxFar.centerLatLng.lng]
        if (mapComp) originOffsetMm = this.latLonTupleToMm(mapComp.leafletMap.map, origin);

        return { instance: ctx, originOffsetMm};
    }

    getColorFromMapping (id: string | number, tag: string) {
        const colorNumber = this.state.clickIdMappings.find(mapping => mapping.tag === tag && mapping.id === id)
        if (!colorNumber) {
            // // tslint:disable-next-line:no-console
            // console.warn(tag + ' id "' + id + '" not found in mapping')
            return
        }
        const colorPadded = '00000' + colorNumber.colorInt.toString(16) // make the hex and pad it with zeros
        const colorString = '#' + colorPadded.substring(colorPadded.length - 6) // cut off the excess padding and make it a color
        return colorString
    }

    getUnusedMappingColor (currentMappings: ClickIdMapping[]) {
        for (let i = currentMappings.length; true; i++) {
            const colorNumber = i
            if (!currentMappings.find(mapping => mapping.colorInt === colorNumber)) {
                return colorNumber
            }
        }
    }


    // Vehicles ######################################
    getVehicleLayers () {
        if (this.props.vehicleLayer) {
            const vehicleLayer: LeafletMapComponentCanvasOverlay = {
                drawFn: (map, params: CanvasLayerDrawFnParameters) => this.drawVehicles(map, params),
                layerType: 'canvas',
                visible: this.layerVisible(Layer.vehicle),
            }
            const vehicleClickLayer: LeafletMapComponentCanvasOverlay = {
                drawFn: (map, params: CanvasLayerDrawFnParameters) => this.drawClickVehicles(map, params),
                layerType: 'canvas',
                visible: this.layerVisible(Layer.vehicle),
            }

            if ( this.state.ghostVehicle){
                const ghostVehicleLayer: LeafletMapComponentCanvasOverlay = {
                    drawFn: (map, params: CanvasLayerDrawFnParameters) => this.drawSingleVehicle(map, params, 
                        this.state.ghostVehicle),
                    layerType: 'canvas',
                    visible: this.layerVisible(Layer.vehicle),
                    }
                    
                return {vehicles: vehicleLayer, vehiclesClick: vehicleClickLayer, ghostvehicle:ghostVehicleLayer}

            } else {
                return {vehicles: vehicleLayer, vehiclesClick: vehicleClickLayer}
            }
            
        }
    }

    interpolate (a: number, b: number, progress: number) {
        // cubic easing function
        // progress = progress < .5 ? 4 * progress * progress * progress : (progress - 1) * (2 * progress - 2) * (2 * progress - 2) + 1
        // quad easing
        // progress = progress < .5 ? 2 * progress * progress : -1 + (4 - 2 * progress) * progress
        // sin easing
        // progress = 1 - (Math.cos(progress * Math.PI) + 1) * .5
        return a * (1 - progress) + b * progress
    }


    interpolateAngle (a: number, b: number, progress: number) {
        if (Math.abs(a-b)>1.5*Math.PI) {
            console.log("jumping angles", a,b);
            return b;
        }
        return a * (1 - progress) + b * progress
    }

    interpolateVehicle (vehicle: Vehicle, originOffsetMm=[0, 0]): Vehicle {
        if (this.state.fromPoses && this.state.fromPoses[vehicle.id]
            && this.state.toPoses && this.state.toPoses[vehicle.id]
            && this.state.transitionStartTime && this.state.expectedDuration) {
            const transVehicle = {
                id: vehicle.id,
                parts: vehicle.parts.map(part => part),
                position: {x: 0, y: 0},
            }
            const fromPose = {...this.state.fromPoses[vehicle.id]};
            const toPose = {...this.state.toPoses[vehicle.id]};
            fromPose.x += originOffsetMm[0];
            fromPose.y += originOffsetMm[1];
            toPose.x += originOffsetMm[0];
            toPose.y += originOffsetMm[1];
            const t = Math.min(1, (new Date().getTime() - this.state.transitionStartTime) / this.state.expectedDuration)

            const transPosition = {
                x: this.interpolate(fromPose.x, toPose.x, t),
                y: this.interpolate(fromPose.y, toPose.y, t),
            }
            transVehicle.position = transPosition

            for (let i = 0; i < vehicle.parts.length; i++) {
                const fromOrientation = fromPose.orientations[i] ? fromPose.orientations[i] : 0
                const toOrientation = toPose.orientations[i] ? toPose.orientations[i] : 0
                vehicle.parts[i].angle = this.interpolateAngle(fromOrientation, toOrientation, t)
            }
            return transVehicle
        }
        return {...vehicle, position: {x: -vehicle.position.x, y: -vehicle.position.y}}
    }

    drawVehicles (map: LeafletMapProxy, params: CanvasLayerDrawFnParameters): void {
        if (!this.props.vehicleLayer) {
            return
        }

        const canvas = this.prepareCanvas(map, params);
        const ctx = canvas.instance;
        if (!ctx) {
            return
        }
        const map2 = this.state.leafletMapComp;
        ctx.canvas.id = 'vehicles'
        ctx.canvas.style.zIndex = '100' // LeafletMapComponent basically draws the canvas layers in a random zIndex
        const vehicles = this.props.vehicleLayer.vehicles
        for (let i = 0; i < vehicles.length; i++) {
            const vehicle : Vehicle = vehicles[i]
            const isSelected = this.props.vehicleLayer.selectedPresence ? this.props.vehicleLayer.selectedPresence.id === vehicle.id : false
            if (vehicle.id === this.props.vehicleId) {
                if  (map2 && map2.leafletMap && map2.leafletMap.map) {
                    const position = {...vehicle.position};
                    position.x -= 2000;
                    position.y += 2000;
                    const vehicleLatLon =  this.mmToLatLonTuple(map2.leafletMap.map, position );
                    this.setState({vehicleLatLon})
                }
                
                // DONT USE INTERPORLATION
                // const xOffset = canvas.originOffsetMm[0], yOffset = canvas.originOffsetMm[1];
                // vehicle_clone = lodash.cloneDeep(vehicle); 
                // vehicle_clone.position.x = -(vehicle.position.x - xOffset);
                // vehicle_clone.position.y = -(vehicle.position.y - yOffset);
                // renderVehicle(ctx, vehicle_clone, map.getMmPerPx(), isSelected)

                const interpolatedVehicle = this.interpolateVehicle(vehicle, canvas.originOffsetMm)
                renderVehicle(ctx, interpolatedVehicle, map.getMmPerPx(), isSelected)
            }


        }
    }


    drawSingleVehicle (map: LeafletMapProxy, params: CanvasLayerDrawFnParameters, vehicle: Vehicle): void {
        if (!this.props.vehicleLayer || !vehicle) {
            return
        }

        const canvas = this.prepareCanvas(map, params);
        const ctx = canvas.instance;
        if (!ctx) {
            return
        }

        ctx.canvas.id = 'vehicles'
        ctx.canvas.style.zIndex = '100' // LeafletMapComponent basically draws the canvas layers in a random zIndex
        const interpolatedVehicle = this.interpolateVehicle(vehicle)
        renderVehicle(ctx, interpolatedVehicle, map.getMmPerPx(), false)

    }

    drawClickVehicles (map: LeafletMapProxy, params: CanvasLayerDrawFnParameters): void {
        if (!this.props.vehicleLayer) {
            return
        }

        const canvas = this.prepareCanvas(map, params);
        const ctx = canvas.instance;
        if (!ctx) {
            return
        }
        ctx.canvas.id = 'clickVehicles'
        ctx.canvas.style.visibility = 'hidden'
        const vehicles = this.props.vehicleLayer.vehicles
        for (let i = 0; i < vehicles.length; i++) {
            const color = this.getColorFromMapping(vehicles[i].id, 'vehicle')
            if (color) {
                const interpolatedVehicle = this.interpolateVehicle(vehicles[i])
                renderVehicle(ctx, interpolatedVehicle, map.getMmPerPx(), this.props.vehicleLayer.selectedPresence ? this.props.vehicleLayer.selectedPresence.id === vehicles[i].id : false, color)
            }
        }
    }

    handleVehicleClick (location: LatLonTuple): boolean {
        if (this.state.leafletMapComp && this.props.vehicleLayer && this.props.vehicleLayer.vehicleClicked) {
            const pixelpos = this.state.leafletMapComp.leafletMapProxy.latLngToCanvasPoint(location)

            const vehicleClickLayer = this.state.leafletMapComp.overlays.vehiclesClick as LeafletCanvasOverlay
            const pixelColor = vehicleClickLayer.getPixelColor(pixelpos.x, pixelpos.y)
            if (pixelColor && pixelColor[3] === 255) {
                const pixelColorInt = pixelColor[0] * 256 * 256 + pixelColor[1] * 256 + pixelColor[2]
                const mapping = this.state.clickIdMappings.find(mapping => mapping.colorInt === pixelColorInt)
                if (mapping) {
                    const vehicle = this.props.vehicleLayer.vehicles.find(vehicle => vehicle.id === mapping.id);
                    if (vehicle) {
                        Store.dispatch(showDialog(true));
                        this.props.vehicleLayer.vehicleClicked(vehicle.id as any);
                        return true
                    }
                }
            }
        }
        return false
    }

    // Targets ######################################

    handleMapPosClick (location: LatLonTuple): boolean {
        let ghostVehicle: VehicleInstance = null;
        const mapComp = this.state.leafletMapComp;
        const mmLocation = this.latLonTupleToMm(mapComp.leafletMap.map, location)
        if (this.props.vehicleLayer && this.props.vehicleLayer.selectedPresence){
            const presence = JSON.parse(JSON.stringify(this.props.vehicleLayer.selectedPresence));
            presence.pose.x = mmLocation[0];
            presence.pose.y = mmLocation[1];
            ghostVehicle = new VehicleInstance(presence);
            ghostVehicle.id = '0';
            this.setState({selectedMapLocation: mmLocation, ghostVehicle, orientationsGoal: presence.pose.orientations[0]});
        } else{
            this.setState({selectedMapLocation: mmLocation});
        }
        return true;
    }




    componentWillUpdate (nextProps: Props) {
        if (this.props.obstacleEditLayer) {
            // on deployment of changes, stop updating until the deployed data is fetched from the database
            if (!this.props.obstacleEditLayer.changesDeployed && nextProps.obstacleEditLayer && nextProps.obstacleEditLayer.changesDeployed) {
                this.setState({shouldUpdate: false})
                this.setState({added:[]})
            } else {
                if (!this.state.shouldUpdate) {
                    this.setState({added: [], updateInformation: {}, shouldUpdate: true})
                }
            }

        }

        const newClickMappings = this.state.clickIdMappings.map(mapping => mapping)
        let clickMappingAdded = false

        // make click id to color mappings
        if (nextProps.targetLayer) {
            const targets = nextProps.targetLayer.targets
            for (let t = 0; t < targets.length; t++) {
                if (!this.state.clickIdMappings.find(mapping => mapping.tag === 'target' && mapping.id === targets[t].id)) {
                    const newMapping = {id: targets[t].id, tag: 'target', colorInt: this.getUnusedMappingColor(newClickMappings)}
                    newClickMappings.push(newMapping)
                    clickMappingAdded = true
                }
            }
        }

        if (nextProps.vehicleLayer) {
            const vehicles = nextProps.vehicleLayer.vehicles
            for (let v = 0; v < vehicles.length; v++) {
                if (!this.state.clickIdMappings.find(mapping => mapping.tag === 'vehicle' && mapping.id === vehicles[v].id)) {
                    const newMapping = {id: vehicles[v].id, tag: 'vehicle', colorInt: this.getUnusedMappingColor(newClickMappings)}
                    newClickMappings.push(newMapping)
                    clickMappingAdded = true
                }
            }

            if (this.props.vehicleLayer && nextProps.vehicleLayer !== this.props.vehicleLayer) {
                // transition data
                const newPoses: {[id: string]: Pose} = {}
                const oldVehicles = this.props.vehicleLayer.vehicles
                vehicles.forEach(vehicle => {
                    if (!vehicle) {return; }
                    if (!vehicle.parts) {return; }
                    newPoses[vehicle.id] = {
                        orientations: vehicle.parts.map((part, i) => {
                            // handle angle jumps around 0
                            const oldVehicle = oldVehicles.find(oldVehicle => oldVehicle.id === vehicle.id)
                            try { // if the angle difference is bigger than half a turn
                                if (oldVehicle) {
                                    const oldAngle = oldVehicle.parts[i].angle
                                    let newAngle = part.angle
                                    
                                    while (Math.abs(oldAngle - newAngle) >= Math.PI) {
                                        if (newAngle > oldAngle) {
                                            newAngle -= Math.PI * 2
                                        } else {
                                            newAngle += Math.PI * 2
                                        }
                                    }
                                    return Math.round(newAngle * 1000) / 1000
                                }
                                
                            } catch (error) {
                                
                            }
                            return Math.round(part.angle * 1000) / 1000
                        }),
                        x: -vehicle.position.x,
                        y: -vehicle.position.y,
                    }
                })
                const now = new Date().getTime()
                // const duration = this.state.transitionStartTime ? now - this.state.transitionStartTime : 250
                const duration = 1000

                if (this.state.fromPoses && this.state.toPoses && this.state.transitionStartTime && this.state.expectedDuration) {
                    const from = this.state.fromPoses
                    const to = this.state.toPoses
                    const t = Math.min(1, (new Date().getTime() - this.state.transitionStartTime) / this.state.expectedDuration)
                    const newFromPoses: {[id: string]: Pose} = {}
                    this.props.vehicleLayer.vehicles.forEach(vehicle => {
                        if (!vehicle) { return; }
                        if (!vehicle.parts) {return; }

                        if (from[vehicle.id] && to[vehicle.id]) {

                            const a = from[vehicle.id]
                            const b = to[vehicle.id]
                            // console.log('ab ' + vehicle.id, a, b)
                            const newPose: Pose = {
                                orientations: a.orientations.map((ori, i) => this.interpolateAngle(ori, b.orientations[i], t)),
                                x: this.interpolate(a.x, b.x, t),
                                y: this.interpolate(a.y, b.y, t),
                            }
                            // console.log('newPose', newPose)
                            newFromPoses[vehicle.id] = newPose
                        } else {
                            newFromPoses[vehicle.id] = {x: -vehicle.position.x, y: -vehicle.position.y, orientations: vehicle.parts.map(part => part.angle)}
                        }
                    })
                    this.setState({fromPoses: newFromPoses, toPoses: newPoses, transitionStartTime: now, expectedDuration: duration * 1.2})
                } else {
                    this.setState({fromPoses: this.state.toPoses, toPoses: newPoses, transitionStartTime: now, expectedDuration: duration * 1.2})
                }
            }
        }

        if (clickMappingAdded) {
            this.setState({clickIdMappings: newClickMappings})
        }
    }

    componentDidUpdate () {
        // log('LayeredMap Updated')
        const map = this.state.leafletMapComp
        if (map && map.leafletMapProxy) {
            map.leafletMapProxy.redrawAllCanvasLayers()
        }

        var lc = document.getElementsByClassName('leaflet-control-layers');
        for (let index = 0; index < lc.length; index++) {
            lc[index].style.visibility = 'hidden';   
        }
    }

    componentDidMount () {
        const redrawingFunction = () => {
            const map = this.state.leafletMapComp
            if (map && map.leafletMapProxy) {
                map.leafletMapProxy.redrawCanvasLayer('vehicles')
                map.leafletMapProxy.redrawCanvasLayer('clickVehicles')
            }
            // window.setTimeout(redrawingFunction, 100)
            window.requestAnimationFrame(redrawingFunction)
        }
        redrawingFunction()
    }


    handleClick (location: LatLonTuple) {
        if (!this.handleVehicleClick(location)) {

                this.handleMapPosClick(location)
            
        }
    }


    layerVisible (layer: Layer) {
        return this.state.visibleLayers.indexOf(layer) >= 0
    }

    toggleVisibleLayer (layer: Layer) {
        const layers = this.state.visibleLayers.map(l => l)
        const index = layers.indexOf(layer)
        if (index >= 0) {
            layers.splice(index, 1)
        } else {
            layers.push(layer)
        }
        this.setState({visibleLayers: layers})
    }

    render () {
        // const coords: LatLonTuple = [this.props.yard.origin.lat, this.props.yard.origin.lon]
        const coords: LatLonTuple = this.state.vehicleLatLon;
        const overlays = { ...this.getVehicleLayers()} as LeafletMapComponentOverlayIndex
        return (
            <div className="LayeredMapDraw ">
            <LeafletMapComponent zoomStyle={{visibility:'hidden'}} initialHomeCoordinates={coords} ref={comp => {if (!this.state.leafletMapComp) {this.setState({leafletMapComp: comp})}}} onMapClick={
                (location: LatLonTuple) => {
                    this.handleClick(location)
                }} initialZoomLevel={this.props.initialZoom || 20 } initialLayer={this.props.initialLayer || "none" } overlays={overlays} />
            </div>
            )
        }
    }
