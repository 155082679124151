import { H_Yard, H_Tools, H_Shape, H_Target, H_Action } from "helyosjs-sdk";
import { H_MapObject } from "helyosjs-sdk/dist/helyos.models";
import { getEnumIndex, PresenceStatus, Obstacle, Presence,
         Target, Mission, MissionStatus, Yard, LayerType, Guideline } from "../models/app.models";


type dispatchTypes= 'created' | 'updated' | 'deleted'


export function dataToDispatchMsg(data, action:  dispatchTypes) {
    const timestamp =  new Date();
    const dispatchMessage = { timestamp };
    dispatchMessage[action] = data;
}



 class HelyosToAppModel {

    validatePoints(mmPoints) {
        let isValid = true;
        try {
            mmPoints.forEach(point =>  isValid = isValid && point.every(element => typeof element === 'number'));
            
        } catch (error) {
            console.warn('map object', error);
            isValid = false;
        }
        return isValid;

    }


    shapeConvertor(shape : H_Shape, type=null) : Obstacle {
        let obstacleData: Partial<Obstacle> = {};
        if (shape.id) {obstacleData['id'] = shape.id.toString() };
 
        if (shape.geometry) {
            obstacleData = {...obstacleData, ...shape.geometry};
        } else {
            obstacleData = {...obstacleData, ...shape.data };
        }

        if(!(this.validatePoints(obstacleData.points))){
            console.log('not valid trucktrix map object', obstacleData.id);
            obstacleData.points=[[0,10000],[10000,0]];
        }

        obstacleData['created'] = shape.createdAt;
        obstacleData['isPermanent'] = shape.isPermanent;
        obstacleData['type'] = type? type:shape.type;
        obstacleData['isObstacle'] =  obstacleData['type'] === 'obstacle';
        obstacleData['data'] = JSON.stringify(shape.metadata);
        obstacleData['yardId'] = shape.yardId;
        if (shape.isPermanent) { obstacleData['layer'] = LayerType.base }
        else { obstacleData['layer'] = LayerType.temporary }

        
        return obstacleData as Obstacle;
    }


    guidelineConvertor(mObj : H_MapObject, type=null) : Guideline {
        let guideline: Partial<Guideline> = {};
        if (mObj.id) {guideline['id'] = mObj.id.toString() };
 
        guideline = {...guideline, ...mObj.data };

        try {
            console.log('guideline num points', guideline.points.length)
            if (guideline.points.length > 100) {
                guideline.points = simplify(guideline.points, 2000);
            }
            console.log('guideline', guideline.points.length)
        } catch (error) {
            console.log('guideline undersampling', error)
        }


        if(!(this.validatePoints(guideline.points))){
            console.log('not valid trucktrix map object', guideline.id);
            guideline.points=[[0,10000],[10000,0]];
        }

        guideline['created'] = mObj.createdAt;
        guideline['type'] = type? type:mObj.type;
        guideline['data'] = JSON.stringify(mObj.metadata);
        guideline['yardId'] = mObj.yardId;
        guideline['layer'] = LayerType.base;

        
        return guideline as Guideline;
    }



    toolConvertor(tool: H_Tools, presence:any={}): Presence {
        if ( tool.id ) {presence['id'] = tool.id.toString() };
        if ( tool.uuid ) {presence['uuid'] = tool.uuid };

        if ( tool.createdAt ) { presence['created'] = tool.createdAt; }
        if ( tool.modifiedAt ) { presence['modified'] = tool.modifiedAt; }

        if ( tool.status ) {
            presence['status'] = getEnumIndex(PresenceStatus,tool['status']);
        }

        if ( tool.name ) { presence['name'] = tool.name; }
        if ( tool.connectionStatus ) { presence['connectionStatus'] = tool.connectionStatus; }
        if ( tool.name ) { presence['main_licence_plate'] = tool.name }
        if ( tool.streamUrl ) { presence['streamUrl'] = tool.streamUrl; }
        if ( tool.toolType) { presence['tool_type'] = tool.toolType; }
        if ( tool.agentClass) { presence['agentClass'] = tool.agentClass; }
        if ( tool.yardId ) { presence['yardId'] = tool.yardId; }
        if ( tool.picture ) { presence['picture'] = tool.picture; }
        if ( tool['toolPoseByToolPoseId']) {
            const toolSensors =  tool['toolPoseByToolPoseId']['sensors'];
            if (toolSensors){
                presence['pose'] = this.toolPoseToPresencePose({sensors: toolSensors});
                presence['sensors'] = this.toolPoseToPresenceSensor({sensors: tool.sensors});
                if (tool.sensors.helyos_agent_control.task_progress) {
                    presence['taskProgress'] = tool.sensors.helyos_agent_control.task_progress;
                }
            }
        } 
        if ( tool.sensors ) {
            presence['sensors'] = this.toolPoseToPresenceSensor({sensors: tool.sensors}); 
            if (tool.sensors.helyos_agent_control && tool.sensors.helyos_agent_control.task_progress) {
                presence['taskProgress'] = tool.sensors.helyos_agent_control.task_progress;
            }
        }
        if ( tool.x !== undefined ) {presence['pose'] = this.toolPoseToPresencePose(tool); }
        
        
        presence['length'] = 0;

        if (tool.geometry) {
            let geometry;

            try {
                geometry = JSON.parse(tool.geometry);
            } catch (error) {
                geometry = tool.geometry;
            }

            if (Array.isArray(geometry)) {
                geometry[0]['tool_type'] = geometry[0]['tool_type']? geometry[0]['tool_type']:tool.toolType;
                geometry[0]['reference_point'] = geometry[0]['reference_point']? geometry[0]['reference_point']:'first_axis';

                
                if (geometry.length == 2){
                    geometry[1]['tool_type'] = geometry[1]['tool_type']? geometry[1]['tool_type']:'trailer';
                }

                presence['specification'] = geometry;
            } else {
                geometry['tool_type']= geometry['tool_type']? geometry['tool_type']:tool.toolType;
                geometry['reference_point']= geometry['reference_point']? geometry['reference_point']:'first_axis';
                presence['specification'] = [geometry];
    }
        }

        return presence;
    }


    toolPoseToPresencePose(toolPose){
        const x = toolPose['x'];
        const y = toolPose['y'];
        let orientations;

        if ( toolPose['orientation']) {
            orientations = [toolPose['orientation']]
        }

        if ( toolPose['orientations']) {
            orientations = toolPose['orientations']
        }
        return { x, y, orientations };
    }

    
    toolPoseToPresenceSensor(toolPose){
        let sensors;
        try {
            sensors = JSON.parse(toolPose.sensors);
            
        } catch (error) {
            sensors = toolPose.sensors;
            
        }
        const sensorArray = [];

        if (!sensors || typeof sensors !== 'object' || Array.isArray(sensors)){
            return sensorArray;
        }

        const name_spaces = Object.keys(sensors);

        name_spaces.forEach(name => { 
        
            for (let key in sensors[name]) {
                try {
                        const item = {
                            title: sensors[name][key].title,
                            value: sensors[name][key].value,
                            type: sensors[name][key].type,
                            maximum: sensors[name][key].maximum,
                            minimum: sensors[name][key].minimum,
                            key: key,
                            unit: sensors[name][key].unit,
            
                        }
                        sensorArray.push(item);
                } catch (error) {
                    console.log(error, sensors)
                }
            }
        
        
        
        });
        
        return sensorArray;
    }



    yardConvertor(newInstance: H_Yard) : Yard {
        let mapData;
        if (newInstance.mapData) {
            try {
                mapData = JSON.parse(newInstance.mapData as string); // Graphql serialize json as string
            } catch (error) {
                mapData = newInstance.mapData;
            }
        }

        let entityData: Yard;
        entityData = {...newInstance};
        entityData.origin = mapData.origin;
        delete entityData['mapData'];

        if (newInstance.picturePos) {
            try {
                entityData['picturePos'] = JSON.parse(newInstance.picturePos as string); // Graphql serialize json as string
            } catch (error) {
                entityData['picturePos']  = newInstance.picturePos;
            }
        }

        return entityData;
    }

        
    targetConvertor(newInstance: H_Target): Target {
        let entityData = {}; 
        if (newInstance.id) { entityData['id'] = newInstance.id;}
        entityData['anchor'] = newInstance.anchor;
        entityData['type'] = newInstance.targetType;
        entityData['width'] = 4000;
        entityData['length'] = 12000;
        entityData['name'] = newInstance.targetName;
        const orientationAngle = -newInstance['orientation'] + (Math.PI * 500);
        const orientations = [orientationAngle, orientationAngle];
        const x = newInstance['x'];
        const y = newInstance['y'];
        entityData['pose'] = { x, y, orientations };
        entityData['yardId'] = newInstance.yardId;

        return entityData as Target;
    }
    

    targetObjectConvertor(newInstance: H_MapObject): Target {
        let entityData = {}; 
        const targetData = newInstance.metadata;
        const geoData = newInstance.data;
        const targetName = newInstance.name || newInstance.metadata.name;

        if(!(this.validatePoints(geoData.points))){
            console.log('not valid trucktrix map object', newInstance.id);
            geoData.points=[[0,0],[0,0]];
        }

        const orientationAngle = -targetData['orientation'] + (Math.PI * 500);
        const orientations = [orientationAngle, orientationAngle];

        entityData = new Target(newInstance.yardId,
                                targetName,
                                targetData['x'],
                                -targetData['y'],
                                orientations,
                                entityData['anchor'] = targetData.anchor,
                                targetData.targetType
                                 );
        if (newInstance.id) { entityData['id'] = newInstance.id;}

        return entityData as Target;
    }
    

    
    workProcessConvertor(newInstance): Mission {
        let entityData: Partial<Mission>= {};
        if (newInstance.id) {entityData['id'] = newInstance.id;}
        entityData['status'] =  getEnumIndex(MissionStatus,newInstance.status);
        entityData['modifiedAt']  = newInstance.modifiedAt;
        entityData['schedStartAt']  = new Date(newInstance.schedStartAt + 'Z');
        entityData['schedEndAt'] =  new Date(newInstance.schedStartAt + 'Z');
        entityData['schedEndAt'].setMinutes(entityData['schedStartAt'].getMinutes() + 1);
        entityData['name'] = newInstance.processType;
        entityData['yardId'] = newInstance.yardId;
        entityData['toolIds'] = newInstance.toolIds;
        entityData['description'] = newInstance.description;
        entityData['workType'] = newInstance.workProcessTypeName;
        // alert(newInstance.createdAt)
        if (newInstance.startedAt){
            entityData['startsAt']  = new Date(newInstance.startedAt + 'Z');
        }
        if (newInstance.endedAt){
            entityData['endsAt']  = new Date(newInstance.endedAt +'Z');
        }
        console.log('startsAt', entityData['startsAt']  )
        console.log('createdat',newInstance.createdAt)
    
        let data;
        try {
            data = JSON.parse(newInstance.data); // Graphql serialize json as string

        } catch (error) {
            data = newInstance.data;
        }

        if (data) {
            entityData['presence_id']  = data.tool_id.toString()
            if (data.target_id) {entityData['target_id'] = data.target_id.toString()};
            if (data.target_type) {entityData['target_type'] = data.target_type};
            if (data.x) {entityData['x'] = data.x};
            if (data.y) {entityData['y'] = data.y};
            if (data.orientation) {entityData['orientation'] = data.orientation};
        }
        return entityData as Mission;
        }


    actionToPathConvertor(action: H_Action) {
        console.log(action) ;
        try {
            const tasks = action.data['payload']['tasks'];
            let path = [];

            tasks.forEach( task => {
                const operations = task.payload.operations;
                for(let i=0; i<operations.length; i++){
                    try {
                        const steps = task.payload.operations[i].payload.data_payload.steps;
                        let segment = [];
                        steps.forEach( s => {
                            const pahtPoint = s.step.vehicles.map( v => ({id: v.vehicle.id, x: v.vehicle.position[0], y: v.vehicle.position[1], orientation: -v.vehicle.orientation}));
                            segment = segment.concat(pahtPoint);
                        });       
                        path = path.concat(segment);
                    } catch (error) {
                        console.log('trucktrix non path action');
                    }
                }
            });

            return path;
            
        } catch (error) {
            console.log('trucktrix paht convertor error:', error)

            try {
                const segment = action.data.map( a => ({ x: a.x, y: a.y, orientation: a.orientations[0]}));
                return segment;
                
            } catch (error) {
                console.log('trajectory paht convertor error:', error)
                
            }

        }

    }


} 


export const helyosToAppModel = new HelyosToAppModel();

/**
 * Simplifies a path object by reducing the number of points based on a given epsilon value.
 * @param points - The array of points representing the path.
 * @param epsilon - The maximum distance threshold for simplification.
 * @returns The simplified path object.
 */
export function simplifyPathObj(points, epsilon) {
    if (points.length <= 2) {
        return points;
    }


    let dMax = 0;
    let index = 0;
    const end = points.length - 1;

    for (let i = 1; i < end; i++) {
        const point0 = [points[0].x, points[0].y];
        const pointi = [points[i].x, points[i].y];
        const pointend = [points[end].x, points[end].y];
        const d = perpendicularDistance(pointi, point0, pointend);
        if (d > dMax) {
            index = i;
            dMax = d;
        }
    }

    if (dMax > epsilon) {
        const results1 = simplifyPathObj(points.slice(0, index + 1), epsilon);
        const results2 = simplifyPathObj(points.slice(index), epsilon);
        results1.pop(); // Remove the duplicate point
        return results1.concat(results2);
    } else {
        return [points[0], points[end]];
    }
}


export function simplify(points, epsilon) {
    if (points.length <= 2) {
        return points;
    }

    let dMax = 0;
    let index = 0;
    const end = points.length - 1;

    for (let i = 1; i < end; i++) {
        const d = perpendicularDistance(points[i], points[0], points[end]);
        if (d > dMax) {
            index = i;
            dMax = d;
        }
    }

    if (dMax > epsilon) {
        const results1 = simplify(points.slice(0, index + 1), epsilon);
        const results2 = simplify(points.slice(index), epsilon);
        results1.pop(); // Remove the duplicate point
        return results1.concat(results2);
    } else {
        return [points[0], points[end]];
    }
}

function perpendicularDistance(point, start, end) {
    const [px, py] = point;
    const [sx, sy] = start;
    const [ex, ey] = end;
    const numerator = Math.abs((ex - sx) * (sy - py) - (sx - px) * (ey - sy));
    const denominator = Math.sqrt(Math.pow(ex - sx, 2) + Math.pow(ey - sy, 2));
    return numerator / denominator;
}

// Sample input list of [x, y] points
// const inputPoints = [[0, 0], [1, 1], [2, 2], [3, 3], [4, 4], [5, 5]];

// // Define the maximum tolerance for simplification
// const epsilon = 0.5;

// // Simplify the curve
// const simplifiedPoints = simplify(inputPoints, epsilon);

// console.log("Original Points:", inputPoints);
// console.log("Simplified Points:", simplifiedPoints);
