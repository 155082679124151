import * as _L from 'leaflet'

import { LatLonTuple } from '../types'

export interface Marker { // TODO: move into leaflet types
    className?: string
    draggable?: boolean
    position: LatLonTuple
    title?: string
}

export class LeafletMarkerOverlay {
    static createMarkerOverlay (map: _L.Map, zIndex: number) {
        return new LeafletMarkerOverlay({
            map,
            zIndex,
        })
    }

    private _isVisible: boolean
    private _map: _L.Map
    private _markerLayerGroup: _L.LayerGroup
    private _markers: Marker[]
    private _zIndex: number
    private _onDrag?: (index: number, pos: LatLonTuple) => void
    private _onClick?: (index: number) => void

    private constructor (options: {
        map: _L.Map
        zIndex: number
    }) {
        this._map = options.map
        this._zIndex = options.zIndex // TODO: what todo with z-indexes ...

        this._isVisible = false
        this._markerLayerGroup = _L.layerGroup([])
        this._markers = []
    }

    setVisibility (visible: boolean) {
        if (visible) {
            this._markerLayerGroup.addTo(this._map)
            this._isVisible = true
        } else {
            this._isVisible = false
            this._markerLayerGroup.removeFrom(this._map)
        }
    }

    setOnDrag (onDrag: undefined | ((index: number, pos: LatLonTuple) => void)) {
        this._onDrag = onDrag
    }

    setOnClick (onClick: undefined | ((index: number) => void)) {
        this._onClick = onClick
    }

    /**
     * add/remove/modify markers on this pane in order to move elements on that pane
     */
    setMarkers (markers: Marker[]) {
        const leafletMarkers = (this._markerLayerGroup.getLayers() as _L.Marker[])

        markers.forEach((m, i) => {
            // add
            if (!this._markers[i]) {
                const newMarker = _L.marker(m.position, {
                    draggable: !!m.draggable,
                    icon: _L.divIcon({className: `${m.className || ''}`}),
                    title: m.title,
                })

                newMarker.on('drag', (ev: any) => {
                    if (!this._onDrag) {
                        return
                    }

                    const lat = ev.latlng.lat
                    const lng = ev.latlng.lng

                    // do not fire the event if position has not changed
                    if (lat === this._markers[i].position[0] && lng === this._markers[i].position[1]) {
                        return
                    }

                    this._onDrag(i, [lat, lng])
                })

                newMarker.on('click', () => {
                    if (!this._onClick) {
                        return
                    }

                    this._onClick(i)
                })

                this._markerLayerGroup.addLayer(newMarker)
                newMarker.openPopup()

                return
            }

            // not modified
            if (m === this._markers[i]) {
                return
            }

            // always update the position to be sure to reset any applied drag movement
            leafletMarkers[i].setLatLng(m.position)

            // update icon classname
            if (this._markers[i].className !== m.className) {
                leafletMarkers[i].setIcon(_L.divIcon({className: `${m.className || ''}`}))
            }
        })

        // delete
        leafletMarkers.slice(markers.length).forEach(lm => {
            this._markerLayerGroup.removeLayer(lm)
        })

        this._markers = markers
    }
}
