import * as React from 'react'
import { FileBase64 } from '../../base/components/React-file-base64';
import { Yard } from '../../../models/app.models';
import { calculatePValues } from '../../base/modules/geomap_calc';


export interface Props {
    yard?: Yard
    change?: (ev:any) => void
    getFiles?: (files:any) => void
    changeCenter?: (ev: any) => void
    centerMapInYard?: (ev: any) => void
    updatePs: (ev: any) => void
    reloadMap?: (ev: any) => void
    style?: React.CSSProperties
}

export class OverlayImageMap extends React.PureComponent<Props, {}> {



        calcPValues(){
                
                const pValues =   calculatePValues(this.props.yard.picturePos.Ref,
                        parseFloat(this.props.yard.picturePos.RA),
                        parseFloat(this.props.yard.picturePos.RB),
                        parseFloat(this.props.yard.picturePos.RC),
                        parseFloat(this.props.yard.picturePos.RD),
                        parseFloat(this.props.yard.picturePos.rotation));
                        
                this.props.updatePs(pValues);

        }



    render () {

        if(!this.props.yard.picturePos.Ref) this.props.yard.picturePos.Ref={};
        return (
        <div id="OverlayMapInputs">
            <img src={this.props.yard.picture}></img>
            <FileBase64
                    multiple={ false }
                    onDone={ this.props.getFiles } />


            <img width={200} style={{padding:4}} src="src/assets/images/overlaycenter.png"></img>


            <div style={{display:'flex', alignItems:'center'}}>      
                <div style={{minWidth:30}}> P1:  </div>          
                <input  name="p1.lat" type="number" placeholder="latitude" 
                        value={this.props.yard.picturePos.p1.lat}  onChange={this.props.change}/>

                <input  name="p1.lon" type="number" placeholder="longitude" 
                        value={this.props.yard.picturePos.p1.lon}  onChange={this.props.change}/>
            </div>

            <div style={{display:'flex', alignItems:'center'}}>    
                <div style={{minWidth:30}}> P2:  </div>         
                <input  name="p2.lat"type="number" placeholder="latitude" 
                        value={this.props.yard.picturePos.p2.lat}  onChange={this.props.change}/>

                <input  name="p2.lon"type="number" placeholder="longitude" 
                        value={this.props.yard.picturePos.p2.lon}  onChange={this.props.change}/>
            </div>

            <div style={{display:'flex', alignItems:'center'}}>    
                <div style={{minWidth:30}}> P3:  </div>         
                <input  name="p3.lat"type="number" placeholder="latitude" 
                        value={this.props.yard.picturePos.p3.lat}  onChange={this.props.change}/>

                <input  name="p3.lon"type="number" placeholder="longitude" 
                        value={this.props.yard.picturePos.p3.lon}  onChange={this.props.change}/>
            </div>

            <div style={{display:'flex', alignItems:'center'}}>    
                <div style={{minWidth:30}}>  P4:  </div>         
                <input  name="p4.lat"type="number" placeholder="latitude" 
                        value={this.props.yard.picturePos.p4.lat}  onChange={this.props.change}/>

                <input  name="p4.lon"type="number" placeholder="longitude" 
                        value={this.props.yard.picturePos.p4.lon}  onChange={this.props.change}/>
            </div>

            <div style={{display:'flex', alignItems:'center'}}>    
                <div style={{minWidth:30}}> C:  </div>         
                <input  name="pCenter.lat"type="number" placeholder="latitude" 
                        value={this.props.yard.picturePos.pCenter.lat}  onChange={this.props.changeCenter}/>

                <input  name="pCenter.lon"type="number" placeholder="longitude" 
                        value={this.props.yard.picturePos.pCenter.lon}  onChange={this.props.changeCenter}/>
            </div>

            <div style={{display:'flex', alignItems:'center'}}>  
                <button className="button" onClick={this.props.centerMapInYard}>C = Yard Center </button>
                <button className="button" onClick={this.props.reloadMap}> &#8635; Redraw </button>
            </div>


               {/* PARAMETERS FOR CALCULATION  */}


            <img width={200} style={{padding:4}} src="src/assets/images/overlayref.png"></img>
            <div style={{display:'flex', alignItems:'center'}}>      
                <div style={{minWidth:30}}> R:  </div>          
                <input  name="Ref.lat"type="number" placeholder="latitude" 
                        value={this.props.yard.picturePos.Ref.lat}  onChange={this.props.change}/>

                <input  name="Ref.lon"type="number" placeholder="longitude" 
                        value={this.props.yard.picturePos.Ref.lon}  onChange={this.props.change}/>
            </div>

            <div style={{display:'flex', alignItems:'center'}}>    
                <div style={{minWidth:30}}> RA:  </div>         
                <input  name="RA"type="number" placeholder="km" 
                        value={this.props.yard.picturePos.RA}  onChange={this.props.change}/>
                <div style={{minWidth:30}}> RB:  </div>         
                <input  name="RB"type="number" placeholder="km" 
                        value={this.props.yard.picturePos.RB}  onChange={this.props.change}/>
            </div>

            <div style={{display:'flex', alignItems:'center'}}>    
                <div style={{minWidth:30}}> RC:  </div>         
                <input  name="RC"type="number" placeholder="km" 
                        value={this.props.yard.picturePos.RC}  onChange={this.props.change}/>
                <div style={{minWidth:30}}> RD:  </div>         
                <input  name="RD"type="number" placeholder="km" 
                        value={this.props.yard.picturePos.RD}  onChange={this.props.change}/>
            </div>

            <div style={{display:'flex', alignItems:'center'}}>    
                <div style={{minWidth:45}}>  Angle:  </div>         
                <input  name="rotation"type="number" placeholder="degrees" 
                        value={this.props.yard.picturePos.rotation}  onChange={this.props.change}/>

            </div>

            <button className="button" onClick={this.calcPValues.bind(this)}>
                                           Calculate P1,P2,P3,P4
             </button>

                <span> Markers: </span>
             <div style={{display:'flex', alignItems:'center'}}>      
                <div style={{minWidth:30}}> M1:  </div>          
                <input  name="m1.lat" type="number" placeholder="latitude" 
                        value={this.props.yard.picturePos.m1.lat}  onChange={this.props.change}/>

                <input  name="m1.lon" type="number" placeholder="longitude" 
                        value={this.props.yard.picturePos.m1.lon}  onChange={this.props.change}/>
            </div>

            <div style={{display:'flex', alignItems:'center'}}>    
                <div style={{minWidth:30}}> M2:  </div>         
                <input  name="m2.lat"type="number" placeholder="latitude" 
                        value={this.props.yard.picturePos.m2.lat}  onChange={this.props.change}/>

                <input  name="m2.lon"type="number" placeholder="longitude" 
                        value={this.props.yard.picturePos.m2.lon}  onChange={this.props.change}/>
            </div>

            <div style={{display:'flex', alignItems:'center'}}>    
                <div style={{minWidth:30}}> M3:  </div>         
                <input  name="m3.lat"type="number" placeholder="latitude" 
                        value={this.props.yard.picturePos.m3.lat}  onChange={this.props.change}/>

                <input  name="m3.lon"type="number" placeholder="longitude" 
                        value={this.props.yard.picturePos.m3.lon}  onChange={this.props.change}/>
            </div>


</div>

        )
    }
}




// <button
// type="button"
// className={`button ${this.props.className}`}
// onClick={this.props.onClick}
// style={this.props.style}
// disabled={this.props.disabled}
// title={this.props.title}>
// {this.props.children}
// </button>