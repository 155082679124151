import * as React from 'react'
import { connect } from 'react-redux'
import { createSelector } from 'reselect'
import { LayerType, Obstacle, Yard } from '../../../models/app.models'
import { ApplicationState } from '../../../state/models'
import { createObstacle, removeObstacle, editYard, helyosService, removeAllShapes, createManyShapes, createMapWorkProcess, reloadAllShapesInStore, listShapesByYard } from '../../../services/app-service'
import { allEntites, entityOfYard } from '../../../state/updatetableCollection'
import { LayeredMapComponent, ObstacleChangeEvent, Props as MapProps, ShapeData, VehicleLayerProps } from '../../maps/components/LayeredMapComponent'
import { Polygon } from '../../maps/types'
import '../components/ObstacleBar.scss'
import './ObstacleView.scss'
import Toggle from 'react-toggle'
import * as lodash from 'lodash';

import { Vehicle } from '../../maps/renderables/vehicle';
import { FileBase64 } from '../../base/components/React-file-base64';
import { Store, Button } from '../../base';
import { editActiveYard, requestMapUpdate, setYard, centerImageOverlay } from '../../../state/actions';
import { OverlayImageMap } from '../../command/components/OverlayMap';
import { ShapePropForm } from '../../command/components/ShapPropForm'
import { download_object } from '../../base/tools_lib'
import * as Swal from 'sweetalert2';
import { helyosToAppModel } from '../../../services/helyos-app-model-convertors'
import { appToHelyosModel } from '../../../models/app-helyos-model-convertors'

export enum ObstacleType {
    // noinspection JSUnusedGlobalSymbols
    Polygon = 'Polygon',
    Rectangle = 'Rectangle',
    Circle = 'Circle',
}

interface State {
    editing: boolean,
    changesDeployed: boolean,
    changes: { [index: string]: ShapeData | null },
    additions: ShapeData[],
    expertMode: boolean,
    selectedShapeId: number,
}

interface Editing {
    editing: boolean,
    editingObstacleShape: boolean,
    changesDeployed: boolean,
    updateObstacles: (event: ObstacleChangeEvent) => void
    selectObstacle: (shapeId: number | string) => void
}

const ObstacleMapSelector: (state: ApplicationState, editing: Editing) => (MapProps) = createSelector(
    (state: ApplicationState) => state.activeYard,
    (state: ApplicationState) => state.entities.presences.items,
    (state: ApplicationState) => state.entities.obstacles.items,
    (state: ApplicationState) => state.entities.nobstacles.items,
    (state: ApplicationState, editing: Editing) => editing,
    (state: ApplicationState) => state.entities.targets.items,
    (state: ApplicationState) => state.uiControl,
    (activeYard, presences, obstaclesCollection, nobstaclesCollection, editingData, targets, uiControl) => {
        const presenceArray = entityOfYard(activeYard, presences);
        const vehiclePositions = presenceArray.map(presence => ({ x: presence.pose.x, y: presence.pose.y, id: presence.id }))
        // const vehicleParts: VehiclePart[][] = presenceArray.map(p=>p.parts);

        const vehicleParts: VehiclePart[][] = presenceArray.map(presence => {
            const presenceSpec = presence.specification as any
            const parts: Specification[] = presenceSpec.vehicles ? presenceSpec.vehicles : presenceSpec
            return parts.map((spec, index) => ({
                angle: (presence.pose.orientations[index] != null ? presence.pose.orientations[index] / 1000 : presence.pose.orientations[0] / 1000),
                ...spec,
            }))
        }
        )

        const vehicles = vehiclePositions.map((position, index) =>
            ({
                id: position.id,
                parts: vehicleParts[index],
                position: { x: position.x, y: position.y },
            })
        )


        const obstacles = entityOfYard(activeYard, obstaclesCollection)
        const nobstacles = entityOfYard(activeYard, nobstaclesCollection)
        const temporaryObstacles = obstacles.filter(obstacle => obstacle.layer === LayerType.temporary);
        let editableShapes;
        editableShapes = editingData.editingObstacleShape ? temporaryObstacles : nobstacles;
        let nobstaclesLayer = editingData.editingObstacleShape ? [] : nobstacles.filter(shape => (!shape.isObstacle));
        return {
            obstacleEditLayer: {
                changesDeployed: editingData.changesDeployed,
                editableObstacleIds: editableShapes.map(obstacle => obstacle.id),
                editableObstacles: editableShapes,
                editing: editingData.editing,
                editingObstacleShape: editingData.editingObstacleShape,
                updateObstacles: editingData.updateObstacles,
                obstacleClicked: (shapeId) => editingData.selectObstacle(shapeId)
            },
            obstacleLayer: {
                nobstacles: [],
                obstacles: obstacles.filter(obstacle => (obstacle.layer === LayerType.base && obstacle.isObstacle)),
            },
            vehicleLayer: {
                selectedPresence: null,
                vehicleClicked: (presenceId: string) => { alert('change to Farhzeuge View to select Vehicle') },
                vehicles: (vehicles as Vehicle[]),
            } as VehicleLayerProps,

            yard: activeYard,
            uiControl,


        } as MapProps
    }
)


interface OVProps {
    yard: Yard;
    authStatus: any;
    shapesCollection: any
}

export class ObstacleView extends React.Component<OVProps, State> {
    state: any;
    props: OVProps;
    reset = true;

    constructor(props) {
        super(props);
        this.state = {
            editing: false,
            editingObstacleShape: true,
            changesDeployed: true,
            changes: {}, additions: [],
            selectedShapeId: null,
            selectedShape: null,
            _shapeProps: [],
            reset: true,
            workType: 'mapping'

        }
    }

    componentWillReceiveProps(nextProps) {
        if (!(this.state._shapeProps && this.state._shapeProps.length)) {
            this.reloadLocalShape(nextProps);
        }
    }

    reloadLocalShape (props){
        const copyShapeProps = JSON.parse(JSON.stringify(entityOfYard(this.props.yard, props.shapesCollection)));
        this.state._shapeProps = copyShapeProps;
        console.log('list of shapes to become a file', copyShapeProps)
    }

    _loadShapeFromStore(selectedShapeId){
        const copyShapeProps = JSON.parse(JSON.stringify(entityOfYard(this.props.yard, this.props.shapesCollection)));
        console.log(copyShapeProps)
        let stagedShape = copyShapeProps.filter(e => e.id === selectedShapeId)[0];
        if (stagedShape){
            this.state._shapeProps.push(stagedShape);
        }
        return stagedShape
    }

    _editLocalShape(shapeId, statePatch) {
        let stagedShape = this.state._shapeProps.filter(e => e.id === shapeId)[0];
        if (!stagedShape) {
            stagedShape = this._loadShapeFromStore(this.state.selectedShapeId)
        }
        if (stagedShape) {
            Object.assign(stagedShape, statePatch);           
        } else {
            alert("shapeId not found", shapeId)
        }
    }

    download_yard_shapes() {
        // Select shape for downloading
        // if ( this.state._shapeProps.length) {
        //     const helyOSShapes =  this.state._shapeProps.map(o => appToHelyosModel.obstacleToShapeFormat(o));
        //     download_object(JSON.stringify(helyOSShapes , undefined, 4), 'map_shapes.json','application/json');
        // }

        // Select all shapes for downloading
        listShapesByYard(this.props.yard.id).then(r => {
            const helyOSShapes =  r;
            download_object(JSON.stringify(helyOSShapes , undefined, 4), 'map_shapes.json','application/json');
        })
            
    }

    browser_yard_shapes() {
        const uploadBtn = document.getElementById('fileup');
        uploadBtn.click()

    }

    load_yard_shapes()  {
            const requestData = {command:"load"};
            createMapWorkProcess('load', requestData, this.state.workType || 'mapping');
            // alert('Wait for the map be processed. You may have to click "Redraw".');
    }

    save_yard_shapes() {

        // Select shape for saving in cloud
        // if ( this.state._shapeProps.length) {
        //     const helyOSShapes =  this.state._shapeProps.map(o => appToHelyosModel.obstacleToShapeFormat(o));
        //     const requestData = JSON.stringify(helyOSShapes , undefined, 2);
        //     createMapWorkProcess('save', requestData, this.state.workType || 'mapping');
        // }

        // Save all shapes in cloud
        listShapesByYard(this.props.yard.id).then(r => {
            const helyOSShapes =  r;
            const requestData = JSON.stringify(helyOSShapes , undefined, 2);
            createMapWorkProcess('save', requestData, this.state.workType || 'mapping');
        })

    }

    updateObstacles(event: ObstacleChangeEvent) {
        const updatedChanges: { [index: string]: ShapeData | null } = {}
        for (const i in this.state.changes) {
            const currentShapes = this.state._shapeProps.filter(e => e.id == i);
            const currentChanges = lodash.cloneDeep(this.state.changes[i]);

            if (currentShapes.length) {
                const shapeToMerge = lodash.cloneDeep(currentShapes[0])
                updatedChanges[i] =  { id:i, ...currentChanges, ...shapeToMerge };
            } else {
                updatedChanges[i] = {id:i, ...currentChanges};
            }
        }

        for (const i in event.updateInformation) {
            const currentShapes = this.state._shapeProps.filter(e => e.id == i);
            const geo_updates =  lodash.cloneDeep((event.updateInformation[i]));
            
            if (currentShapes.length) {
                const shapeToMerge = lodash.cloneDeep(currentShapes[0]);
                updatedChanges[i] = { id:i, ...shapeToMerge, ...geo_updates };
            } else {
                updatedChanges[i] = {id:i, ...geo_updates};;
            }
        }
        const updatedAdditions = event.added
        console.log({ changes: updatedChanges, additions: updatedAdditions, changesDeployed: false, editing: false })
        this.setState({ changes: updatedChanges, additions: updatedAdditions, changesDeployed: false, editing: false })
        console.log("this.state.changes", this.state.changes)
    }

    stagePropertiesChangesOfOneShape(shapeId, patch) {
        // stage changes for database update when save is clicked
        const updatedChanges = { ...this.state.changes };

        if (this.state.changes[shapeId]) {
            updatedChanges[shapeId] = { ...updatedChanges[shapeId], ...patch };
            this.setState({ changes: updatedChanges });
        } else {
            updatedChanges[shapeId] = {id: shapeId, ...patch};
            this.setState({ changes: updatedChanges });
        }
    }


    selectObstacle(shapeId) {
        console.log(shapeId)
        let selectedShape;
        if (this.state._shapeProps &&  this.state._shapeProps.length) {
            selectedShape =  this.state._shapeProps.filter(e => e.id === shapeId)[0];
        } else {
            selectedShape = null;
        }

        if (!selectedShape) {
            selectedShape = this._loadShapeFromStore(shapeId)
        }


        if (this.state.changes[shapeId]) {
            selectedShape = { ...selectedShape, ...this.state.changes[shapeId] };
        }
        this.setState({ selectedShapeId: shapeId, selectedShape: selectedShape });
    }
    onDeleteObstacle() {
        removeObstacle(this.state.selectedShapeId).then(() => {
            Store.dispatch(requestMapUpdate());
            this.setState({ selectedShapeId: null })
        
        });
    }

    deployChanges() {
        const delPromises = [];
        for (const i in this.state.changes) {
            delPromises.push(removeObstacle(i))
        }

        const doResetState = () => {
                                Store.dispatch(requestMapUpdate());
                                this.setState({ changesDeployed: true, changes: {}, additions: [] });
                                this.reloadLocalShape(this.props);

                            }

        const recreate = () => {
                        const createPromises = []
                        // read the edited ones - we do not edit shapes, we always create a new one and hide the old one.
                        for (const i in this.state.changes) {
                            if (this.state.changes[i] !== null) {
                                const change = this.state.changes[i] as ShapeData
                                let local_shape = this.state._shapeProps.filter(e => e.id === i)[0];
                                if (!local_shape) {
                                    local_shape = this._loadShapeFromStore(i);
                                }   
                                
                                if (local_shape.points.length > 2) {
                                     const aux = {...local_shape, ...change}
                                     const newObstacle = { geometryType: 'polygon',
                                                        points: aux.points,
                                                        isObstacle: aux.isObstacle,
                                                        type: aux.type,
                                                        data: aux.data,
                                                        layer: LayerType.temporary,
                                                        top: aux.top,
                                                        bottom:aux.bottom,
                                                        } as Obstacle;
                                    createPromises.push(createObstacle(newObstacle));
                                }
                            }
                        }
                        // add the new ones
                        for (const i in this.state.additions) {
                            if (this.state.additions[i] !== null) {
                                const addition = this.state.additions[i] as ShapeData
                                if (addition.points.length > 2) {
                                    const newObstacle = {   geometryType: 'polygon',
                                                            points: addition.points,
                                                            isObstacle: addition.isObstacle,
                                                            layer: LayerType.temporary,
                                                            type: addition.isObstacle?  'obstacle':'drivable',
                                                         } as Obstacle;
                                    createPromises.push(createObstacle(newObstacle));
                                }
                            }
                        }
                        Promise.all(createPromises).catch(doResetState).then(doResetState);
                    }

        Promise.all(delPromises).catch(recreate).then(recreate);
    }

    getFiles = (files) => {
        Store.dispatch(editActiveYard({ picture: files['base64'] }));
        Store.dispatch(requestMapUpdate());
    };

    confirmAction = () => {
        editYard(this.props.yard);
        // Store.dispatch(requestMapUpdate());
        // Store.dispatch(showDialog(false))
    }


    cancelAction = () => {
        Store.dispatch(setYard(this.props.yard.id));
        Store.dispatch(requestMapUpdate());

    }


    handleMapOverlayChange(event) {
        const stateAttr = event.target.name;
        const statePatch = { ...this.props.yard.picturePos };
        const fields = stateAttr.split('.');

        if (event.target.type === 'checkbox') {
            if (fields.length == 1) {
                statePatch[fields[0]] = event.target.checked;
            } else {
                statePatch[fields[0]][fields[1]] = event.target.checked;
            }
        } else {
            if (fields.length == 1) {
                statePatch[fields[0]] = event.target.value;
            } else {
                statePatch[fields[0]][fields[1]] = event.target.value;
            }
        }

        const ovlerlayPatch = { ...this.props.yard.picturePos, ...statePatch }
        Store.dispatch(editActiveYard({ picturePos: ovlerlayPatch }));
        Store.dispatch(requestMapUpdate());
    }

    handleShapeAttributesChange(event) {
        const stateAttr = event.target.name;
        const statePatch = { id: this.state.selectedShapeId};
        const fields = stateAttr.split('.');

        if (fields.length == 1) {
            statePatch[fields[0]] = event.target.value;
        } else {
            statePatch[fields[0]][fields[1]] = event.target.value;
        }

        this._editLocalShape(this.state.selectedShapeId, statePatch)

        this.setState({ selectedShape: { ...this.state.selectedShape, ...statePatch }});
        this.setState({ changesDeployed: false })
        this.stagePropertiesChangesOfOneShape(this.state.selectedShapeId, statePatch)

    }

    
    handleMapServiceAttributesChange(event) {
        const stateAttr = event.target.name;
        const statePatch = {};
        const fields = stateAttr.split('.');

        if (fields.length == 1) {
            statePatch[fields[0]] = event.target.value;
        } else {
            statePatch[fields[0]][fields[1]] = event.target.value;
        }

        this.setState(statePatch);

    }

    handleCenterChange(event) {
        const stateAttr = event.target.name;
        const statePatch = { pCenter: { ...this.props.yard.picturePos.pCenter } };
        const fields = stateAttr.split('.');

        if (fields.length == 1) {
            statePatch[fields[0]] = event.target.value;
        } else {
            statePatch[fields[0]][fields[1]] = event.target.value;
        }
        const ovlerlayPatch = { ...this.props.yard.picturePos.pCenter, ...statePatch.pCenter }

        Store.dispatch(centerImageOverlay(ovlerlayPatch));
        Store.dispatch(requestMapUpdate());

    }

    centerMapInYard() {
        Store.dispatch(centerImageOverlay({ lat: this.props.yard.origin.lat, lon: this.props.yard.origin.lon }));
        Store.dispatch(requestMapUpdate());

    }

    reloadMap() {
        Store.dispatch(requestMapUpdate());
    }

    calcPValues(PValues) {
        const statePatch = { ...this.props.yard.picturePos };
        const ovlerlayPatch = { ...statePatch, ...PValues };
        Store.dispatch(editActiveYard({ picturePos: ovlerlayPatch }));
    }

    
    getFileShapes = (files) => {
        createManyShapes(JSON.parse(files.base64))
        .then(() => {
            this.setState({ selectedShapeId: null });
            Store.dispatch(requestMapUpdate());
        });
    } 

    cleanAll() {
        removeAllShapes(this.props.yard.id).then(() => this.redrawAll());
    }
    

    redrawAll() {
        this.setState({destroyMap: true});
        Store.dispatch(requestMapUpdate());
        this.setState({ selectedShapeId: null }); 
        reloadAllShapesInStore();
        setTimeout(()=>{this.setState({destroyMap:false})}, 500);
    }



    render() {


        let editingShape: string;
        // if(!this.props.yard || this.props.authStatus!=='ADMIN_LOGGED') {return (<div></div>)} 

        if (this.state.editingObstacleShape) { editingShape = 'Obstacle'; }
        else { editingShape = 'Drivable'; }

        let obstMap = null;
        if (this.props.yard && !this.state.destroyMap) {
            obstMap = (<ObstacleMap editing={this.state.editing} editingObstacleShape={this.state.editingObstacleShape} changesDeployed={this.state.changesDeployed}
                selectObstacle={shapeId => this.selectObstacle(shapeId)} updateObstacles={event => this.updateObstacles(event)}>
            </ObstacleMap>);
        }

        let expertCommand;
        if (this.state.expertMode) {
            expertCommand = (<div>
                <Button style={{ marginTop: 50 }} className="alert button" onClick={() => this.setState({ expertMode: false })} > Expert Off </Button>
                
                

                <OverlayImageMap yard={this.props.yard} centerMapInYard={this.centerMapInYard.bind(this)}
                    changeCenter={this.handleCenterChange.bind(this)} updatePs={this.calcPValues.bind(this)}
                    reloadMap={this.reloadMap.bind(this)} change={this.handleMapOverlayChange.bind(this)} getFiles={this.getFiles.bind(this)} />

                <div id="DialogButtons">
                    <button className="button confirm" onClick={this.confirmAction.bind(this)}>
                        Save
                    </button>
                    <button className="button cancel" onClick={this.cancelAction.bind(this)}>
                        Cancel
                    </button>
                </div>
            </div>
            );

        } else {
            expertCommand = (<div>

                     
                <h4 style={{marginBottom:0}}>Attributes</h4>

                <i style={{fontSize:"0.8em"}}>Click in a polygon to annotate.</i>


                <ShapePropForm shapeProps={this.state.editingObstacleShape && this.state.selectedShape}  
                    reloadMap={this.reloadMap.bind(this)} change={this.handleShapeAttributesChange.bind(this)} />

                <div className="divider"></div>

                
                <h4  style={{ marginTop: 10 }}>Import/Export</h4>
                <h5  style={{ marginTop: 10 }}>Trucktrix map format</h5>

                <h5  style={{ marginTop: 10 }}>Local maps</h5>
                <Button style={{ marginTop: 0, width:100 }} className="button" onClick={() => this.download_yard_shapes()} > Download Regions </Button>
                <Button style={{ marginTop: 0 , width:100 }} className="button" onClick={() => this.browser_yard_shapes()} > Browse Regions</Button>

                <h5  style={{ marginTop: 10 }}>Cloud map server</h5>

                <div style={{ minWidth: 30 }}> Channel:  </div>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <input name="workType" type="text" placeholder="work type name"  
                                                value={this.state.workType} onChange={this.handleMapServiceAttributesChange.bind(this)} />

                </div>

                <Button style={{ marginTop: 0, width:100 }} className="button" onClick={() => this.load_yard_shapes()} > Load Regions </Button>
                <Button style={{ marginTop: 0 , width:100 }} className="button" onClick={() => this.save_yard_shapes()} > Save in Cloud </Button>

                <FileBase64  multiple={ false }  is_text={ true } accept={".json"} upldId="fileup" btnVisibility="hidden"
                            onDone={ this.getFileShapes.bind(this) } />


            <Button style={{ marginTop: 50 }} className="alert button" onClick={() => this.setState({ expertMode: true })} > Expert Mode </Button>



            </div>)
        }

        return (
            <div>
                <aside>

                    <div className={'ObstacleBar'}>
                        <button type="button" className={this.state.editing ? 'button active' : 'button'} onClick={() => { this.setState({ editing: !this.state.editing }) }}>Add</ button>
                        <button type="button" className="button" disabled={this.state.changesDeployed} onClick={() => { this.deployChanges() }}>Save</ button>
                        <button type="button" className="alert button" disabled={!this.state.selectedShapeId} onClick={() => { this.onDeleteObstacle() }}>Delete</ button>

                        <div >
                            {/* <button type="button" className={this.state.editing ? 'button active' : 'button'} onClick={() => {this.setState({editingObstacleShape: !this.state.editingObstacleShape})}}>{editingShape}</ button> */}
                            <label className="labelToggle">
                                <div  > Regions </div>
                                <Toggle
                                    defaultChecked={editingShape === 'Drivable'}
                                    icons={false}
                                    onChange={() => { this.setState({ editingObstacleShape: !this.state.editingObstacleShape }) }}>
                                </Toggle>
                                <div> Drivable area </div>
                            </label>
                        </div>

                        <Button style={{ marginTop: 20 }} className="alert button" onClick={() => this.cleanAll()} > Clean All </Button>
                        <Button style={{ marginTop: 20 }} className="alert button" onClick={() => this.redrawAll()} > Redraw </Button>


                        <div className="divider"></div>

                        {expertCommand}

                    </div>

                </aside>

                <div className="Content">
                    {obstMap}
                </div>
            </div>
        )
    }
}

const ObstacleMap = connect<MapProps, {}, { editing: boolean, editingObstacleShape: boolean, changesDeployed: boolean, updateObstacles: (event: ObstacleChangeEvent) => void }>(ObstacleMapSelector)(LayeredMapComponent)
// const ObstacleMap = connect<MapProps, {}, {editing: boolean, changesDeployed: boolean, updateObstacles: (event: ObstacleChangeEvent) => void} > (mapStateProp)(LayeredMapComponent)

const ObstacleViewSelector: (state: ApplicationState) => (MapProps) = createSelector(
    (state: ApplicationState) => state.activeYard,
    (state: ApplicationState) => state.authentication,
    (state: ApplicationState) => state.entities.obstacles.items,
    (activeYard, authStatus, shapesCollection) => {
        return ({
            yard: activeYard,
            authStatus: authStatus,
            shapesCollection: shapesCollection
        })
    }
)

export const ObsViewConnected = connect<MapProps>(ObstacleViewSelector)(ObstacleView)
