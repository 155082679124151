import { ApplicationEntityBase, InvalidStateType, Playback } from './models'
import { UpdateForAllEntities } from './updatetableCollection'
import { Yard } from '../models/app.models';
import { H_WorkProcessType } from 'helyosjs-sdk'

export enum ActionTypes {
    // noinspection JSUnusedGlobalSymbols
    SetMissionUI= 'SET_AVAILABLE_MISSIONS',
    ChangeEntityState = 'CHANGE_ENTITY_STATE',
    MarkMissionsOutdated = 'MARK_MISSIONS_OUTDATED',
    MissingAction = 'An action which is never used',
    SetYard = 'SET_YARD',
    SetPlaybackStatus = 'SET_PLAYBACK_STATUS',
    SetPlayback = 'SET_PLAYBACK',
    ReloadScheduleEvents = 'RELOAD_SCHEDULE_EVENTS',
    SelectPresence = 'SELECT_PRESENCE',
    ShowDialog = 'SHOW_DIALOG',
    SetPlayTime = 'SET_PLAY_TIME',
    CollapseSchedule = 'COLLAPSE_SCHEDULE',
    EditSelectedPresence = 'EDIT_SELECTED_PREESENCE',
    EditActiveYard = 'EDIT_ACTIVE_YARD',
    RequestMapUpdate = 'REQUEST_MAP_UPDATE',
    CenterImageOverlay = 'CENTER_IMAGE_OVERLAY',
    SetFilterStatus = 'SET_FILTER_STATUS',
    SetAuthenticationStatus = 'SET_AUTHENTICATION_STATUS',
    CleanEntity = 'CLEAN_ENTITY'
}

export interface CleanEntity {
    type:ActionTypes.CleanEntity;
    entityName?: string
}

export interface SetFilterStatus {
    type:ActionTypes.SetFilterStatus;
    status: string
}

export interface SetAuthenticationStatus {
    type:ActionTypes.SetAuthenticationStatus;
    status: string
}


export interface SetYard {
    type:ActionTypes.SetYard;
    yardId: number
}

export interface RequestMapUpdate {
    type: ActionTypes.RequestMapUpdate;
}


export interface SetPlaybackStatus {
    type:ActionTypes.SetPlaybackStatus;
    status: string
}

export interface SetPlayTime {
    type:ActionTypes.SetPlayTime;
    time: any
}

export interface ReloadScheduleEvents{
    type:ActionTypes.ReloadScheduleEvents;
    reload: boolean
}

export interface SetPlayback {
    type:ActionTypes.SetPlayback;
    playback: Partial<Playback>
}


export interface EditActiveYard {
    type:ActionTypes.EditActiveYard;
    yard: Partial<Yard>
}

export interface CenterImageOverlay {
    type:ActionTypes.CenterImageOverlay;
    pCenter: any;
}

export interface ShowDialog {
    type:ActionTypes.ShowDialog;
    show: boolean
}

export interface CollapseSchedule {
    type:ActionTypes.CollapseSchedule;
    collapse: boolean;
}

export interface SelectPresence {
    type:ActionTypes.SelectPresence;
    presence: any
}


export interface EditSelectedPresence {
    type:ActionTypes.EditSelectedPresence;
    presence: any
}


export interface ChangeEntityState {
    type: ActionTypes.ChangeEntityState
    changes: UpdateForAllEntities<ApplicationEntityBase>
}


export interface SetMissionUI {
    type: ActionTypes.SetMissionUI
    missions: Partial<H_WorkProcessType>[]
}

export interface MarkMissionsOutdated {
    type: ActionTypes.MarkMissionsOutdated
    presenceId: string
    outdatedType: InvalidStateType,
}

export type Action = CleanEntity | ChangeEntityState | SetMissionUI|  MarkMissionsOutdated | SetYard | SetPlaybackStatus | EditActiveYard | RequestMapUpdate | CenterImageOverlay | SetAuthenticationStatus|
             SetPlayback | ReloadScheduleEvents | SelectPresence | ShowDialog | SetPlayTime | CollapseSchedule | EditSelectedPresence | SetFilterStatus;



export function cleanEntityState (status: string): CleanEntity {
    return {type: ActionTypes.CleanEntity}
}


 // Presence actions
export function setFilterStatus (status: string): SetFilterStatus {
    return {type: ActionTypes.SetFilterStatus, status}
}


export function setAuthenticationStatus (status: string): SetAuthenticationStatus {
    return {type: ActionTypes.SetAuthenticationStatus, status}
}



export function selectPresence (presence: any): SelectPresence {
    return {type: ActionTypes.SelectPresence, presence}
}

export function editSelectedPresence (presence: any): EditSelectedPresence {
    return {type: ActionTypes.EditSelectedPresence, presence}
}

export function requestMapUpdate (): RequestMapUpdate {
    return {type: ActionTypes.RequestMapUpdate}
}

export function editActiveYard (yard: Yard): EditActiveYard {
    return {type: ActionTypes.EditActiveYard, yard}
}

export function centerImageOverlay (pCenter: any): CenterImageOverlay {
    return {type: ActionTypes.CenterImageOverlay, pCenter}
}

export function setPlaybackStatus (status: string): SetPlaybackStatus {
    return {type: ActionTypes.SetPlaybackStatus, status}
}

export function setPlayTime (time: string): SetPlayTime {
    return {type: ActionTypes.SetPlayTime, time}
}


// USER INTERFACE ACTIONS


export function collapseSchedule (collapse: boolean): CollapseSchedule {
    return {type: ActionTypes.CollapseSchedule, collapse}
}


export function showDialog (show: boolean): ShowDialog {
    return {type: ActionTypes.ShowDialog, show}
}


export function setMissionUI (missions: any[]): SetMissionUI {
    return {type: ActionTypes.SetMissionUI, missions}
}


export function setPlayback (playback: Playback): SetPlayback {
    return {type: ActionTypes.SetPlayback, playback}
}



export function reloadScheduleEvents (reload: boolean): ReloadScheduleEvents {
    return {type: ActionTypes.ReloadScheduleEvents, reload}
}

export function changeEntityState (changes: UpdateForAllEntities<ApplicationEntityBase>): ChangeEntityState {
    return {type: ActionTypes.ChangeEntityState, changes}
}

export function setYard (yardId:number): SetYard {
    return {type: ActionTypes.SetYard, yardId}
}

export function markMissionsOutdated (presenceId: string, outdatedType: InvalidStateType): MarkMissionsOutdated {
    return  {
        outdatedType,
        presenceId,
        type: ActionTypes.MarkMissionsOutdated,
    }
}
