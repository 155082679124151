import * as React from 'react'
import './PresenceBar.scss';
import './MissionButtons.scss';
import 'react-circular-progressbar/dist/styles.css';



export class MissionTrigger {
    buttonLabel: string;
    inputMessage: string = 'Not defined';
    inputVariable: string;
    defaultInputValue: string;
    runMission: string;
    defaultRequest: any;
    button: string = 'MA1';
    requireAgent: boolean;
    backgroundColor: string;
    missionAgents: string[] = null;

    constructor(missionSettings, buttonDefaultLabel) {
        if (!missionSettings){
            this.buttonLabel = buttonDefaultLabel;
        } else {
            this.buttonLabel= missionSettings.buttonLabel || buttonDefaultLabel;
            this.inputMessage= missionSettings.inputMessage;
            this.inputVariable= missionSettings.inputVariable;
            this.defaultInputValue= missionSettings.defaultInputValue;
            this.runMission= missionSettings.runMission;
            this.defaultRequest = missionSettings.defaultRequest;
            this.requireAgent = missionSettings.requireAgent === false? false: true;
            this.backgroundColor = missionSettings.backgroundColor;
            this.missionAgents = missionSettings.missionAgents;
        }
    }
}


class MissionButtonSet {
        MA1 = null;
        MA2 = null;
        MB1 = null;
        MB2 = null;
        MC1 = null;
        MC2 = null;
        MD1 = null;
        MD2 = null;
        MD3 = null;


}

export const missionButtons: React.SFC<any> = (loadAndPlay, autotruck_app) => {
        let _autotruck_app;
        if(autotruck_app){
                _autotruck_app = autotruck_app;
        } else {
                _autotruck_app = new MissionButtonSet();
        }
        const missionA1 = new MissionTrigger(_autotruck_app['MA1'], 'M-A1');
        const missionA2 = new MissionTrigger(_autotruck_app['MA2'], 'M-A2');
        const missionA3 = new MissionTrigger(_autotruck_app['MA3'], 'M-A3');

        const missionB1 = new MissionTrigger(_autotruck_app['MB1'], 'M-B1');
        const missionB2 = new MissionTrigger(_autotruck_app['MB2'], 'M-B2');
        const missionB3 = new MissionTrigger(_autotruck_app['MB3'], 'M-B3');
        const missionB4 = new MissionTrigger(_autotruck_app['MB4'], 'M-B4');

        const missionC1 = new MissionTrigger(_autotruck_app['MC1'], 'M-C1');
        const missionC2 = new MissionTrigger(_autotruck_app['MC2'], 'M-C2');
        const missionC3 = new MissionTrigger(_autotruck_app['MC3'], 'M-C3');
        const missionC4 = new MissionTrigger(_autotruck_app['MC4'], 'M-C4');

        const missionD1 = new MissionTrigger(_autotruck_app['MD1'], 'M-D1');
        const missionD2 = new MissionTrigger(_autotruck_app['MD2'], 'M-D2');
        const missionD3 = new MissionTrigger(_autotruck_app['MD3'], 'M-D3');
        const missionD4 = new MissionTrigger(_autotruck_app['MD4'], 'M-D4');


        return  <div style={{width:'55%', padding:'6px', textAlign:'center', display:'flex', flexDirection:'column'}}>

                    <div style={{display: 'flex'}}>

                                <button title="A1" style={{backgroundColor: missionA1.backgroundColor ||"#296301" }} type="button"className="MissionButton button"
                                        onClick={() => loadAndPlay(missionA1)}>
                                        {missionA1.buttonLabel}
                                </button>
                                <button title="A2" style={{backgroundColor: missionA2.backgroundColor ||"#32527b", margin:"0 1px" }} type="button" className="MissionButton button"
                                        onClick={() => loadAndPlay(missionA2)}>
                                        {missionA2.buttonLabel}
                                </button>
                                <button title="A3" style={{backgroundColor: missionA3.backgroundColor || "#a62c2b" }} type="button" className="MissionButton button"
                                        onClick={() => loadAndPlay(missionA3)}>
                                        {missionA3.buttonLabel}
                                </button>
                    </div>


                    <div style={{display: 'flex'}}>
                                <button style={{backgroundColor: missionB1.backgroundColor ||"#32527b", margin:"0 1px" }} type="button" className="MissionButton button" 
                                        onClick={() => loadAndPlay(missionB1)}>
                                        {missionB1.buttonLabel}
                                </button>
                                <button style={{backgroundColor: missionB2.backgroundColor ||"#a62c2b" }} type="button" className="MissionButton button" 
                                        onClick={() => loadAndPlay(missionB2)}>
                                        {missionB2.buttonLabel}
                                </button>
                                <button style={{backgroundColor: missionB3.backgroundColor ||"#a62c2b" }} type="button" className="MissionButton button" 
                                        onClick={() => loadAndPlay(missionB3)}>
                                        {missionB3.buttonLabel}
                                </button>
                                <button style={{width:"100%", height: '60px', backgroundColor: missionB4.backgroundColor ||"#a62c2b" }} type="button" className="button" 
                                        onClick={() => loadAndPlay(missionB4)}>
                                        {missionB4.buttonLabel}
                                </button>
                    </div>


                    <div style={{display: 'flex'}}>
                                <button style={{backgroundColor: missionC1.backgroundColor ||"#296301", margin:"0 1px" }} type="button" className="MissionButton button" 
                                        onClick={() => loadAndPlay(missionC1)}>
                                        {missionC1.buttonLabel}
                                </button>
                                <button style={{backgroundColor: missionC2.backgroundColor ||"#32527b" }} type="button" className="MissionButton button" 
                                        onClick={() => loadAndPlay(missionC2)}>
                                        {missionC2.buttonLabel}
                                </button>
                                <button style={{backgroundColor: missionC3.backgroundColor ||"#a62c2b" }} type="button" className="MissionButton button" 
                                        onClick={() => loadAndPlay(missionC3)}>
                                        {missionC3.buttonLabel}
                                </button>
                                <button style={{width:"100%", height: '60px', backgroundColor: missionC4.backgroundColor ||"#a62c2b" }} type="button" className="button" 
                                        onClick={() => loadAndPlay(missionC4)}>
                                        {missionC4.buttonLabel}
                                </button>
                    </div>

                    <div style={{display: 'flex'}}>
                                <button style={{backgroundColor: missionD1.backgroundColor ||"#296301" }} type="button" className="MissionButton button"
                                        onClick={() => loadAndPlay(missionD1)}>
                                        {missionD1.buttonLabel}
                                </button>
                                <button style={{width:"100%", height: '60px', backgroundColor: missionD2.backgroundColor || "#32527b" }} type="button" className="button" onClick={() => loadAndPlay(missionD2)}>
                                        {missionD2.buttonLabel}
                                </button>
                                <button style={{width:"100%", height: '60px', backgroundColor: missionD3.backgroundColor ||"#a62c2b" }} type="button" className="button" onClick={() => loadAndPlay(missionD3)}>
                                        {missionD3.buttonLabel}
                                </button>
                                <button style={{width:"100%", height: '60px', backgroundColor: missionD4.backgroundColor ||"#a62c2b" }} type="button" className="button" onClick={() => loadAndPlay(missionD4)}>
                                        {missionD4.buttonLabel}
                                </button>
                    </div>

                </div>

}

