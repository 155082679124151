import * as React from 'react'
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { createSelector } from 'reselect'
import { Presence, MissionStatus } from '../../../models/app.models'
import { ApplicationState, UiControl, Playback } from '../../../state/models'
import { allEntites } from '../../../state/updatetableCollection'
import { InjectedPresence, withSelectedPresence } from '../modules/with-presence'
import { Button } from './Button';
import { Store, sliceTimeSlot } from '../../../state/store';
import { setPlayback, setPlaybackStatus, setPlayTime } from '../../../state/actions';
import { schedulePresenceUpdates, presencePoseUpdates } from '../../../services/app-service';


export interface Props {
    uiControl: UiControl;
    playback : Playback;
    selectedItem?: Presence,
}

const uiSelector: (state: ApplicationState, selected: InjectedPresence) => Props  = createSelector(
    (state: ApplicationState) => state.entities.presences,
    (state: ApplicationState) => state.uiControl,
    (state: ApplicationState, selected: InjectedPresence) => selected.presence,
    (state: ApplicationState) => state.uiControl.playback,
    (presences, uiControl, presence, playback) => ({
        uiControl: uiControl ,
        playback: playback,
        selectedItem: allEntites(presences.items).find(item => !!(presence && item.id === presence.id)),
    })
)




export const PlaybackButtons =  (props: Props ) => {
    let listPos = [];
    let timerHandler;
    let currentPlaybackTime: any = "00:00";

    const onClose = () =>{
        clearInterval(props.playback.timerHandler);
        Store.dispatch(setPlayback({history:[]}));
        Store.dispatch(setPlaybackStatus('stop'));
        schedulePresenceUpdates();
        Store.dispatch(setPlayback({visible: false}));
    }

    const onOpen = () =>{
        Store.dispatch(setPlayback({visible: true}));
    }

    const onRewind = () => {
        Store.dispatch(setPlaybackStatus('rewind'));
        listPos = [...props.playback.history];
        let toolPose = {toolId:0};
        let toolId = 0;
        let positionedTools = [];

        for (let index = 0; index < listPos.length; index++) {
            toolPose = listPos[index];
            if( positionedTools.indexOf(toolPose.toolId)<0) {
                positionedTools.push(toolPose.toolId);
                presencePoseUpdates([toolPose]);
            }
        }
        Store.dispatch(setPlaybackStatus('stop'));

    }


    const nextPointRecursive = (deltaTime: number) => {
        const state: ApplicationState = Store.getState();
        if (state.uiControl.playback.status !== 'play') { return; }

        const rv = sliceTimeSlot(listPos, 'createdAt',250);
        listPos = rv.remainingItems;
        
        currentPlaybackTime = new Date(rv.itemsInSlot[0].createdAt + 'Z').toString();
        const toolPoses = rv.itemsInSlot;;
        Store.dispatch(setPlayTime(currentPlaybackTime));
    
        timerHandler = setTimeout(()=> {
                presencePoseUpdates(toolPoses,rv.deltaTime);
                if (listPos.length ) {
                    nextPointRecursive(rv.deltaTime);
                } else {
                    Store.dispatch(setPlaybackStatus('stop'));
                }
            }, deltaTime);
    }

    const onPlay = () => {
        if (props.playback.status ==='stop') {
            onRewind();
            Store.dispatch(setPlaybackStatus('play'));

            nextPointRecursive(500);
            Store.dispatch(setPlayback({timerHandler}));  
            
        }

    }


    const onStop = () => {
        clearInterval(props.playback.timerHandler);
        Store.dispatch(setPlaybackStatus('stop'));
    }

    if (props.playback.mission && props.playback.mission.status === MissionStatus.Successful &&  !props.playback.visible  && props.playback.history.length ) {
      return <div>
                    <Button onClick={onOpen}> Replay Mission </Button>
            </div>

    }

    if (props.playback.visible) {
            return <div>

                        <Button  onClick={onRewind}> rewind </Button>
                        <Button onClick={onPlay}> play </Button>
                        <Button onClick={onStop}> stop </Button>

                        <div>
                           <h2> {props.playback.status}</h2>
                           <span> {props.playback.currentPlayTime} </span>
                            <Button onClick={onClose}> CLOSE Playback Mode </Button>
                        </div>
                    </div>

            } else {
                return <div></div>
            }    
}


const Connected = connect(uiSelector)(PlaybackButtons);
export const PlaybackComponent = withSelectedPresence(p => <Connected {...p}/>);

