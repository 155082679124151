import * as React from "react";
import {render} from "react-dom";
import {AppContainer} from "react-hot-loader";
import { MainContainer } from './components/base'

const rootEl = document.getElementById("root");
declare let module: { hot: any };

render(
    <AppContainer>
       <MainContainer />
    </AppContainer>,
    rootEl
);

// Hot Module Replacement API
if (module.hot) {
    module.hot.accept("./components/App", () => {

        render(
            <AppContainer>
                <MainContainer/>
            </AppContainer>,
            rootEl
        );
    });
}