import * as React from 'react'
import * as ReactPopover from 'react-popover'

import './Popover.scss'

interface Props {
    style?: React.CSSProperties
    className?: string
    body: React.ReactNode
    isOpen: boolean
    preferPlace: 'above' | 'right' | 'below' | 'left'
    onOuterAction?: () => void
}

export class Popover extends React.PureComponent<Props> {

    render () {
        return (
            <ReactPopover
                isOpen={this.props.isOpen}
                className={`ReactPopover ${this.props.className || ''}`}
                style={this.props.style}
                body={this.props.body}
                preferPlace={this.props.preferPlace}
                onOuterAction={this.props.onOuterAction}
                refreshIntervalMs={false}
                enterExitTransitionDurationMs={0}
            >
                {this.props.children}
            </ReactPopover>
        )
    }
}
